import React, { Component, useEffect } from 'react';
import { Provider,useSelector } from 'react-redux'
import store from "./utils/store"
import Routes from "./utils/routes";
import { logintoken, logout, login, loginagenttoken, agentowndblogin, logincustomertoken, agentlogin,logintokenRealM,getCookie,loginOwner,setCookies } from "./utils/services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from "./utils/db"
import { useHistory } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const jwt = require('jsonwebtoken')

export default function App(props) {
    const history = useHistory()
    let pathname = (window.location.href).split('.com')[1]
    let pathname1 = (pathname)?.split('/')[1]

    function previousLogin(){

        console.log('previous login ')
        let owner = getCookie("79fefaf2564aabb19d44fe324844c86b")
        let agent = getCookie("61be0d8c3c195df8308def9fac389355")
        let customer = getCookie("a1840bd4010cc8f4c31a45a679a985cd")
        //console.log(customer,"customer")
        if((agent === "1" || agent === 1) || (owner === "1" || owner === 1) || (customer === "1" || customer === 1)){
            if(agent === "1" || agent === 1){
                checkRealMCommonlogin("agent")
            }
            else if(owner === "1" || owner === 1){
                checkRealMCommonlogin("owner")
            }
            // else if(customer === "1" || customer === 1){
            //     checkRealMCommonlogin("customer")
            // }
        }
    }
    previousLogin()
    setInterval(() => {    
        previousLogin() 
    },30000)

    function loginAccess(){
        
        if (localStorage.getItem('user')) {
            var url = (window.location.hostname).split('.');
            var location = (window.location.href).split('/');
            let user = JSON.parse(localStorage.getItem('user'));
            let domainname = (localStorage.getItem('user')) ? user[0].domainname : "";
             
            if (url[0] != 'dev-desk' && url[0] != 'localhost') {
                let path = ""                   
                const checkDomain = window.location.hostname.split('.')[0]
                if (checkDomain != domainname) {  
                    var redUrl = (window.location.href).replace(checkDomain, domainname)
                    if(window.location.href.includes('appypie.com')){
                        localStorage.removeItem('user')
                        window.location.href=redUrl
                    }
                }
            }
        }
    }
    loginAccess()

    // function checkCustomerLogin(){
    //     if (localStorage.getItem('customer')) {
    //         var url = (window.location.hostname).split('.');
    //         let user = jwt.decode(localStorage.getItem('customer'));
    //         let domainname = (localStorage.getItem('customer')) ? user.domainname : "";
    //         if (domainname !== "") {
    //             const checkDomain = window.location.hostname.split('.')[0]
    //             if (checkDomain != domainname) {
    //                 var redUrl = (window.location.href).replace(checkDomain, domainname)
    //                 if(window.location.href.includes('appypie.com')){
    //                     localStorage.removeItem('customer')
    //                     window.location.href=redUrl
    //                 }
    //             }
    //         }
    
    //     }
    // }
    // checkCustomerLogin()


    function checkRealMCommonlogin(login_type) {
    
        logintokenRealM(login_type)
            .then(async(user) => {
                if(user.status === 200){
                    if (user.userdata && user.userdata !== "") {
                        let userdetail = JSON.parse(user.userdata);
                        if(userdetail.user_type === 'owner'){
                                let userdetail = JSON.parse(user.userdata);
                                if(userdetail.userdata.company_identifier == ""){
                                    window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}`
                                }
                                else{
                                    var user = loginOwner(userdetail.userdata.email, userdetail.userdata._id).then(userdetails => {                                       
                                        if (userdetails.status === 200 || userdetails.status === "200") {
                                           
                                           var token=localStorage.getItem('token');
                                            if(token){
                                                const userData=jwt_decode(token)
                                                let tickethomespage=userdetails.data[0]?.tickethomespage
                                                
                                                if(tickethomespage === undefined || tickethomespage === ""){
                                                    tickethomespage="myopentickets"
                                                }
    
                                                if(!['categories','category','article','folder','preview'].includes(pathname1)){
                                                    if(userData?.is_ticket_app) {
                                                        window.location.replace(`/ticket/${tickethomespage}`)                                                            
                                                    }
                                                    else if(userData?.is_kb_app){
                                                        window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                                    }
                                                    else{
                                                        window.location.replace(`/ticket/${tickethomespage}`)    
                                                    }
                                                }                                                
                                            }
                                            else{
                                                window.location.reload();
                                            }
                                        }
                                        else {
                                           
                                            if(userdetails.message === "Invalid Domain"){
                                                var token=localStorage.getItem('token');
                                            if(token){
                                                const userData=jwt_decode(token)
                                                let tickethomespage=userdetails.data[0]?.tickethomespage
                                                if(tickethomespage === undefined || tickethomespage === ""){
                                                    tickethomespage="myopentickets"
                                                }
                                                if(!['categories','category','article','folder','preview'].includes(pathname1)){
                                                if(userData?.is_ticket_app) {
                                                    window.location.replace(`/ticket/${tickethomespage}`)                                                       
                                                }
                                                else if(userData?.is_kb_app){
                                                    window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                                }
                                                else{
                                                    window.location.replace(`/ticket/${tickethomespage}`)    
                                                }
                                            }
                                            }
                                            else{
                                                console.log("No token.....")
                                                window.location.reload();
                                            }
                                            }
                                        }
                                    });
                                }                           
                        }
                        else if(userdetail.user_type === 'agent'){
                            let userData=JSON.parse(JSON.parse(user.userdata).userdata)?.idToken.payload                            
                            // console.log(userData.preferred_username,"agentdata")    
                            var user = agentowndblogin(userData.preferred_username,userData['email']).then(async(owndbagent) => {
                                //console.log(owndbagent,"owndbagent")
                                if (owndbagent.status == 200) {
                                    owndbagent.data[0].firstName = userData['custom:first_name']
                                    owndbagent.data[0].lastName = userData['custom:last_name']
                                    owndbagent.data[0].alias = userData['custom:alias']
                                    owndbagent.data[0].email = userData['email']
                                    owndbagent.data[0].photo = userData['custom:imageurl'] + '/' + userData['custom:pic']
                                    owndbagent.data[0].timezone = userData['custom:timezone']
                                    owndbagent.data[0].sub = owndbagent.data[0].id
                                    owndbagent.data[0].id = owndbagent.data[0].parentId
                                    localStorage.setItem('user', JSON.stringify(owndbagent.data));
                                    localStorage.setItem('userId', owndbagent.data[0].parentId);
                                    localStorage.setItem('token', owndbagent.data[0].token);
                                    localStorage.setItem('articlecount', owndbagent.aticlecount);
                                    //window.location.replace(window.location.origin);
                                    let tickethomespage=owndbagent.data[0]?.tickethomespage
                                    //console.log(tickethomespage,"tickethomespage1")
                                    if(tickethomespage === undefined || tickethomespage === ""){
                                        tickethomespage="myopentickets"
                                    }   
                                    var token=localStorage.getItem('token');
                                    if(token){
                                        const userData=jwt_decode(token)

                                        var agentLoginCookies = {
                                            "61be0d8c3c195df8308def9fac389355" : 1
                                        }
                                        await setCookies(agentLoginCookies);
                                    
                                        if(!['categories','category','article','folder','preview'].includes(pathname1)){
                                            if(userData?.is_ticket_app) {
                                                window.location.replace(`/ticket/${tickethomespage}`)                                            
                                            }
                                            else if(userData?.is_kb_app){
                                                window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                            }
                                            else{
                                                window.location.replace(`/ticket/${tickethomespage}`)
                                            }
                                        }
                                    }
                                    else{
                                        window.location.replace(window.location.origin);
                                    }
                                    //window.location.replace(urlReplace);
                                    // window.location.reload();
                                }
                                else {
                                    //console.log(owndbagent.message);
                                }
                                //console.log(agentdata);
                            });
                        }
                        else{
                            let agentusers = JSON.parse(user.userdata);
                                if(agentusers.user_type === "customer"){
                                    //console.log(JSON.parse(agentusers.userdata)?.idToken?.jwtToken)
                                    let customerToken = JSON.parse(agentusers.userdata)?.idToken?.jwtToken
                                    let customerData = jwt.decode(customerToken);
                                    let domainname = (customerData) ? customerData["custom:company_identifier"] : "";
                                                                       
                                    if (domainname !== "") {
                                        const checkDomain = window.location.hostname.split('.')[0]
                                        if (checkDomain != 'dev-desk' && checkDomain != 'localhost') {                            
                                        if (checkDomain != domainname) {
                                            //var redUrl =  `https://${domainname}.${webUrl}`
                                            // redirect to sub domain when user ser his/her domain                            
                                            var redUrl = (window.location.href).replace(checkDomain, domainname)
                                            var customerLoginCookies = {
                                                "a1840bd4010cc8f4c31a45a679a985cd" : 1
                                            }
                                            await setCookies(customerLoginCookies);
                                            // alert("realm remove")
                                            localStorage.removeItem('customer')
                                            window.location.href=redUrl
                                        }
                                    }
                                    }

                                }
                        }                        
                    }
                    else {
                        console.log(user.userdata,"user.userdata")
                        logout();
                    }
                }
            })
    }
    return (
        <Provider store={store} >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Routes />
        </Provider>
    );
}