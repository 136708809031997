import React, { Fragment,useState } from "react"
import { InstantSearch } from 'react-instantsearch-dom';
import SearchBox from "../components/Shared/SearchBox"
import Hits from "../components/Shared/Hits"


const Search = (props) => {
  
  const [hits,sethits]=useState([])
  //console.log(props.userinfo);
  return (
    <Fragment>
      {(props.userinfo) ?
     

          <SearchBox userid={props.userinfo[0]} />

        : null}
    </Fragment>
  )
}



export default Search;
