import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
// import InfiniteScroll from 'react-infinite-scroller';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useHistory } from "react-router-dom"

import * as Yup from 'yup';
import * as Services from "../../utils/services";
import { toast } from 'react-toastify';
import Loader from "../../components/Shared/Loader"
import { loginRequest} from "../BaseLayout/actions"
import BaseLayout from "../BaseLayout"
const jwt = require('jsonwebtoken')
const CustomerVerify = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const baseData = useSelector(state => state.baseReducer)
    //console.log(baseData,"baseData")
    const [loading, setLoading] = useState(false)


    // async function resendOtp(location){
    //     setLoading(true)
    //     let resp= await Services.resendOtpSignup(location,"customer" )
    //     console.log(resp,"res")
    //     if(resp.status == 200){
    //         setLoading(false)
    //         toast.success(resp.message);
            
    //         history.push(`/signup-success`)
    //         }
    //         else{
    //         setLoading(false)
    //         toast.error(resp.message);
    //     }
    // }

    return (

    <BaseLayout>
      <div className="main-wrapper" id="main-wrapper">
        <div>
          <div className="container-fluid main-container">
            <div className="centerMainContainer">
            { loading &&<Loader /> }

            <Formik
            initialValues={{
                password: '',
                confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
                verify: Yup.string()
                    .required('Verification Code is required')
                    .matches(/^[0-9]{6,}$/, { message: "Must Contain 6 Numeric Characters" }),
                // password: Yup.string()
                //     .required('Password is required')
                //     .matches(
                //         /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                //         "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                //     ),
                // confirmPassword: Yup.string()
                //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //     .required('Confirm Password is required'),
            })}
            onSubmit={async fields => {
                setLoading(true)
                //const res = await Services.setSignUpPassword(fields,window.location.href,"customer" )                
                const res = await Services.confirmUserSignup(window.location.href,fields.verify,"customer" )
                //console.log(res,"res")
                if(res.status == 200){
                    let location=window.location.href                   
                    let agentData=jwt.decode(location.split('?q=')[1])

                    /**
                     * Check if customer is coming From ForgetPassword
                     * 
                     */ 
                    if(localStorage.getItem('inComingPage') === 'forgetPassword'){
                        localStorage.removeItem('inComingPage')
                        history.push("/login")
                        return 
                    }

                    var loginagent = await Services.agentlogin(baseData.settings.usersettings.companyidentifier,agentData.user.email,atob(agentData.user.code),"customer");
                        
                        let is_kb_app=false
                        let is_ticket_app= false
                        if(baseData.settings.usersettings.is_kb_app){
                            is_kb_app=true
                        }
                        if(baseData.settings.usersettings.is_ticket_app){
                            is_ticket_app=true
                        }
                        if(loginagent.status == 200){
                            let subId=JSON.parse(loginagent.userdata).idToken.payload.sub
                            //console.log(subId,"subId")
                            setLoading(false)
                            var customerLoginCookies = {
                                "a1840bd4010cc8f4c31a45a679a985cd" : 1
                            }
                            await Services.setCookies(customerLoginCookies);
                            dispatch(loginRequest({ email: agentData.user.email, 
                                org_id: baseData.settings.usersettings.id, 
                                password: atob(agentData.user.code),
                                id:subId, 
                                domainname: baseData.settings.usersettings.domainname,
                                portalname: baseData.settings.usersettings.portalname,
                                companyidentifier: baseData.settings.usersettings.companyidentifier,
                                login_type:"customer",
                                is_ticket_app:is_ticket_app,
                                is_kb_app:is_kb_app,
                                path:"/"}))
                            setTimeout(() => {
                                history.push("/")
                            },1000)
                            // dispatch(setAuthenticationStatus(true))
                            // dispatch(loginSuccess({token:loginagent.accessToken.jwtToken}))
                            
                        }
                        else{
                            toast.error(loginagent.message)
                            setLoading(false)
                            history.push("/login")
                        }
                  }else if(res.status == 400){
                        setLoading(false)
                        toast.error(res.message)
                  }else{
                    setLoading(false)
                    toast.error(res.message);
                }
 
            }}            
        >
            {({ errors, status, touched }) => (
                <Form>
                    <div className="main-tickting-frontend frontends tickting-login-screen">                   
                        <div className="container">                        
                            <div className="login-centerd">                            
                                <div className="login-frontend">
                                <div className="k-flex lock-secure text-right pb-3">
                                        <p className="mr-auto text-light-blue cursor-pointer" onClick={history.goBack}>
                                            <strong>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.038" height="15.81" viewBox="0 0 9.038 15.81">
                                                <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.975,14.1l5.982-5.977a1.13,1.13,0,0,0-1.6-1.6L11.58,13.3a1.128,1.128,0,0,0-.033,1.558l6.806,6.82a1.13,1.13,0,0,0,1.6-1.6Z" transform="translate(-11.251 -6.194)" fill="#64b5f6" />
                                            </svg>                  
                                            </strong>
                                            &nbsp;Go Back</p>
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                            <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                            </svg>
                                            <span>Secure Area</span></p>
                                    </div>
                                    
                                    <div className="login-box">
                                        
                                        <div className="headings-login-signup paraLoginScreenHeading">
                                            {/* <h4 className="card-title">Customer Signup!!!</h4> */}
                                            <h4 className="card-title">Let's Get You Signed Up </h4>
                                            <p className="text-mutede">Please enter the verification code that we sent you on your provided mail to continue signing up.</p>
                                        </div>
                                        <div className="feilds-new">
                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="verify" id="input-char-counter1"  maxLength="6" type="text" className={'form-control' + (errors.verify && touched.verify ? ' is-invalid' : '')} />
                                                    <label htmlFor="input-char-counter1">Enter Verification Code*</label>
                                                    <ErrorMessage name="verify" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                                                                        
                                                <p>
                                                <span className="cursor-pointer text-light-blue card-body-text text-left"
                                                onClick={ async() => {
                                                    setLoading(true)
                                                   
                                                    let resp= await Services.resendOtpSignup(window.location.href,"customer" )
                                                    //console.log(resp,"res")
                                                    if(resp.status == 200){
                                                        setLoading(false)
                                                        toast.success("Confirmation code sent!");                                           
                                                    }else{
                                                        setLoading(false)
                                                        toast.error(resp.message);
                                                    }
                                                }}
                                                >Resend Verification Code</span>
                                            </p>
                                                                                                                                        
                                            <div className="user-feilds">
                                                <div className="login-signup-btn k-flex align-items-center">
                                                    <button disabled={loading} type="submit">Continue</button>
                                                </div>
                                            </div>                                           
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
      </div>
     </div>
    </div>
</div>

</BaseLayout>
       
    )
}

export default CustomerVerify;