import React, { Component, useState, useEffect } from 'react'
import BaseLayout from "../BaseLayout"
import {Tabs, Tab, Modal, Button} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { countries, timeZones, updateUserDetails, fetchUserInfo,changeUserPassword, fetchUserDetails, updateUserDetailsOwnDb, deactivateUserAccount,signouttoken,logout, checkUserPassword, deleteEndUser, exportUserData,fetchUserIp,updateProfileOnComments } from "../../utils/services"
import { showSuccessToast,setAuthUser,setUserProfilePic,signoutUser } from "../../containers/BaseLayout/actions"
import { updateVoteColor } from "../../containers/ArticlePage/actions"
import { toast } from 'react-toastify';
import Loader from "../../components/Shared/Loader";
import InfiniteScroll from 'react-infinite-scroller';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
const jwt = require('jsonwebtoken')
const publicIp = require('public-ip');
var os = require('os');
var platform = require('platform');
const ct = require('countries-and-timezones');
// class Profilesettings extends Component{
const Profilesettings = (props) => {
    const baseData = useSelector(state => state.baseReducer)
    const dispatch = useDispatch()
    const [isChangePassword, setChangePassword] = useState(false)
    const [isEditProfile, setEditProfile] = useState(false)
    const [activeTab, setTabActive] = useState({key:"active"})
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [description, setDescription] = useState('')
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [timezone, setTimezone] = useState('notexist')
    const [isInfo, checkIsInfo] = useState(false)
    const [isLocation, checkIsLocation] = useState(false)
    const [isAutomated, checkIsAutomated] = useState(false)
    const [isArticleUpdates, checkIsArticleUpdates] = useState(false)
    const [isCommunityTopics, checkIsCommunityTopics] = useState(false)
    const [isActivityUpdates, checkIsActivityUpdates] = useState(false)
    const [isAccountDeactivate, setAccountDeactivate] = useState(false)
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [fetchInfo, setFetchInfo] = useState(true);
    const [previewImage, setPreviewImage] = useState('')
    const [imageAsUrl, setImageAsUrl] = useState('');
    const [profilePic, setprofilePic] = useState('');
    const [thumbnail, setThumbnail] = useState('notexist');
    const [pic, setPic] = useState('notexist');
    
    
    const [loading, setLoading] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const handleClose = () => {setShow(false);setModalLoading(false)};
    const handleShow = () => setShow(true);
    const handleDeleteClose = () => {setShowDelete(false);setModalLoading(false)};
    const handleDeleteShow = () => setShowDelete(true);
    const [deactivateConfirm, setDeactivateConfirm] = useState(false)
    const [isDeleteConfirm, setDeleteConfirm] = useState(false)
    const [incorectOldPassword, setIncorrectOldPassword] = useState(false)
    const [incorectNewPassword, setIncorrectNewPassword] = useState(false)
    const [oldPasswordError, setOldPasswordError] = useState("")
    const [newPasswordError, setNewPasswordError] = useState("")
    const [checkPassword,setCheckPassword]=useState('')
    const [checkPasswordErrorStatus,setCheckPasswordErrorStatus]=useState(false)
    const [checkPasswordErrorMessage,setCheckPasswordErrorMessage]=useState('')
    const [showImageUploadError, setShowImageUploadError] = useState(false)
    const [exportDataType, setExportDataType] = useState("Personal")
    //const Countries = countries()
    let Timezones = timeZones()
    const Countries_list = ct.getAllCountries();
    const Countries = Object.values(Countries_list);
    
    // constructor(props){
    //     super(props)
    //     this.state = {
    //         changePass : false,
    //     }
    // }

    // ChangePassword = () => {
    //     this.setState({
    //         changePass : true
    //     })
    // }
    useEffect( () => {
       
        if((Object.keys(baseData.authUser).length >0)){
           // console.log(baseData.settings.usersettings,"baseData.settings.usersettings")
            getUserProfileInfo(baseData.authUser.user_id)
           
        }
       
        //console.log(baseData,"baseData",isEditProfile,name)
    },[baseData?.authUser?.user_id,baseData?.settings?.usersettings?.companyidentifier]);

    const changePassword = (e) =>{
        setChangePassword(true)
        setIncorrectOldPassword(false)
        setIncorrectNewPassword(false)
        setOldPasswordError("")
        setNewPasswordError("")
    }
    const editProfile = () =>{
        setEditProfile(true)
    }
    const cancelChangePassword = () =>{
        setChangePassword(false)
    }

    const cancelEditProfile = () =>{
        setEditProfile(false)
    }

    const setCountryAndCode =(e) =>{
       
        setCountry(e)
        let code=Countries.filter((country)=>{if (country.name === e) return country.id} )
        if(code){
            setCountryCode(code[0].id)
            let timezoneList=code[0].timezones
            //console.log(e,"setCountryAndCode",timezoneList)
            setTimezone(timezoneList[timezoneList.length-1])
        }
        
    }

    const setTimezoneAndCountry = (e) => {
        setTimezone(e)
        const timezoneInfo = ct.getTimezone(e);
        if(timezoneInfo){
            let country=Countries.filter((value)=>{if (value.id === timezoneInfo.country) return value} )
            //console.log(timezoneInfo.country,"timezoneInfo.country",country)
            setCountry(country[0].name)
        }
        
    }

    const getUserProfileInfo = async (user_id) =>{
        console.log(user_id,"fetchInfo",fetchInfo)
        if(fetchInfo){
            //let userInfo = await fetchUserInfo({user_id:user_id})
            // if(userDetails){
            //     setUserInfo(userDetails)
            // }
           
            let decoded= jwt.decode(localStorage.getItem('customer'))
            //let userDetails= await  fetchUserDetails(decoded.email,decoded.domainname,"customer")
            console.log(baseData?.settings?.usersettings?.companyidentifier,"baseData.settings.usersettings.companyidentifier")
            if(baseData?.settings?.usersettings?.companyidentifier){
                //let userDetails= await  fetchUserDetails(decoded.email,decoded.companyidentifier,"customer")
                let comapanyIdentifier =baseData.settings.usersettings.companyidentifier
                let userDetails= await  fetchUserDetails(decoded.email,comapanyIdentifier,"customer")
                console.log(userDetails,"userInfo>>>>>>>>>>>>>>>>>>>>>>>>>>")
                //if(userDetails.status === 200){
                    if(Object.keys(userDetails).length>0){
                        setLoading(true)
                        console.log(userDetails,"userDetails>>")
                       setUserInfo(userDetails)
                   }
                   let userDataInfo = await fetchUserInfo({user_id:user_id, org_id: decoded.org_id})
                   if(userDataInfo.status == 200){
                       setUserDataInfo(userDataInfo.data)
                   }
                //}
            }
           
          
        }     
    }
    // const fetchDetails = async () =>{
    //     let decoded= jwt.decode(localStorage.getItem('customer'))
    //     let userDetails=   fetchUserDetails(decoded.email,decoded.domainname,"customer")
    //     console.log(userDetails.email,"userInfo>>>>>>>>>>>>>>>>>>>>>>>>>>")
    // }
    // fetchDetails()
    const setUserInfo =async (userInfo)=>{
        //console.log(userInfo,"userInfo")
        setFetchInfo(false)
        setEmail(baseData.authUser.email)
        if(userInfo.name){
            setName(userInfo.name)
            
        }
        else{

            let firstname=""
            let lastname=""
            if(userInfo.first_name && userInfo.first_name !== "NA"){
                firstname=userInfo.first_name
            }
            else{
                firstname=userInfo.email.split("@")[0]
            }
            if(userInfo.last_name && userInfo.last_name !== "NA"){
                lastname=userInfo.last_name
            }
            setName(`${firstname} ${lastname}`)
            
        }
        if(userInfo.description && userInfo.description !== "NA"){
            setDescription(userInfo.description)
        }
        if(userInfo.phone_number){
            setPhone(userInfo.phone_number)
        }
        if(userInfo.country && userInfo.country !== "notexist"){
            setCountryAndCode(userInfo.country)
            //setCountryCode(Countries.filter((country)=>{return country.code} ))

        }
        else{
            let fetchIp = await fetchUserIp()
            
            if(fetchIp.ipAddress){
                //console.log(fetchIp,"User Ip>>>>>>")
                setCountryCode(fetchIp.countryCode)
                let country=Countries.filter((value)=>{if (value.id === fetchIp.countryCode) return value} )
                //console.log(country[0].name,"timezoneInfo.country",country)
                setCountry(country[0].name)
                if(country[0]?.timezones){
                    setTimezone(country[0].timezones[country[0].timezones.length-1])
                }
                
            }
        }
        if(userInfo.timezone && userInfo.timezone !== "notexist" && timezone!== "notexist"){
            //setTimezone(userInfo.timezone)
            setTimezoneAndCountry(userInfo.timezone)
        }
        if(userInfo.imageurl && userInfo.pic && userInfo.pic !== "notexist"){
            let profileImage=userInfo.imageurl+'/'+userInfo.pic
            setImageAsUrl(profileImage)
            setprofilePic("notexist")
        }
        if(userInfo.pic){
            setPic(userInfo.pic)
        }
        if(userInfo.thumbnail){
            setThumbnail(userInfo.thumbnail)
        }
        // if(userInfo.data.isInfo){
        //     checkIsInfo(userInfo.data.isInfo)
        // }
        // if(userInfo.data.isLocation){
        //     checkIsLocation(userInfo.data.isLocation)
        // }
        // if(userInfo.data.isAutomated){
        //     checkIsAutomated(userInfo.data.isAutomated)
        // }
        // if(userInfo.data.isArticleUpdates){
        //     checkIsArticleUpdates(userInfo.data.isArticleUpdates)
        // }
        // if(userInfo.data.isCommunityTopics){
        //     checkIsCommunityTopics(userInfo.data.isCommunityTopics)
        // }
        // if(userInfo.data.isActivityUpdates){
        //     checkIsActivityUpdates(userInfo.data.isActivityUpdates)
        // }
        
        setLoading(false)

    }

    const setUserDataInfo = async (userDataInfo) =>{
        if(userDataInfo.isInfo){
            checkIsInfo(userDataInfo.isInfo)
        }
        if(userDataInfo.isLocation){
            checkIsLocation(userDataInfo.isLocation)
        }
        if(userDataInfo.isAutomated){
            checkIsAutomated(userDataInfo.isAutomated)
        }
        if(userDataInfo.isArticleUpdates){
            checkIsArticleUpdates(userDataInfo.isArticleUpdates)
        }
        if(userDataInfo.isCommunityTopics){
            checkIsCommunityTopics(userDataInfo.isCommunityTopics)
        }
        if(userDataInfo.isActivityUpdates){
            checkIsActivityUpdates(userDataInfo.isActivityUpdates)
        }
    }
    const handleFireBaseUpload = (image) => {
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend   = function (e) {
          setPreviewImage([reader.result])
          //let selectedFile = e.target.files[0];
          //console.log(image,"[reader.result]")
        }
        setImageAsUrl(image)
        setprofilePic(image)
        setEditProfile(true)
        //setImageAsUrl(image)
    
        // var name = `${uuidv4()}-${image.name}`
        // var storageRef = firebase.storage().ref();
        // var imageRef = storageRef.child(`agents/${name}`);
        // var uploadTask = imageRef.put(image)
    
        // uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        //   function (snapshot) {
        //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //     console.log('Upload is ' + progress + '% done');
        //   });
        // uploadTask.then(function (snapshot) {
        //   uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        //     setImageAsUrl(downloadURL)
        //   });
        // });
      }
    
      
    // Usage
    
    

    // let handleSelect =  (async(key) =>{
    //     //setTabActive("active")
    //     console.log(key,"key")
    //   })
      let handleSelect = (event) => {
        setTabActive({key:event})
          //console.log(activeTab,"setTabActive")
          setEditProfile(true)
        // this.setState(state => ({
        //   editTodo: {
        //     ...state.editTodo,
        //     title: event.target.value,
        //   },
        // }));
    }
let openDialogDeactivate =()=>{
    setDeactivateConfirm(true)
    handleShow()
}
let openDialogDelete =()=>{
    setDeleteConfirm(false)
    handleDeleteShow()
}
let clearAll = () =>{
    
    logout();
    dispatch(signoutUser())
    dispatch(updateVoteColor({ upvote: false, downvote: false }))
    // setTimeout(async ()=>{
    //     window.location.reload();
    // },5000)
    
}
let deleteUserAccount =async (checkPassword)=>{
    if(checkPassword.trim().length>0){
        setCheckPasswordErrorStatus(false)
        setCheckPasswordErrorMessage("")
        setModalLoading(true)
        //console.log(checkPassword,email,baseData.settings.usersettings.domainname,"customer","checkpassword")
        //const response = await checkUserPassword(checkPassword,email,baseData.settings.usersettings.domainname,"customer")
        const response = await checkUserPassword(checkPassword,email,baseData.settings.usersettings.companyidentifier,"customer")
        //console.log(response,"response")
        if(response.status==200){
            let decode=jwt.decode(localStorage.getItem("customer"))
            let user_id=decode.user_id;
            let org_id=decode.org_id;
            //const del = await deleteEndUser(email,baseData.settings.usersettings.domainname,"customer",user_id,org_id)
            const del = await deleteEndUser(email,baseData.settings.usersettings.companyidentifier,"customer",user_id,org_id)
            setModalLoading(false)
            if(del.status==200){
                dispatch(showSuccessToast('Your account has been permanently deleted.'))
                setTimeout(async ()=>{
                    //await signouttoken().then(data =>{ console.log(data)}) 
                clearAll()
                },3000)

            }
            else{
                dispatch(showSuccessToast('Account has not been deleted due to some technical issue. Please try again.'))
            }
        }
        else{
            setModalLoading(false)
            setCheckPasswordErrorStatus(true)
            setCheckPasswordErrorMessage("Password is incorrect.")
        }
    }
    else{
        setCheckPasswordErrorStatus(true)
        setCheckPasswordErrorMessage("Password is must*")
        //console.log(checkPasswordErrorStatus,checkPasswordErrorMessage,"customer","checkpassword")
    }
    
 
 
    

}

let disableAccount = async () => {
    setAccountDeactivate(true)
    setModalLoading(true)
    let decode=jwt.decode(localStorage.getItem("customer"))
    let user_id=decode.user_id;
    let org_id=decode.org_id;

    let response = await deactivateUserAccount({user_id:user_id,org_id:org_id})
    setModalLoading(false)
    if(response.status==200){
        //console.log(response.message)
        handleClose()
        dispatch(showSuccessToast('Your account has been disabled temporarily.\n However, you can always reactivate it by signing in again.'))
        setTimeout(async ()=>{
            //await signouttoken().then(data =>{ console.log(data)}) 
        clearAll()
        },3000)
     
           
    }
}

let exportData = async(phone,timezone,country,exportDataType) =>{
    setLoading(true)
    if(!modalLoading){
        setModalLoading(true)
    }
    let decode=jwt.decode(localStorage.getItem("customer"))
    let user_id=decode.user_id;
    let org_id=decode.org_id;
    let domainname=decode.domainname;
    let portalname=decode.portalname;
    let companyidentifier=decode.companyidentifier
    let email=decode.email;
    let firstname=""
    if(decode.firstname && decode.firstname.length>0){
        firstname=decode.firstname
    }
    else{
        firstname=email.split('@')[0]
    }

    let exportResponse= await exportUserData({user_id:user_id,org_id:org_id,domainname:domainname,portalname:portalname,companyidentifier:companyidentifier,email:email,firstname:firstname,phone:phone,timezone:timezone,country:country,type:exportDataType})
    if(exportResponse.status == 200){
        //dispatch(showSuccessToast("Data have been exported successfully and sent to your email"))
        dispatch(showSuccessToast("Data export process has been initiated successfully.\n An email regarding the same has been sent to you."))
        setModalLoading(false)

    }
    else{
        toast.error(exportResponse.message);
        setModalLoading(false)
    }
    setLoading(false)

}

        return(
            
            
            
            <BaseLayout>
            { loading && <Loader /> }
            {(localStorage.getItem('customer'))?
             
             <Formik
             enableReinitialize
             initialValues={{
                email: email,
                name:name,
                description:description,
                phone:phone,
                country:country,
                timezone:timezone,
                currentPassword:'',
                password: '',
                confirmPassword: '',
                isChangePassword:isChangePassword,
                isInfo:isInfo,
                isLocation:isLocation,
                isAutomated:isAutomated,
                isArticleUpdates:isArticleUpdates,
                isCommunityTopics:isCommunityTopics,
                isActivityUpdates:isActivityUpdates,
                isAccountDeactivate:isAccountDeactivate,
               
            }}
            validationSchema={Yup.object().shape({
                isChangePassword: Yup.boolean(),
                
                // email: Yup.string()
                //     .email('Email is invalid')
                //     .required('Email is required'),
                
                currentPassword: Yup.string().when('isChangePassword', {
                    is: true,
                    then: Yup.string().required('Current password is required')
                    // .matches(
                    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    // ),
                    }),
                    
                password: Yup.string().when('isChangePassword', {
                    is: true,
                    then: Yup.string().required('Password is required')
                    .matches(
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        { message: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character" }),
                    }),
                    
                confirmPassword: Yup.string().when('isChangePassword', {
                    is: true,
                    then: Yup.string().required('Password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required'),
                    }),
                // phone:Yup.string()
                // .matches(
                //     /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/,
                //     { message: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character" }
                // ),
                

            })}
            onSubmit={async fields => {
                setLoading(true)
                //console.log(fields,"fields",isChangePassword,baseData.settings.usersettings.domainname)
                if(isChangePassword){
                //const updatePassword=await changeUserPassword(baseData.settings.usersettings.domainname,"customer",fields.email,fields.currentPassword,fields.password)
                const updatePassword=await changeUserPassword(baseData.settings.usersettings.companyidentifier,"customer",fields.email,fields.currentPassword,fields.password)
                if(updatePassword.status == 200){
                    //toast.success()
                    dispatch(showSuccessToast('Your password has been successfully changed.'))
                    setChangePassword(false)
                    setLoading(false)
                }
                else if(updatePassword.Message == "Password cannot be same as old password"){
                    setLoading(false)
                    setIncorrectNewPassword(true)
                    setNewPasswordError("Password cannot be same as old password")
                }
                else{
                    setIncorrectOldPassword(true)
                    setOldPasswordError("Incorrect old password")
                    setLoading(false)
                    
                }
                }
                
                else{
                    //if(previewImage !== ""){
                        let alias=""
                        let firstname=""
                        let lastname=""
                        if(name.split(" ")[0]){
                            firstname=name.split(" ")[0]
                        }
                        if(name.split(" ")[1]){
                            lastname=name.split(" ")[1]
                        }
                        //console.log(countryCode,phone,country,timezone,"countryCode")
                        let res = await updateUserDetails(fields.email,baseData.settings.usersettings.domainname,"customer",baseData.settings.usersettings.portalname,alias,fields.timezone,firstname,lastname,profilePic,pic,thumbnail,fields.description,fields.country,fields.phone)
                        
                        if(res.status === "200"){
                            let userdetails= await fetchUserDetails(fields.email,baseData.settings.usersettings.companyidentifier,"customer")
                            // if(Object.keys(userdetails).length > 0 && imageAsUrl !== ""){
                            if(Object.keys(userdetails).length > 0){
                                let is_kb_app=false
                                let is_ticket_app=false
                                if(baseData.settings.usersettings.is_kb_app){
                                    is_kb_app=baseData.settings.usersettings.is_kb_app
                                }
                                if(baseData.settings.usersettings.is_ticket_app){
                                    is_ticket_app=baseData.settings.usersettings.is_ticket_app
                                }
                                let resp = await updateUserDetailsOwnDb(fields,baseData.settings.usersettings.id,baseData.authUser.user_id,userdetails.imageurl,userdetails.pic,userdetails.thumbnail,is_kb_app,is_ticket_app,baseData.settings.usersettings.domainname,baseData.settings.usersettings.portalname,"customer",firstname,lastname)
                                await updateProfileOnComments({imageurl:userdetails.imageurl,thumbnail:userdetails.thumbnail,commentator_id:baseData.authUser.user_id,user_id:baseData.settings.usersettings.userId})

                                //console.log(resp,"resp")
                                //baseData.authUser['photo']=userdetails.imageurl+'/'+
                                //localStorage.setItem("customer",resp.token)
                                //let decode=jwt.decode(resp.token)
                                //dispatch(setAuthUser(decode))
                                console.log(profilePic,"profilePic>>>>")
                                if(profilePic !== "notexist"){
                                    dispatch(setUserProfilePic(resp.token))
                                    window.location.reload()
                                }
                            }
                        }
                        setLoading(false)
                        setEditProfile(false)
                        dispatch(showSuccessToast('Your profile has been successfully updated.'))

                        //}
                    // let res = await updateUserDetails(fields,baseData.authUser.user_id)
                    // //console.log(res,"updateUserDetails")
                    // getUserProfileInfo(baseData.authUser.user_id)
                    // window.location.reload()
                }
            }}
            >
             
             {({ errors, status, touched }) => (
            <Form>
            <div className="mainFullWidth">
                <div className="forms-section ticket-section box-section my-3">
                    <div className="container">
                    <>

                <Modal className="disableModel" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header className="py-0 border-bottom-0" closeButton>
                    {/* <Modal.Title></Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="py-0 px-0">
                    { modalLoading && <Loader /> }
                        <div className="mb-4">
                            <h4 className="card-title mb-0 font-bold mb-3">Temporarily Disable My Account</h4>
                            <p className="card-body-text text-dark-gray">Are you sure you want to disable your account temporarily?</p>
                        </div>
                        
                          
                            
                            {!(deactivateConfirm)?
                            <>
                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={()=>{setDeactivateConfirm(true)}}>Confirm</button>
                            <span className="ml-2 mr-3 cursor-pointer text-light-blue-hove" onClick={handleClose}>Cancel</span>
                            </>:
                            <>
                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={handleClose}>Cancel</button>
                            <span className="ml-2 mr-3 cursor-pointer text-light-blue"  onClick={() => { disableAccount()}}>Yes, disable it</span>
                            </>
                            }
                            
                           
                    </Modal.Body>
                </Modal>
                <Modal className="disableModel" show={showDelete} onHide={handleDeleteClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header className="py-0 border-bottom-0" closeButton>
                    {/* <Modal.Title></Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="py-0 px-0">
                    { modalLoading && <Loader /> }
                        <div className="mb-4">
                            <h4 className="card-title mb-0 font-bold mb-3">Delete My Account</h4>
                            {!(isDeleteConfirm)?
                            <>
                            <p className="card-body-text text-dark-gray">This profile's information (personal, location-based, preferences, contacts and activities) will be deleted forever, and you won't be able to access it again.
                            </p>
                            <p className="card-body-text mt-3 text-dark-gray">Before you delete your account, you can download all the data associated with you by selecting any of the option below.</p>
                            <div className="button-password mt-4 k-flex align-items-center">
                                                            <div className="md-form md-form-custom my-0 mr-3" style={{ width: '200px'}}>
                                                                <select value={exportDataType} className="customArrow customArrowBorder0 text-dark-gray" onChange={(e)=>{setExportDataType(e.target.value)}}>
                                                                    <option value="personal">Personal Information</option>
                                                                    <option value="tickets">Your Tickets</option>
                                                                    <option value="all">All</option>
                                                                </select>
                                                            </div>
                                                            {/* <button className="info-btn form-btn py-2 px-4" type="button" disabled={false}  onClick={()=> exportData(phone,timezone,country,exportDataType)}>Create File</button> */}
                                                            <div className="button-password mt-1">
                                                            <p className="text-light-blue card-body-text" >
                                                            <span className="cursor-pointer text-light-blue py-1 px-1 text-decoration-underline" onClick={()=> exportData(phone,timezone,country,exportDataType)}>Download Data</span>

                                                            </p>

                                                            </div>
                                                            
                                                        </div>
                            <p className="card-body-text font-bold mt-4">Are you sure you want to delete it?</p>
                            </>
                            :<>
                            <p className="card-body-text text-dark-gray mt-4 mb-4">To delete account please you're required to enter your password.</p>
                            <div className="profile-title-output">
                                <div className="md-form md-form-custom my-0 enter_pass_profile_popup">
                                    <input name="checkpassword" id="input-char-counter1" type="password" onChange={(e) => setCheckPassword(e.target.value)} autoFocus/>
                                    <label for="input-char-counter1" class="active" >Enter Password*</label>
                                </div>
                            {/* <input name="checkpassword" id="input-char-counter1" placeholder="Enter Password*"  type="password"  onChange={(e) => setCheckPassword(e.target.value)}/> */}
                            {/* <ErrorMessage name="checkpassword" component="div" className="invalid-feedback" /> */}
                            {(checkPasswordErrorStatus)?
                            // <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                            <div className="invalid-feedback-checkbox"  >{checkPasswordErrorMessage}</div>
                            :null
                            }
                            </div>
                            
                            </>}
                            
                        </div>
                        
                          
                            
                            {!(isDeleteConfirm)?
                            <>
                            <button className="info-btn form-btn py-2 px-4" type="button"  onClick={handleDeleteClose}>Cancel</button>
                            <span className="ml-2 mr-3 cursor-pointer text-light-blue text-decoration-underline" onClick={()=>{setDeleteConfirm(true)}}>Delete my account permanently.</span>
                            {/* <div className="button-password mt-3">
                                                            <p className="text-light-blue card-body-text" ><span className="ml-2 mr-3 cursor-pointer text-decoration-underline" onClick={()=>{setDeleteConfirm(true)}}>Delete my account permanently</span></p>
                                                        </div> */}
                            </>:
                            <>
                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={()=> deleteUserAccount(checkPassword)}>Submit</button>
                            <span className="ml-2 mr-3 cursor-pointer text-dark-gray"  onClick={handleDeleteClose}>Cancel</span>
                            </>
                            }
                            
                           
                    </Modal.Body>
                </Modal>
            </>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="sumbit-ticket profileSettings mt-5">
                            <div className="ticket-area-tabs position-relative">
                            {/* <ul id="tabs" className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                <a id="tab-A" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">View Tickets</a>
                                </li>
                                <li className="nav-item">
                                <a id="tab-B" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Timeline</a>
                                </li>
                            </ul> */}
                            {(!isEditProfile)?
                            <div className="button-ticket-edit-profile">
                            
                                <button className="info-btn form-btn py-2 px-4" onClick={()=>{editProfile()}}>Edit Profile</button>
                            </div>
                                :
                            <div className="button-ticket-edit-profile">
                                <span className="mr-3 cursor-pointer" onClick={()=>{cancelEditProfile()}}>Cancel</span>
                                <button className="info-btn form-btn py-2 px-4" type="submit">Update</button>
                                </div>
                            }
                            
                            {/* <Tabs defaultActiveKey="active" id="controlled-tab-example"> */}
                            <Tabs activeKey={activeTab.key} onSelect={key => setTabActive({ key })}  id="controlled-tab-example">
                                <Tab eventKey="active"  title="Profile Settings" className="mt-4">
                                    
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                                            <div className="profile-settings-photo">
                                                {/* {console.log(previewImage,"previewImage",imageAsUrl)} */}
                                                {/* <img src="./images/user.png" /> */}
                                                <img src={(previewImage) ? previewImage : (imageAsUrl) ? imageAsUrl : "./images/user.png"} alt="" />

                                            </div>
                                            <div className="text-center mt-2">
                                            <label htmlFor="editImage" className="editImageAdd">
                                            <input type="file" id="editImage" accept="image/x-png,image/jpeg" className="d-none" name="userimage"
                                            onChange={(e) => {
                                                if ((e.target.files[0].type === "image/png" && e.target.files[0].size < 2097152) || (e.target.files[0].type === "image/jpeg" && e.target.files[0].size < 2097152)) {
                                                handleFireBaseUpload(e.target.files[0])
                                                } else {
                                                e.target.value = null;
                                                setShowImageUploadError(true)
                                                setTimeout(() => {
                                                    setShowImageUploadError(false)
                                                }, 2000)

                                                }
                                            }}
                                            />
                                                <p className="card-body-text text-light-blue text-center cursor-pointer">Update Profile Photo</p>
                                                </label>
                                                {(showImageUploadError) ?
                                                <p className="invalid-feedback-checkbox">Only jpg, jpeg, and png allowed & size lesser than 2 MB.</p>
                                                : null}
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                                            <div className="profile-settings-details">
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Name</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                        {(!isEditProfile)?
                                                        <input type="text" placeholder="Enter Name" value={`${name}`} className="text-dark-gray" disabled="disabled"/>

                                                            :
                                                        <Field name="name" id="input-char-counter1"  type="text"  className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} onChange={(e) => setName(e.target.value)} />

                                                        }
                                                        {/* <input type="text" placeholder="Enter Name" value="John Brandon D’Costa" className="text-dark-gray" /> */}
                                                    </div> 
                                                </div>
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Description</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                    {(!isEditProfile)?
                                                        <textarea type="text" rows="5" placeholder="Enter Description" value={description}  className="text-dark-gray" style={{"caret-color": "transparent"}} readonly/>
                                                        //<input type="text" rows="5" placeholder="Enter Description" value={description} className="text-dark-gray" disabled="disabled"/>
                                                        
                                                        :
                                                        <Field name="description" maxLength={1500} component="textarea"  type="text"  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} onChange={(e) => setDescription(e.target.value)}/>

                                                        }  
                                                    </div> 
                                                </div> 
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Email ID</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                        
                                                        <input type="text" placeholder="Enter Email ID" value={`${email}`} className="text-dark-gray" disabled="disabled"/>
                                                    </div> 
                                                    {/* {(!isEditProfile)?
                                                    <div className="profile-title-output">
                                                        
                                                        <input type="text" placeholder="Enter Email ID" value={`${email}`} className="text-dark-gray" />
                                                    </div> 
                                                        :
                                                        <div className="profile-title-output">
                                                        
                                                        <Field name="email"   type="text"  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                    </div> 
                                                        } */}
                                                </div> 
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Phone</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                        {/* <input type="text" placeholder="Enter Phone" value="+91-9876543221" className="text-dark-gray" /> */}

                                                        {(!isEditProfile)?
                                                        <PhoneInput type="text" placeholder="Enter Phone" value={phone} className="text-dark-gray" disabled="disabled"/>
                                                        :
                                                        // <Field name="phone" id="input-char-counter1"  type="phone"  className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                                        <PhoneInput name="phone" value={phone}  type="phone"  className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} onChange={setPhone}/>

                                                        }  
                                                        
                                                    </div> 
                                                </div> 
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Country</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                    {(!isEditProfile)?
                                                    <select className="customArrow1 customArrowBorder0 text-dark-gray" disabled="disabled">
                                                        <option>{country}</option>
                                                    </select>:
                                                        <select as="select" className="customArrow customArrowBorder0 text-dark-gray" value={country} onChange={e => setCountryAndCode(e.target.value)}>
                                                            {/* <option>USA</option> */}
                                                            
                                                            {Countries.map((country,i) =>
                                                            <option key={i} value={country.name}>{country.name}</option>
                                                            )}
                                                            

                                                        </select>
                                                        }
                                                    </div> 
                                                </div> 
                                                <div className="profile-details-row">
                                                    <div className="profil-title">
                                                        <h5 className="card-title mb-0">Time Zone</h5>   
                                                    </div> 
                                                    <div className="profile-title-output">
                                                        {/* <select className="customArrow customArrowBorder0 text-dark-gray">
                                                            <option>USA</option>
                                                        </select>  */}
                                                        {(!isEditProfile)?
                                                    <select className="customArrow1 customArrowBorder0 text-dark-gray" disabled="disabled">
                                                        <option>{timezone}</option>
                                                    </select>:
                                                        <select as="select" className="customArrow customArrowBorder0 text-dark-gray" value={timezone} onChange={e => setTimezoneAndCountry(e.target.value)} >
                                                        {/* <option>USA</option> */}
                                                        
                                                        {Timezones.map((timezone,i) =>
                                                        <option key={i} value={timezone.value}>{timezone.value}</option>
                                                        )}
                                                    </select>
                                                        
                                                        }
                                                    </div>  
                                                </div>  
                                                
                                                {(isChangePassword ? 
                                                <div className="changePassword mt-3">
                                                    <h5 className="card-title mb-2 chngePas-heidng">Change Password</h5>
                                                    <div className="changePasswordProfile">
                                                        <div className="profile-details-row">
                                                            <div className="profil-title">
                                                                <h5 className="card-title mb-0">Current Password*</h5>   
                                                            </div> 
                                                            <div className="profile-title-output">
                                                                {/* <input type="password" placeholder="Enter Current Password" /> */}
                                                                <Field name="currentPassword" placeholder="Enter Current Password" onClick={()=>{changePassword()}}  type="password" className={'form-control' + (errors.currentPassword && touched.currentPassword ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="currentPassword" component="div" className="invalid-feedback" />
                                                                
                                                                {(incorectOldPassword)?
                                                               
                                                                <div className="invalid-feedback-checkbox"  >{oldPasswordError}</div>
                                                                :null
                                                                }
                                                            </div> 
                                                        </div>
                                                        <div className="profile-details-row">
                                                            <div className="profil-title">
                                                                <h5 className="card-title mb-0">New Password*</h5>   
                                                            </div> 
                                                            <div className="profile-title-output">
                                                                {/* <input type="password" placeholder="Enter New Password" /> */}
                                                                <Field name="password" placeholder="Enter Current Password" onClick={()=>{changePassword()}}  type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                                {(incorectNewPassword)?
                                                               
                                                               <div className="invalid-feedback-checkbox"  >{newPasswordError}</div>
                                                               :null
                                                               }
                                                            </div> 
                                                        </div>
                                                        <div className="profile-details-row">
                                                            <div className="profil-title">
                                                                <h5 className="card-title mb-0">Confirm New Password*</h5>   
                                                            </div> 
                                                            <div className="profile-title-output">
                                                                {/* <input type="password" placeholder="Enter New Password again" /> */}
                                                                <Field name="confirmPassword" placeholder="Enter Current Password" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                            </div> 
                                                        </div>
                                                        <div className="important-notes-pass py-3">
                                                            <p className="card-body-text text-dark-gray"><strong>Important Note : </strong>Password must contain a special charecter (!@#$% etc), a capital letter, a number and should be of 8-16 charecter limit.</p>
                                                        </div>
                                                        <div className="button-password text-right mt-3">
                                                            <span className="mr-3 cursor-pointer" onClick={()=>{cancelChangePassword()}}>Cancel</span>
                                                            <button type="submit" className="info-btn form-btn py-2 px-4">Update Password</button>
                                                        </div>
                                                    </div>
                                                </div> : 
                                                <div className="profile-details-row">
                                                <div className="profil-title">
                                                    <h5 className="card-title mb-0">Change Password</h5>   
                                                </div> 
                                                <div className="profile-title-output k-flex">
                                                    <p className="card-body-text text-dark-gray">**********</p> 
                                                    <div className="ml-auto">
                                                        {/* <p className="card-body-text text-light-blue cursor-pointer" onClick={this.ChangePassword}>Edit Password</p>    */}
                                                        <p className="card-body-text text-light-blue cursor-pointer" onClick={()=>{changePassword()}}>Edit Password</p>   
                                                    </div>
                                                </div> 
                                            </div>  
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile"  title="Data Privacy & Management" className="mt-4 my_profile_he scroll-4">
                                <div className="data-Privacy-row mb-5">
                                <h5 className="card-title mb-3">In compliance with the General Data Protection Regulation (GDPR), we’ve provided some tools to enable customers to have primary control over their data.</h5>
                                </div>
                                    <Tabs>
                                        <Tab eventKey="active" title="View & Edit Profile" className="mt-4">
                                        <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="data-Privacy-row mb-5">
                                                    <div className="Managemnt-row">
                                                    <h5 className="card-title mb-3">View & Edit Profile :</h5>
                                                        <p className="card-body-text">Your profile consists of all the personal information like email address, name, email id and other important digital information you may have shared with us.  You can view and edit your personal information you have shared with us by clicking on the button below.</p>
                                                        <div className="button-password mt-3">
                                                            <button type="button" onClick={()=> handleSelect("active")} className="info-btn form-btn py-2 px-4">View & Edit Profile</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                        <Tab eventKey="consent" title="Consent & Notifications" className="mt-4">
                                        <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="dataPrivacyManagemnt">
                                            <div className="data-Privacy-row mb-5">
                                                    <h5 className="card-title mb-3">Since we respect your data privacy, you can always opt-in or opt-out from the kind of information you share with us by clicking on the consent boxes below anytime.</h5>
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Personal Information :</h5>
                                                        <p className="card-body-text">We require your personal information like name, phone number, email id to uniquely identify you so that we can communicate with you regarding actions taken by us to resolve your issues.</p>
                                                        <div className="button-password mt-3">
                                                            <label className="containerCheckBox">
                                                                <span>I agree that my personal details like name, phone number and email id can be used to identify me and communicate with me.</span>
                                                              
                                                                <Field type="checkbox" name="isInfo" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                                                                
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Location :</h5>
                                                        <p className="card-body-text">We require your digital information like IP Address and Time-Zone to know your location so that we can better understand your issues which are location-based or time-based for faster resolution.</p>
                                                        <div className="button-password mt-3">
                                                            <label className="containerCheckBox">
                                                                <span>I agree that my location-based and time-based data can be used for faster resolution of my issues.</span>
                                                               
                                                                <Field type="checkbox"  name="isLocation" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Automated Individual Decision-Making :</h5>
                                                        <p className="card-body-text">We may require your personal information for automated individual decision-making systems to speed up your issue resolution times through categorization of issues into their types like questions, feature-requests and  problems. 
Through automated individual decision-making, we may also segment issues raised by you into categories of products and services so that your issue can be taken to our concerned department for better handling and time management.</p>
                                                        <div className="button-password mt-3">
                                                            <label className="containerCheckBox">
                                                                <span>I agree that my location-based and time-based data can be used for faster resolution of my issues.</span>
                                                              
                                                                <Field type="checkbox" name="isAutomated" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Notifications and Content Promotion :</h5>
                                                        <p className="card-body-text">Join our mailing list to stay informed about all the latest articles added by us, community topics and activities happening on your raised issues.</p>
                                                        <div className="button-password mt-3">
                                                            <p><label className="containerCheckBox">
                                                                <span>Send me updates on latest articles</span>
                                                               
                                                                <Field type="checkbox"  name="isArticleUpdates" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label>
                                                            </p>
                                                            <p>
                                                            <label className="containerCheckBox">
                                                                <span>Send me updates on community topics</span>
                                                               
                                                                <Field type="checkbox"  name="isCommunityTopics" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label> 
                                                            </p>
                                                            <p>
                                                            <label className="containerCheckBox">
                                                                <span>Send me updates on activities happening on my raised issues</span>
                                                               
                                                                <Field type="checkbox"  name="isActivityUpdates" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            </div>
                                        </div>
                                        </Tab>
                                    
                                    <Tab eventKey="data-export" title="Data Export" className="mt-4">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="data-Privacy-row mb-5">
                                    <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Data Export :</h5>
                                                        <p className="card-body-text">Download a copy of your information stored with us to your device.</p>
                                                        <div className="button-password mt-3 k-flex align-items-center">
                                                            <div className="md-form md-form-custom my-0 mr-3" style={{ width: '200px'}}>
                                                                <select value={exportDataType} className="customArrow customArrowBorder0 text-dark-gray" onChange={(e)=>{setExportDataType(e.target.value)}}>
                                                                    <option value="personal">Personal Information</option>
                                                                    <option value="tickets">Your Tickets</option>
                                                                    <option value="all">All</option>
                                                                </select>
                                                            </div>
                                                            <button className="info-btn form-btn py-2 px-4" type="button" disabled={false}  onClick={()=> exportData(phone,timezone,country,exportDataType)}>Create File</button>
                                                        </div>
                                                        {/* <select className="customArrow1 customArrowBorder0 text-dark-gray">
                                                        <option>Personal Information</option>
                                                        <option>Your Tickets</option>
                                                        <option>All</option>
                                                    </select> */}
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="disable-profile" title="Disable & Delete Profile" className="mt-4">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="data-Privacy-row mb-5">
                                        <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Temporarily Disable My Account :</h5>
                                                        <p className="card-body-text">You can disable your account temporarily to stop receiving any communication from us, feature updates, community topics and any action taken on your issues by clicking on the button below.
                                                            However, you can always re-activate your account by signing in again.
                                                        </p>
                                                        <div className="button-password mt-3">
                                                            <p className="text-light-blue card-body-text"><span className="cursor-pointer text-decoration-underline" onClick={()=>openDialogDeactivate()}>Disable my account temporarily</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Delete My Account :</h5>
                                                        <p className="card-body-text">You can delete your account permanently by clicking on the button below.</p>
                                                        <div className="button-password mt-3">
                                                            <p className="text-light-blue card-body-text" ><span className="cursor-pointer text-decoration-underline" onClick={()=>openDialogDelete()}>Delete my account</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                            
                                    </Tab>
                                    </Tabs>
                                
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="dataPrivacyManagemnt">
                                                {/* <div className="data-Privacy-row mb-5">
                                                    <h5 className="card-title mb-3">In compliance with the General Data Protection Regulation (GDPR), we’ve provided some tools to enable customers to have primary control over their data.</h5>
                                                    <div className="Managemnt-row">
                                                        <p className="card-body-text">Your profile consists of all the personal information like email address, name, email id and other important digital information you may have shared with us.  You can view and edit your personal information you have shared with us by clicking on the button below.</p>
                                                        <div className="button-password mt-3">
                                                            <button type="button" onClick={()=> handleSelect("active")} className="info-btn form-btn py-2 px-4">View & Edit Profile</button>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                
                                                {/* <div className="data-Privacy-row mb-5">
                                                    <h5 className="card-title mb-3">Since we respect your data privacy, you can always opt-in or opt-out from the kind of information you share with us by clicking on the consent boxes below anytime.</h5>
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Personal Information :</h5>
                                                        <p className="card-body-text">We require your personal information like name, phone number, email id to uniquely identify you so that we can communicate with you regarding actions taken by us to resolve your issues.</p>
                                                        <div className="button-password mt-3">
                                                            <label className="containerCheckBox">
                                                                <span>I agree that my personal details like name, phone number and email id can be used to identify me and communicate with me.</span>
                                                              
                                                                <Field type="checkbox" name="isInfo" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                                                                
                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Location :</h5>
                                                        <p className="card-body-text">We require your digital information like IP Address and Time-Zone to know your location so that we can better understand your issues which are location-based or time-based for faster resolution.</p>
                                                        <div className="button-password mt-3">
                                                            <label className="containerCheckBox">
                                                                <span>I agree that my location-based and time-based data can be used for faster resolution of my issues.</span>
                                                               
                                                                <Field type="checkbox"  name="isLocation" onClick={() => setEditProfile(true)} className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />

                                                                <span className="checkmark" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                
                                                

                                                {/* <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Data Export :</h5>
                                                        <p className="card-body-text">Download a copy of your information stored with us to your device.</p>
                                                        <div className="button-password mt-3">
                                                            <button className="info-btn form-btn py-2 px-4" type="button" disabled={false}  onClick={()=> exportData(phone,timezone,country)}>Export</button>
                                                          
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Temporarily Disable My Account :</h5>
                                                        <p className="card-body-text">You can disable your account temporarily to stop receiving any communication from us, feature updates, community topics and any action taken on your issues by clicking on the button below.
                                                            However, you can always re-activate your account by signing in again.
                                                        </p>
                                                        <div className="button-password mt-3">
                                                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={()=>openDialogDeactivate()}>Disable my account temporarily</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-Privacy-row mb-3">
                                                    <div className="Managemnt-row">
                                                        <h5 className="card-title mb-3">Delete My Account :</h5>
                                                        <p className="card-body-text">You can delete your account permanently by clicking on the button below.</p>
                                                        <div className="button-password mt-3">
                                                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={()=>openDialogDelete()}>Delete my account</button>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                    </div>
                               
                                </Tab>
                            </Tabs>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                
                
                <div className="tickting-frontend-footer">
                    <div className="footer footer-white">
                    <div className="container">
                        <div className="footerMenuSection k-flex align-items-center">
                        <div className="footer-menu">
                            <img src="./images/brand.png" />
                        </div>
                        <div className="footer-social">
                            <ul className="list-style-none">
                            <li><a className="text-dark-gray " href="">Powered by Appy Pie</a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </Form>
            )}
            </Formik>
            
            :
            window.location.href="/"}
            </BaseLayout>
            
           
        );
   
}



export default Profilesettings;






