/***
 * This is forget password section customer section 
 * 
 */
import React,{useState} from "react"
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { useHistory } from "react-router-dom"
import {useSelector,useDispatch} from "react-redux"
import BaseLayout from "../BaseLayout" 
import {
   // forgotPasswordSendCodeRequest,
    setverifyPasswordPage
} from "../BaseLayout/actions"
import { forgetpassword,checkuser,forgetOwnerPassword ,fetchCustomerInfo , sendOptToUnVerifiedCustomer, getTokenForOTPVerification} from "../../utils/services"
import { toast } from 'react-toastify';
import Loader from "../../components/Shared/Loader"

const Index = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()
    const baseData = useSelector(state => state.baseReducer)
    
    if (baseData.loadVerifyPasswordPage) {
        history.push(`/confirm-password/${btoa(email)}`)
        dispatch(setverifyPasswordPage(false));
    }

    return (
        <BaseLayout>
        { loading && <Loader />}

        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Email is invalid')
                    .required('Email is required'),
            })}
            onSubmit={async fields => {
                setEmail(fields.email)
                setLoading(true)
                const login_type="customer"
                callForgetPassword(login_type)

                async function callForgetPassword(login_type){
                    /**
                     * checkUser api is not in use now as verify token is implemented. 
                     * Therefore it is commented
                     */   
                    var forget = await forgetpassword(baseData.settings.usersettings.companyidentifier,fields.email,login_type);
                        if(forget.status == 200){
                            toast.success(forget.message);
                            setTimeout(()=>{
                                setLoading(false)            
                            history.push(`/confirm-password/${btoa(fields.email)}/${login_type}`)
                        },3000)
                            //history.push(`/verify`)
                        }else if(forget.status == 400){
                            if(login_type=='customer'){                                
                                // in case of customer we need to send otp page                               
                                setLoading(false)
                                // toast.error('Your account is not verified, Please verify you account by provide otp');
                                let  customer_info = {                                    
                                    email: fields.email, 
                                    org_id: baseData.settings.usersettings.id, 
                                }
                                const customerInfo = await fetchCustomerInfo(customer_info)
                                
                                if(customerInfo.results.uid){

                                    let obj= { 
                                        uid: customerInfo.results.uid,
                                        email: fields.email, 
                                        password: '', 
                                        org_id: baseData.settings.usersettings.id,
                                        portalName:baseData.settings.usersettings.portalname,
                                        companyidentifier:baseData.settings.usersettings.companyidentifier,
                                        url:`https://${window.location.hostname.split('.')[0]}.appypiedesk.com`
                                    }

                                    console.log('send resendOtpSignup')
                                    let resp= await sendOptToUnVerifiedCustomer(window.location.href,"customer",obj )
                                    
                                    if(resp.status == 200){
                                        setLoading(false)
                                        // if user unverified then redirect 
                                        const optVerification = await  getTokenForOTPVerification(obj)
                                        toast.success("Your account is not verified, Please verify you account, The verification code has been sent to your email address");
                                        setLoading(false)
                                        console.log('optVerification...', optVerification)
                                        localStorage.setItem('inComingPage','forgetPassword');
                                        history.push(`/customer-verify/${optVerification.userToken}`)                                          
                                    }
                                    else{
                                        setLoading(false)
                                        toast.error(resp.message);
                                    }
                                }else{
                                    setLoading(false)
                                    toast.error('Your account is not exist in our system');
                                }
                            }else{
                                setLoading(false)
                                toast.error(forget.message);
                            }
                        }
                        else{
                            setLoading(false)
                            toast.error(forget.message);
                        }
                }

            }}
        >
            {({ errors, status, touched }) => (
                <Form>
                    <div className="main-tickting-frontend frontends tickting-login-screen">
                        <div className="container">
                            <div className="login-centerd">
                                <div className="login-frontend">
                                    <div className="lock-secure text-right pb-3">
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                        <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                        </svg>
                                        <span>Secure Area</span></p>
                                    </div>
                                    <div className="login-box">
                                            <div className="goBackForget" onClick={history.goBack}>
                                                {/* <a href=""> */}
                                                <span className="goBackArrow cursor-pointer">
                                                    <svg id="_4781858_arrow_arrows_back_direction_left_icon" data-name="4781858_arrow_arrows_back_direction_left_icon" xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 32 32">
                                                        <g id="Layer_2" data-name="Layer 2" transform="translate(2.994 5.996)">
                                                            <path id="Path_2561" data-name="Path 2561" d="M28,26a1,1,0,0,0,.71-1.71L20.41,16l8.3-8.29a1,1,0,1,0-1.42-1.42l-9,9a1,1,0,0,0,0,1.42l9,9A1,1,0,0,0,28,26Z" transform="translate(-17.994 -5.996)" fill="#64B5F6" />
                                                            <path id="Path_2562" data-name="Path 2562" d="M4,17H28a1,1,0,0,0,0-2H4a1,1,0,0,0,0,2Z" transform="translate(-2.994 -5.996)" fill="#64B5F6" />
                                                        </g>
                                                        <g id="frame">
                                                            <rect id="Rectangle_1789" data-name="Rectangle 1789" width={32} height={32} fill="none" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span className="goBack text-light-blue pl-2 cursor-pointer" >Go Back</span>
                                                {/* </a> */}
                                            </div>
                                        <div className="headings-login-signup paraLoginScreenHeading">
                                            <h4 className="card-title">Forgot Password ?</h4>
                                            <p className="text-mutede">Type your Email Id to receive OTP for creating a new password.</p>
                                        </div>
                                        <div className="feilds-new">
                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="email" id="input-char-counter1" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter1">Email ID*</label>
                                                </div>
                                            </div>
                                            <div className="user-feilds">
                                                <div className="login-signup-btn k-flex align-items-center">
                                                    <button disabled={loading} type="submit">Continue</button>
                                                </div>
                                            </div>
                                            {baseData.forgotPasswordSendCodeError ?
                                                <p className="errorText">{baseData.forgotPasswordSendCodeError}</p>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div> </div>
                            {/* <div className="loginFooterReview registrationImgFilter NewFooterFrnt">
                                        <div className="review-section">
                                            <div className="review-companies">
                                                <div className="reviewcompanieswrapper">
                                                    <div className="reviewcompaniesimg"><a href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47" target="_blank" rel="noopener noreferrer"><img src='/images/login1.png' alt="login1"/></a></div>
                                                </div>
                                                <div className="reviewcompanieswrapper">
                                                    <div className="reviewcompaniesimg"><a rel="noopener noreferrer"><img src='/images/login2.png' alt="login2" /></a></div>
                                                </div>
                                                <div className="reviewcompanieswrapper">
                                                    <div className="reviewcompaniesimg"><a rel="noopener noreferrer"><img src='/images/login3.png' alt="login3" /></a></div>
                                                </div>
                                                <div className="reviewcompanieswrapper">
                                                    <div className="reviewcompaniesimg"><a rel="noopener noreferrer"><img src='/images/login4.png' alt="login4" /></a></div>
                                                </div>
                                                <div className="reviewcompanieswrapper">
                                                    <div className="reviewcompaniesimg"><a rel="noopener noreferrer"><img src='/images/login5.png' alt="login5" /></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                    </div>

                </Form>
            )}
        </Formik>
        </BaseLayout>
    );
}

export default Index;