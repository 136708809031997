import {createStore,applyMiddleware,compose} from 'redux'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./rootReducer"
import rootSaga from "../utils/rootSagas"

const sagaMiddleware = createSagaMiddleware()

let store;
if(process.env.REACT_APP_WORK_MODE === 'DEVELOPMENT'){
     store = createStore(rootReducer,composeWithDevTools(applyMiddleware(sagaMiddleware,logger)))
}else{
     store = createStore(rootReducer,compose(applyMiddleware(sagaMiddleware)))
}

//const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(sagaMiddleware)))
// const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(sagaMiddleware)))
//  const store = createStore(
//     rootReducer,
//      initialState,
//      compose(
//          applyMiddleware(...sagaMiddleware),
//          window.__REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION_()
//      )
//  )

sagaMiddleware.run(rootSaga)
export default store;