import React, { useState ,useEffect} from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { Link } from "react-router-dom"
import BaseLayout from "../BaseLayout"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { 
    // signupRequest, 
    setLoadVerifyPage,
    resetErrors 
} from "../BaseLayout/actions";
import { 
    customerSignup ,
    postApiCall,
    // agentlogin,
    getTokenForOTPVerification
} from "../../utils/services"
import { toast } from 'react-toastify';
import Loader from "../../components/Shared/Loader"
import queryString from 'query-string'

const Index = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory();
    const baseData = useSelector(state => state.baseReducer)
    const params = queryString.parse(props.location.search)
    let emailValue=""
    // if (baseData.loadverifyPage) {
    //     history.push(`/verify/${btoa(email)}`)
    //     dispatch(setLoadVerifyPage(false));
    // }
    
    useEffect(() => {  
        
        // history.push(`/signup?org_id=${params.org_id}&email_id=${btoa(email)}`)
        if(params.org_id && params.email_id){
            let email=atob(params.email_id)
             window.location.href = `kb/signup?org_id=${params.org_id}&email_id=${btoa(email)}`
        }else{
            window.location.href = `kb/signup`; 
        }
    },[])

    
    return (
       
        <BaseLayout>
         { true && <Loader />}
            <Formik
            enableReinitialize
                initialValues={{
                    email: email,
                    password: '',
                    confirmPassword: '',
                    acceptTerms: false,
                    marketingTerms: false,
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email('Email is invalid')
                        .required('Email is required'),

                    password: Yup.string().required('Password is required')
                        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_\$%\^&\*\-])(?=.{8,})/, 
                        { message: "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character" }),

                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Confirm Password is required'),

                    acceptTerms: Yup.bool()
                        .oneOf([true], 'Accept Ts & Cs is required')
                        .required('Accept Ts & Cs is required'),
                })}

                onSubmit={async fields => {
                    
                    setLoading(true)                    
                    let obj={ email: fields.email, password: fields.password, org_id: baseData.settings.usersettings.id,portalName:baseData.settings.usersettings.portalname,companyidentifier:baseData.settings.usersettings.companyidentifier,url:`https://${window.location.hostname.split('.')[0]}.appypiedesk.com`}
                    
                    //console.log(obj,"obj")
                    const res = await customerSignup(obj)
                    console.log(res)
                    if(res.status == 200){
                        //toast.success(res.message);
                        toast.success("The verification code has been sent to your email address");
                        setLoading(false)
                        history.push(`/customer-verify/${res.userToken}`)
                    }
                    // else if(res.status === 400){ 
                    //     // if user unverified then redirect 
                    //     const optVerification = await  getTokenForOTPVerification(obj)
                    //     toast.success("The verification code has been sent to your email address");
                    //     setLoading(false)
                    //     console.log('optVerification...', optVerification)
                    //     history.push(`/customer-verify/${optVerification.userToken}`)
                    // }
                    else{
                        setLoading(false)
                        toast.error(res.message?res.message:res.msg);
                    }

                    
                }}                
            >
                {({ errors, status, touched }) => (

                    <Form>
                        <div className="main-tickting-frontend frontends tickting-login-screen">
                            <div className="container">
                                <div className="login-centerd">
                                    <div className="login-frontend">
                                        <div className="lock-secure text-right pb-3">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                            <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                            </svg>
                                            <span>Secure Area</span></p>
                                        </div>
                                        <div className="login-box">
                                            
                                            <div className="headings-login-signup">
                                                <h4>Let’s Get You Signed Up</h4>
                                            </div>
                                            <div className="feilds-new">

                                                <div className="user-feilds">
                                                    <div className="md-form md-form-custom">
                                                        {/* <Field name="email" id="input-char-counter1" onChange={(e) => setEmail(e.target.value)} type="text" value={email} className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} /> */}
                                                        <Field name="email" id="input-char-counter1"  type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                        {/* {(!email.length) ? <ErrorMessage name="email"  component="div" className="invalid-feedback" />  : null} */}
                                                        <ErrorMessage name="email"  component="div" className="invalid-feedback" />
                                                        <label className={(email.length) ? 'active' : ''} htmlFor="input-char-counter1">Email ID*</label>
                                                        {/* <label  htmlFor="input-char-counter1">Email ID</label> */}
                                                    </div>
                                                </div>

                                                <div className="user-feilds">
                                                    <div className="md-form md-form-custom">
                                                        <Field name="password" id="input-char-counter11" type="password"  className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                        <label htmlFor="input-char-counter11">Password*</label>
                                                    </div>
                                                </div>

                                                <div className="user-feilds">
                                                    <div className="md-form md-form-custom">
                                                        <Field name="confirmPassword" id="input-char-counter12" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                        <label htmlFor="input-char-counter12">Confirm Password*</label>
                                                    </div>
                                                </div>
                                                <div className="form-check dont-have-an-account pl-0 mb-2">
                                                    <label className="containerCheckBox mb-0">
                                                        <span>By signing up you agree to our </span><a href="https://www.appypie.com/terms-of-use" target="_blank">Terms &amp; Conditions</a>, <a href="https://www.appypie.com/privacy-policy" target="_blank">Privacy Policy</a>
                                                        <Field type="checkbox" id="materialUnchecked" name="acceptTerms" className={'form-control' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                                                        {/* <label className="form-check-label" htmlFor="materialUnchecked">By signing in you agree to our Terms &amp; Conditions, Privacy Policy</label> */}
                                                        <span className="checkmark mt-1" />
                                                    </label>
                                                    {/* <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" /> */}
                                                    {(errors.acceptTerms && touched.acceptTerms)?
                                                    // <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                                                    <div className="invalid-feedback-checkbox"  >Please accept Terms & Conditions</div>
                                                    :null
                                                    }
                                                   
                                                </div>                                                        
                                                <div className="form-check dont-have-an-account pl-0">
                                                    <label className="containerCheckBox mb-0">
                                                        <span>Receive marketing newsletters from {baseData.settings.usersettings.portalname} </span>
                                                        <Field type="checkbox" id="materialUnchecked1" name="marketingCheck" className={'form-check-input' + (errors.marketingTerms && touched.marketingTerms ? ' is-invalid' : '')} />
                                                        {/* <label className="form-check-label" htmlFor="materialUnchecked">By signing in you agree to our Terms &amp; Conditions, Privacy Policy</label> */}
                                                        <span className="checkmark mt-1" />
                                                    </label>                                            
                                                 </div>
                                                <div className="user-feilds mb-2">
                                                    <div className="login-signup-btn k-flex align-items-center">
                                                        <button disabled={loading} type="submit">Sign Up</button>
                                                    </div>
                                                </div>
                                                
                                                <p className="paralogin-signup mt-2 text-center">Already have an account? <Link to="/login">Login</Link></p>

                                                {(baseData.signupError.length) ?
                                                    <p className="errorText">{baseData.signupError}</p>
                                                    : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </BaseLayout>
    )
}
export default Index;