import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { fetchSettingsRequest, setAuthenticationStatus, setAuthUser, setBrowserHistory } from "./actions"
import Header from "../../components/Shared/Header"
import Footer from "../../components/Shared/Footer"
import HeaderContent from "../../components/Shared/HeaderContent"
// import Organization from "../../components/Shared/Organization"
import Loader from "../../components/Shared/Loader"
import Domain from "../../components/NoData/Domain"
import { Helmet } from "react-helmet";
// import jwt_decode from "jwt-decode";
import { useLocation } from 'react-router-dom';
import SeoTags from "../../components/Shared/SeoTags"
import SuccessToast from "../../components/Shared/SuccessToast"
import jwt_decode from "jwt-decode";
import { db } from "../../utils/db"
import {setCookieCustomDomain,getCookie} from '../../utils/services'

import md5 from 'md5';

const Index = (props) => {
    const dispatch = useDispatch()
    const baseData = useSelector(state => state.baseReducer)
    const pathname = useLocation().pathname
    let loader = true;
    const [loading, setLoading] = useState(false)
    const [invalidUrl, setInvalidUrl] = useState(false)
    
    const checkSetting =async () =>{
        let domainname = window.location.hostname.split('.')[0]

        if(!window.location.href.includes('appypie.com') && !window.location.href.includes('pbodev.info')){
             let custom_domain=window.location.href
            // let custom_domain="https://helpdesk.onsinteractive.com/"
            //let custom_domain="https://helpdesk.profitbysearch.com/"
            if(custom_domain.split("https://")[1]){
                custom_domain=custom_domain.split("https://")[1]
            }

            if(custom_domain.split("/")[0]){
                custom_domain=custom_domain.split("/")[0]
            }
          
            let customCookie ={
                'APPYID':md5(custom_domain+new Date().toISOString())
            }
            let connectsid = getCookie('APPYID')
            
            if(!connectsid || connectsid === ""){
                setCookieCustomDomain(customCookie,'',custom_domain)
            }

            if(process.env.REACT_APP_WORK_MODE === 'DEVELOPMENT'){
                /**
                 * Without  custom domain on local Test un-comment following 
                 */
                const domainname = 'paywalltest' // "depard" //TODO: Change for test account on local 
                return new Promise((resolve, reject) => {
                    dispatch(fetchSettingsRequest({ domainname, resolve, reject }))
                });

                /**
                 * for custom domain on local Test un-comment following 
                 */
                // const domain_data =await db.collection('usersettings').where("custome_domain", "==",custom_domain ).get()
                // if(domain_data.empty){
                //     console.log("Data is empty")
                //     return {status:400}
                // }
                // else{
                //     let userData = domain_data.docs[0].data()
                //     domainname = userData.companyidentifier
                //     return new Promise((resolve, reject) => {
                //         dispatch(fetchSettingsRequest({ domainname, resolve, reject }))
                //     });
                // }

            }else{
                const domain_data =await db.collection('usersettings').where("custome_domain", "==",custom_domain ).get()
                if(domain_data.empty){
                    console.log("Data is empty")
                    return {status:400}
                }
                else{
                    let userData = domain_data.docs[0].data()
                    domainname = userData.companyidentifier
                    return new Promise((resolve, reject) => {
                        dispatch(fetchSettingsRequest({ domainname, resolve, reject }))
                    });
                } 
            }  
        }
        else{
            console.log(domainname,"domainname")
            return new Promise((resolve, reject) => {
                dispatch(fetchSettingsRequest({ domainname, resolve, reject }))
              });
        }
        // setTimeout(()=>{
        //     setInvalidUrl(true)
        //     console.log(baseData.settings,"baseData.settings")
        // },5000)

    }

    useEffect(() => {
        //const domainname = window.location.hostname.split('.')[0]
        // const domainname = "nainochip" // 'peta' // no need to hardcode this variable 
        //console.log(baseData.settings.length,"baseData.settings")
        if(baseData.settings.length === 0) {
                checkSetting().then((res)=>{
                    if(res.status== '200'){
                        setInvalidUrl(false)
                    }
                    else{
                        setInvalidUrl(true)
                    }
                })
            }
            
        if (localStorage.getItem('user') !== null) {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user?.length>0) {
              //  const decode = jwt_decode(user.token)
                dispatch(setAuthenticationStatus(true))
                dispatch(setAuthUser(user))
            }else{
               // localStorage.removeItem('user')
                dispatch(setAuthenticationStatus(false))
                dispatch(setAuthUser({}))
            }
        }
        
        if (localStorage.getItem('customer') !== null) {
            //const user = JSON.parse(localStorage.getItem('customer'));
            const user = (localStorage.getItem('customer'));
            if (user) {
                const decode = jwt_decode(user)
                dispatch(setAuthenticationStatus(true))
                dispatch(setAuthUser(decode))
            }
            else{ 
               // localStorage.removeItem('user')
                dispatch(setAuthenticationStatus(false))
                dispatch(setAuthUser({}))
            }
        }
        //console.log(pathname,pathname === '/',pathname.split('/')[1],"pathname.split('/')[1]")
        dispatch(setBrowserHistory({ pathname: pathname, path: (pathname === '/') ? "/" : pathname.split('/')[1] }))
       
    }, [])

    return (
        <Fragment>
            {(Object.keys(baseData.settings).length > 0 && baseData.settings.usersettings.userId !== undefined) ?

                <div style={{ fontFamily: 'Roboto' }}>
                    <Helmet>
                        <title>{baseData.settings.usersettings.portalname}</title>
                    </Helmet>                    
                    <Header />
                    <HeaderContent />
                    {baseData.successToastVisibility ? <SuccessToast /> : null}
                    {props.children}
                    <SeoTags />
                    <Footer />
                </div>
               
                //:  ((baseData.settingsLoading)?
                :  ((baseData.settingsLoading &&  !invalidUrl)?
                   <Loader/>
                   :(!(baseData.settingsLoading) &&  invalidUrl)?
                   //null
                   <Domain/>
                    :null
                   )
            }
        </Fragment  >
    );
};

export default Index;