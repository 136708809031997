import React,{useEffect} from "react";
import { Link } from "react-router-dom"
import BaseLayout from "../BaseLayout"
import {useDispatch} from "react-redux"
import {resetErrors} from "../BaseLayout/actions"

const Index = (props) => {
    const dispatch = useDispatch()
    // const email=atob(props.match.params.id);
    const login_type=props.match.params.type;
    useEffect(() => {
        dispatch(resetErrors())
    },[])

    return (
        <BaseLayout>
        <div className="mainFullWidth loginSignup_footer">
            <div className="container">
                <div className="login-centerd">
                    <div className="login-frontend">


                        <div className="login-box">
                            <div className="headings-login-signup">
                                <div className="user-feilds mb-5 mb-md-3 mb-xs-cus-2 mt-xs-cus-0">
                                    <div className="congrates-hurry-img text-center">
                                        <img alt="" src='/images/congrates.svg' />
                                    </div>
                                </div>
                                <div className="user-feilds congrates-para-box">
                                    <h4 className="card-title text-center mb-2">Hurray!!!</h4>
                                    <p className="card-body-text text-center">The password for your account has been successfully reset. Please sign in using the button below to access it.</p>
                                </div>
                                {(login_type==="customer")?
                                <div className="user-feilds py-5 mt-4 py-md-2 mt-md-3 pt-xs-cus-1 pb-xs-cus-1 text-center">
                                    <div className="login-signup-btn k-flex align-items-center justify-content-center">
                                        <Link to="/login" className="login-signup-btn"><button type="submit">Sign In</button></Link>
                                    </div>
                                </div>:
                                <div className="user-feilds py-5 mt-4 py-md-2 mt-md-3 pt-xs-cus-1 pb-xs-cus-1 text-center">
                                    <div className="login-signup-btn k-flex align-items-center justify-content-center">
                                        <Link to="/agent-login" className="login-signup-btn"><button type="submit">Sign In</button></Link>
                                    </div>
                                </div>}
                            </div>
                        </div>




                        {/* <div className="loginFooterReview registrationImgFilter NewFooterFrnt">
                            <div className="review-section">
                                <div className="review-companies">
                                    <div className="reviewcompanieswrapper">
                                        <div className="reviewcompaniesimg"><a href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47" target="_blank" rel="nofollow noopener"><img src='/images/login1.png' /></a></div>
                                    </div>
                                    <div className="reviewcompanieswrapper">
                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login2.png' /></a></div>
                                    </div>
                                    <div className="reviewcompanieswrapper">
                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login3.png' /></a></div>
                                    </div>
                                    <div className="reviewcompanieswrapper">
                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login4.png' /></a></div>
                                    </div>
                                    <div className="reviewcompanieswrapper">
                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login5.png' /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div> </div>
        </div>
        </BaseLayout>
    )
}


export default Index;
