import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from "react"
import BaseLayout from "../BaseLayout"
import { useSelector, useDispatch, createDispatchHook } from "react-redux"
// import { showUpdatePasswordPopUp, showSuccessToast } from "../BaseLayout/actions"
import queryString from 'query-string'
import { checkuser, agentlogin, agentowndblogin, logintoken, logintokenRealM, login, signoutagenttoken, addDeskOwner, getCompanyDetails, reactivateUserSetting, activityLog, deleteCookie, logout, setCookies, adminLogin } from "../../utils/services"
import Updatepassword from "./updatepassword"
import Loader from "../../components/Shared/Loader"
import { toast } from 'react-toastify';
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [useremail, setuseremail] = useState(true)
    const [adminEmail, setAdminEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [show, setShow] = useState(false);
    const [modalLoading, setModalLoading] = useState(false)
    const [loginWithOTP, setLoginWithOTP] = useState(false)
    const handleClose = () => { setShow(false); setModalLoading(false) };
    const [showAgent, setAgentShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleAgentClose = () => setAgentShow(false);
    const handleAgentShow = () => setAgentShow(true);
    const dispatch = useDispatch()
    const history = useHistory();

    const baseData = useSelector(state => state.baseReducer)
    const params = queryString.parse(props.location.search)
    const settings = baseData.settings.usersettings;


    // if (localStorage.getItem('user') || localStorage.getItem('customer')) {
    //     //console.log(window.location.origin);
    //     window.location.replace(window.location.origin);
    // }

    let token = localStorage.getItem('token');
    if (token) {
        let token_data = jwt_decode(token)
        if (token_data.role_type == 'admin' || token_data.role_type == 'agent') {
            if (token_data.is_ticket_app) {
                window.location.href = '/ticket/myopentickets'
            } else {
                window.location.href = '/knowledge/dashboard'
            }
        }
    }


    let load = false
    useEffect(() => {
        if (settings?.companyidentifier) {
            async function fetchCheckUrlAPI(companyidentifier) {
                let res = await getCompanyDetails(companyidentifier)
                if (res.status == 200) {
                    if (res.userdata?.email) {
                        setAdminEmail(res.userdata?.email)
                    }
                }
            }
            fetchCheckUrlAPI(settings.companyidentifier)
        }
    }, [settings?.companyidentifier])

    const windowopen = async (e, type) => {
        setLoading(true)
        e.preventDefault();
        if (type == 'google') {
            let win = window.open(`${process.env.REACT_APP_GOOGLE_LOGIN}`, 'popup', 'width=600,height=600');

            var timer = setInterval(async () => {
                if (win.closed) {
                    clearInterval(timer);
                    await checkRealMSignIn();
                }

            }, 2000);
            return false;
        }
        else {
            let win = window.open(`${process.env.REACT_APP_FACEBOOK_LOGIN}`, 'popup', 'width=600,height=600');
            var timer = setInterval(async () => {
                if (win.closed) {

                    clearInterval(timer);
                    await checkRealMSignIn();
                }

            }, 2000);
            return false;
        }
    }


    const checkRealMSignIn = async () => {
        logintokenRealM("owner")
            .then((response) => {
                if (response.status === 200) {
                    if (response.userdata) {
                        if (!localStorage.getItem('user')) {
                            let userdetail = JSON.parse(response.userdata);
                            var user = login(userdetail.userdata.email, userdetail.userdata._id).then(async (userdetails) => {

                                if (userdetails.status === 200 || userdetails.status === "200") {

                                    try {
                                        activityLog("Login by Common Login")
                                    }
                                    catch (e) {
                                        console.log("Error in activity log owner. ", e)
                                    }
                                    let tickethomespage = userdetails.data[0]?.tickethomespage
                                    if (tickethomespage === undefined || tickethomespage === "") {
                                        tickethomespage = "myopentickets"
                                    }
                                    setModalLoading(false)
                                    if (baseData.settings.usersettings?.is_ticket_app) {
                                        window.location.replace(`/ticket/${tickethomespage}`)
                                    }
                                    else if (baseData.settings.usersettings?.is_kb_app) {
                                        window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                    }
                                    else {
                                        window.location.replace(`/ticket/${tickethomespage}`)
                                    }
                                    return
                                }
                                else {

                                    // console.log("error 202....")
                                    toast.error(userdetails.message)
                                    if (userdetails.isCompanyIdentifier) {
                                        setTimeout(() => {
                                            setLoading(false)
                                            window.location.replace(userdetails.redirectTo)
                                        }, 2000)
                                    }
                                    else {
                                        setTimeout(() => {
                                            setLoading(false)
                                            window.location.replace(`${process.env.REACT_APP_COMMON_AUTH_URL}ticket/orgname`)
                                        }, 2000)
                                    }
                                }
                            });
                        }
                    }
                    else {
                        setLoading(false)
                    }
                }
                else {
                    setLoading(false)
                }
            })
    }
    const checkAdminUserSigin = async () => {
        logintoken()
            .then(response => response.json())
            .then(
                user => {

                    if (user.userdata) {

                        if (!localStorage.getItem('user')) {
                            let userdetail = JSON.parse(user.userdata);
                            var user = login(userdetail.userdata.email, userdetail.userdata._id).then(async (userdetails) => {
                                if (userdetails.status == 200) {
                                    try {
                                        activityLog("Login by Common Login")
                                    }
                                    catch (e) {
                                        console.log("Error in activity log owner. ", e)
                                    }
                                    let tickethomespage = userdetails.data[0]?.tickethomespage
                                    //console.log(tickethomespage,"tickethomespage1") 
                                    if (tickethomespage === undefined || tickethomespage === "") {
                                        tickethomespage = "myopentickets"
                                    }

                                    setModalLoading(false)
                                    if (baseData.settings.usersettings?.is_ticket_app) {
                                        window.location.replace(`/ticket/${tickethomespage}`)
                                    }
                                    else if (baseData.settings.usersettings?.is_kb_app) {
                                        window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                    }
                                    else {
                                        window.location.replace(`/ticket/${tickethomespage}`)
                                    }
                                    return

                                }
                                else {
                                    setLoading(false)
                                    // console.log("error 202....")
                                    toast.error(userdetails.message)
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 2000)
                                }
                            });
                        }
                    } else {
                        setLoading(false)
                    }
                }
            );
    }

    let checkOwnerEmail = (arg) => {
        // let adminEmail = "ashishkumar@appypiellp.com"
        if (adminEmail != undefined) {
            if (adminEmail.length > 0 && document.getElementById("inputMDEx7").value == adminEmail) {
                console.log("mine", document.getElementById("inputMDEx7").value)
                setLoginWithOTP(true)
            } else if (adminEmail.length > 0 && document.getElementById("inputMDEx7").value !== adminEmail) {
                setLoginWithOTP(false)
            }
        }
    }


    const reactivateUserAccount = async (userId) => {
        setModalLoading(true)
        var userkey = btoa(adminEmail + '-' + userPassword);
        //var url = 'https://commonlogin.pbodev.info/authenticate?userkey=' + userkey;
        var url = `${process.env.REACT_APP_POPUP_URL}/authenticate?userkey=${userkey}`
        var win = window.open(url, 'login', 'width=600,height=600');

        var timer = setInterval(async () => {
            if (win.closed) {
                clearInterval(timer);
                const res = await reactivateUserSetting({ userId: userId })
                await checkRealMSignIn();
            }

        }, 4000);


    }
    const clearAll = () => {
        logout();
    }
    let otp_url = process.env.REACT_APP_COMMON_LOGIN_OTP
    console.log(otp_url,process.env.REACT_APP_COMMON_LOGIN_OTP,process.env.REACT_APP_POPUP_URL)
    return (
        <BaseLayout>

            {/* { loading && <Loader /> } */}
            {(loading || adminEmail.length == 0) ? <div className="shadow">
                <Loader />
            </div> : null}
            {adminEmail.length > 0 ?

                <Formik
                    initialValues={{
                        email: params.email_id ? atob(params.email_id) : '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().trim()
                            .email('Email is invalid')
                            .required('Email is required'),
                        password: Yup.string()
                            .required('Password is required')
                    })}
                    onSubmit={async fields => {
                        try {
                            setLoading(true) //FJQ7BSZqRf2YrKoDlbMJ

                            if (settings.ticketdeactivated || settings.kbdeactivated) {

                                if (fields.email.trim() === adminEmail) {
                                    setUserPassword(fields.password)
                                    //console.log(fields.password ,adminEmail,"fields.password")
                                    setShow(true)
                                }
                                else {
                                    setAgentShow(true)
                                    setLoading(false)
                                }
                            }
                            else {
                                if (fields.email.trim() === adminEmail) {

                                    let loginAdmin = await adminLogin(fields.email.trim(), fields.password)
                                    if (loginAdmin.status == 200) {
                                        await checkRealMSignIn();

                                    }
                                    else {
                                        if (loginAdmin.message) {
                                            setLoading(false)
                                            toast.error(loginAdmin.message)
                                        }

                                    }
                                }
                                else {
                                    /*
                                    var checkusers = await checkuser(settings.id, fields.email);
                                    This API is not in use , as we are identifying user type Admin/agent through getcompany api.
                                    */
                                    //var checkusers = await checkuser(settings.id, fields.email);
                                    setuseremail(fields.email.trim());

                                    var loginagent = await agentlogin(settings.companyidentifier, fields.email.trim(), fields.password, "agent");

                                    if (loginagent.status === 400 || loginagent.status_msg == 400) {
                                        toast.error(loginagent.message)
                                        setLoading(false)
                                    }
                                    else {

                                        var userdata = JSON.parse(loginagent.userdata);
                                        activityLog("Login by Common Login")
                                        var owndbagent = await agentowndblogin(userdata.idToken.payload.preferred_username, fields.email.trim());
                                        var owndata = userdata.idToken.payload;
                                        if (owndbagent.status == '200') {
                                            //console.log(owndbagent.data);
                                            if (owndbagent.data[0].status) {
                                                toast.success(loginagent.message)
                                                owndbagent.data[0].firstName = owndata['custom:first_name']
                                                owndbagent.data[0].lastName = owndata['custom:last_name']
                                                owndbagent.data[0].alias = owndata['custom:alias']
                                                owndbagent.data[0].email = owndata['email']
                                                owndbagent.data[0].photo = owndata['custom:pic']
                                                owndbagent.data[0].timezone = owndata['custom:timezone']
                                                owndbagent.data[0].sub = owndbagent.data[0].id
                                                owndbagent.data[0].id = owndbagent.data[0].parentId
                                                localStorage.setItem('user', JSON.stringify(owndbagent.data));
                                                localStorage.setItem('userId', owndbagent.data[0].parentId);
                                                localStorage.setItem('token', owndbagent.data[0].token);
                                                localStorage.setItem('articlecount', owndbagent.aticlecount);
                                                let tickethomespage = owndbagent.data[0]?.tickethomespage
                                                //console.log(tickethomespage,"tickethomespage1")
                                                if (tickethomespage === undefined || tickethomespage === "") {
                                                    tickethomespage = "myopentickets"
                                                }
                                                activityLog("Login by own database");
                                                var agentLoginCookies = {
                                                    "61be0d8c3c195df8308def9fac389355": 1
                                                }
                                                await setCookies(agentLoginCookies);
                                                if (baseData.settings.usersettings?.is_ticket_app) {
                                                    window.location.replace(`/ticket/${tickethomespage}`)
                                                }
                                                else if (baseData.settings.usersettings?.is_kb_app) {
                                                    window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                                }
                                                else {
                                                    window.location.replace(`/ticket/${tickethomespage}`)
                                                }
                                            }
                                            else {
                                                toast.error("Your account has been deactivated.")
                                                await signoutagenttoken().then(data => { //console.log(data)  
                                                })
                                                clearAll()
                                                setLoading(false)
                                            }
                                        }
                                        else if ((owndbagent.status === "400") || (owndbagent.status === 400)) {
                                            if (owndbagent.message === "Agent not added in this helpdesk") {
                                                toast.error("Agent not added in this helpdesk")
                                            }
                                            else {
                                                toast.error(owndbagent.message)
                                            }
                                            setLoading(false)
                                        }
                                        else {
                                            setLoading(false)
                                            toast.error(owndbagent.message)
                                        }

                                        return false;
                                    }
                                }
                            }
                        } catch (err) {
                            console.log('error', err)
                        }

                    }}
                >

                    {({ errors, status, touched }) => (

                        <Form>
                            <Modal className="disableModel" show={show} aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header className="py-0 border-bottom-0" onClick={handleClose} closeButton>

                                </Modal.Header>
                                <Modal.Body className="py-0 px-0">
                                    {modalLoading && <Loader />}
                                    <div className="mb-4">
                                        <h4 className="card-title mb-0 font-bold mb-3">Sign In</h4>
                                        <p className="card-body-text text-dark-gray">This account has been deactivated. Are you sure you want to reactivate it?</p>
                                    </div>
                                    <>
                                        <button className="info-btn form-btn py-2 px-4" type="button" onClick={() => reactivateUserAccount(settings.userId)}>Sign In</button>
                                        <span className="ml-2 mr-3 cursor-pointer text-dark-gray" onClick={() => window.location.replace('/agent-login')}>Cancel</span>
                                    </>
                                </Modal.Body>
                            </Modal>

                            <Modal className="disableModel" show={showAgent} aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header className="py-0 border-bottom-0" onClick={handleAgentClose} closeButton>

                                </Modal.Header>
                                <Modal.Body className="py-0 px-0">
                                    <div className="mb-4">
                                        <h4 className="card-title mb-0 font-bold mb-3">Deactivate</h4>
                                        <p className="card-body-text text-dark-gray">This portal has been deactivated. Please contact your admin.</p>
                                    </div>

                                </Modal.Body>
                            </Modal>

                            <div className="main-tickting-frontend frontends tickting-login-screen">
                                <div className="container">
                                    <div className="login-centerd">
                                        <div className="login-frontend">
                                            <div className="lock-secure text-right pb-3">
                                                <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                                    <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                                </svg>
                                                    <span>Secure Area</span></p>
                                            </div>
                                            <div className="login-box">
                                                <div className="headings-login-signup">
                                                    <h4>Agent Sign-In</h4>
                                                </div>
                                                <div className="feilds-new">
                                                    <div className="user-feilds">
                                                        <div className="md-form md-form-custom">
                                                            <Field name="email" id="inputMDEx7" readOnly={params.email_id ? true : false} type="text" onBlur={checkOwnerEmail} className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                            <label htmlFor="inputMDEx7" className={params.email_id ? "active" : ""}>Email Address* </label>
                                                        </div>
                                                    </div>
                                                    <div className="user-feilds">
                                                        <div className="md-form md-form-custom">
                                                            <Field name="password" id="inputMDEx71" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                            <label htmlFor="inputMDEx71">Password*</label>
                                                        </div>

                                                    </div>
                                                    {(baseData.loginError) ?
                                                        <p className="text-danger">{baseData.loginError}</p>
                                                        : null
                                                    }
                                                    <div className="user-feilds">
                                                        <div className="settings-feld form-feilds k-flex align-items-center ">
                                                            <p className="text-light-blue card-body-text text-right ml-auto"><span className="cursor-pointer "><Link className="text-light-blue" to="/forgot-password/agent">Forgot Password?</Link></span></p>
                                                        </div>

                                                    </div>

                                                    <div className="user-feilds">
                                                        <div className="login-signup-btn k-flex align-items-center">
                                                            <button disabled={loading} type="submit">Login</button>&nbsp;&nbsp;
                                                        { loginWithOTP &&   <a className='atagalgchala' target="_blank" href={otp_url} disabled={loading} type="button" >Login with OTP</a>}
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="are-you-agent text-center pt-3 mt-3 pt-md-3 pt-xs-cus-4 pt-xs-cus-0">
                                                    <p className="paralogin-signup" style={{ 'fontWeight': 'bold' }} ><span>Are you a customer? </span><a href="/kb/login">Login here</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik> :
                null}

            {(baseData.updatepasswordpopup) ? <Updatepassword useremail={useremail} /> : null}
        </BaseLayout>
    )
}

export default Index;