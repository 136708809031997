export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export const SIGNOUT_USER = 'SIGNOUT_USER';

export const IS_CONTENT_EXIST= 'IS_CONTENT_EXIST';

export const SHOW_CATEGORY_SIDEBAR= 'SHOW_CATEGORY_SIDEBAR';
export const HIDE_CATEGORY_SIDEBAR= 'HIDE_CATEGORY_SIDEBAR';

export const SHOW_FOLDER_SIDEBAR= 'SHOW_FOLDER_SIDEBAR';
export const HIDE_FOLDER_SIDEBAR= 'HIDE_FOLDER_SIDEBAR';

export const SET_AUTHENTICATION_STATUS='SET_AUTHENTICATION_STATUS';

export const SET_AUTH_USER='SET_AUTH_USER';

export const SET_CATEGORY_ID='SET_CATEGORY_ID';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const SET_LOAD_VERIFY_PAGE='SET_LOAD_VERIFY_PAGE'

export const SET_SIGNUP_SUCCESS_PAGE='SET_SIGNUP_SUCCESS_PAGE'

export const SET_VERIFY_PASSWORD_PAGE='SET_VERIFY_PASSWORD_PAGE'

export const SET_FORGOT_PASSWORD_SUCCESS_PAGE='SET_FORGOT_PASSWORD_SUCCESS_PAGE'

export const LOAD_LAST_PAGE='LOAD_LAST_PAGE'

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';

export const FORGOT_PASSWORD_SEND_CODE_REQUEST = 'FORGOT_PASSWORD_SEND_CODE_REQUEST';
export const FORGOT_PASSWORD_SEND_CODE_SUCCESS = 'FORGOT_PASSWORD_SEND_CODE_SUCCESS';
export const FORGOT_PASSWORD_SEND_CODE_FAILURE = 'FORGOT_PASSWORD_SEND_CODE_FAILURE';

export const FORGOT_PASSWORD_VERIFY_OTP_REQUEST = 'FORGOT_PASSWORD_VERIFY_OTP_REQUEST';
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = 'FORGOT_PASSWORD_VERIFY_OTP_SUCCESS';
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE = 'FORGOT_PASSWORD_VERIFY_OTP_FAILURE';

export const SET_BROWSER_HISTORY='SET_BROWSER_HISTORY'

export const SHOW_SUCCESS_TOAST='SHOW_SUCCESS_TOAST'
export const HIDE_SUCCESS_TOAST='HIDE_SUCCESS_TOAST'

export const RESET_ERRORS='RESET_ERRORS'

export const SHOW_UPDATE_PASSWORD_POPUP='SHOW_UPDATE_PASSWORD_POPUP';

export const HIDE_UPDATE_PASSWORD_POPUP='HIDE_UPDATE_PASSWORD_POPUP';

export const SET_PROFILE_PIC='SET_PROFILE_PIC';

export const SET_ACCOUNT_DEACTIVE='SET_ACCOUNT_DEACTIVE';