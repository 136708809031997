import qs from "querystring";
import md5 from "md5";
import jwt_decode from "jwt-decode";
import { resolve } from "path";
import { firebase } from "./db";
import { ObjectId } from "bson";
import * as Realm from "realm-web";
const jwt = require("jsonwebtoken");
const uniqid = require("uniqid");
const credentials = Realm.Credentials.serverApiKey(
  `${process.env.REACT_APP_REALM_API_KEY}`
);

export const getRealMApp = () => {
  const app = new Realm.App({ id: `${process.env.REACT_APP_REALM_API_ID}` });
  return app;
};
//var ObjectID = require("bson-objectid");

// const ActivitySchema = {
//     name: 'insertLog',
//     properties: {
//         product_name:'string',
//         action:'string',
//         user_id:'string',
//         company_name:'string',
//         //user_type:"owner",
//         user_type:'string',
//         partition_key:Date.now().toString(),
//         addedon:Date.now(),
//        "_id":ObjectID()
//     }
//   };

////console.log(`${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,">>>>>>>>>>>>>>>")
//console.log(firebase,"firebase>>>>>")
export const getAdminSession = (token = null) => {
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({ file_name: connectsid, login_type: "owner" }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/owneragent", headerOptions)
  return (
    fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/owneragent`,
      headerOptions
     )
      .then((response) => {
        return response;
      })
  );
};

export const getBaseURL = () => {
  var getUrl = window.location;
  // var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
  var baseUrl = getUrl.protocol + "//" + getUrl.host;
  return baseUrl;
};

export const getApiCall = (arg, name) => {
  var URL = "";
  let deskktoken = localStorage.getItem("token");
  if (Object.keys(arg).length) {
    const params = new URLSearchParams(arg);
    URL = `${process.env.REACT_APP_API_CONFIG}/${name}?${params.toString()}`;
    ////console.log(URL,"URL")
  } else {
    URL = `${process.env.REACT_APP_API_CONFIG}/${name}`;
  }

  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${deskktoken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

export const postApiCall = (arg, name) => {
  let deskktoken = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${name}`, {
      method: "POST",
      body: JSON.stringify(arg),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${deskktoken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

export const putApiCall = (arg, name) => {
  let deskktoken = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${name}`, {
      method: "PUT",
      body: JSON.stringify(arg),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${deskktoken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

export const deleteApiCall = (arg, name) => {
  let deskktoken = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${name}`, {
      method: "DELETE",
      body: JSON.stringify(arg),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${deskktoken}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

export const postApiCallwithHeaderAuth = (arg, token, name) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${name}`, {
      method: "POST",
      body: JSON.stringify(arg),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

// export const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return decodeURIComponent(parts.pop().split(';').shift());
// }

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  //return decodeURIComponent('s:9c45e502-4327-4ac4-bad4-69872a0641e6.Cogx3sV5bMLPLO7YYvA+WGXv91ugV2AdEWJCqHZadHI');
  //return decodeURIComponent('s%3A8f9105cd-069d-4ab3-810c-8997e44ba023.LPyb7z%2Fabh91FdeX3V3hRLiXKs3LRa5sGqkBt%2BfpzvY');
  if (parts.length === 2)
    return decodeURIComponent(parts.pop().split(";").shift());
  //return decodeURIComponent('s%3A7769cc31-4d02-4f0e-b3ab-7322ba9021ae.VC7QkNeaM%2FBtktXFqkEA3mB5ikCHHMIYXaESk0ONlog');
  else return decodeURIComponent("");
};

export const setCookies = (
  cookies = {},
  expiry = "",
  domain = process.env.REACT_APP_APP_ENV == "STAGING" ? "pbodev.info" : "appypie.com"
) => {
  console.log(cookies, expiry, domain, "cookies,expiry,domain");
  return new Promise((res, rej) => {
    if (Object.keys(cookies).length > 0) {
      console.log("cookies >>> ", cookies);
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      expiry = expiry !== "" ? expiry : expiryDate;
      for (var key in cookies) {
        console.log("----key---", key, cookies[key], expiry, domain);
        document.cookie =
          key +
          "=" +
          cookies[key] +
          ";expires=" +
          expiry +
          ";path=/;domain=" +
          domain;
      }
      res();
    } else {
      res();
    }
  });
};

export const setCookieCustomDomain = (
  cookies = {},
  expiry = "",
  domain = ""
) => {
  console.log(cookies, expiry, domain, "cookies,expiry,domain");
  return new Promise((res, rej) => {
    if (Object.keys(cookies).length > 0) {
      console.log("cookies >>> ", cookies);
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      expiry = expiry !== "" ? expiry : expiryDate;
      for (var key in cookies) {
        console.log("----key---", key, cookies[key], expiry, domain);
        document.cookie =
          key +
          "=" +
          cookies[key] +
          ";expires=" +
          expiry +
          ";path=/;domain=" +
          domain;
      }
      res();
    } else {
      res();
    }
  });
};

export const gettoken = () => {
  ////console.log('gettoken called')
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)

  return fetch(
    `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
    headerOptions
  )
    .then((response) => response.json())
    .then((result) => {
      localStorage.setItem("deskktoken", result.accessToken);
      return;
    });
};

export const signouttoken = () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  if (localStorage.getItem("deskktoken")) {
    return removeusersession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("deskktoken", result.accessToken);
        return removeusersession(result.accessToken);
      });
  }
};

export const signoutagenttoken = () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  if (localStorage.getItem("deskktoken")) {
    return removesagentession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("deskktoken", result.accessToken);
        return removesagentession(result.accessToken);
      });
  }
};

// export const removesession = (token = null) => {
//     var connectsid = getCookie('APPYID');
//     const headerOptions = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//             'Authorization': 'Bearer ' + token
//         },
//         body: qs.stringify({ file_name: connectsid })
//     };
//     //return fetch("https://commonlogin.pbodev.info/backend/userses/setsession", headerOptions)
//     return fetch(`${process.env.REACT_APP_COMMON_LOGIN}/userses/setsession`, headerOptions)
//         .then(response => {
//             ////console.log(response)
//             return response;
//         })
//     //});

// }

export const removeusersession = (token = null) => {
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({
      file_name: connectsid,
      login_type: "customer",
      login_status: "no",
    }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/userses/setsession", headerOptions)
  return fetch(
    `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/useroutses`,
    headerOptions
  ).then((response) => {
    ////console.log(response)
    return response;
  });
  //});
};

export const removesagentession = (token = null) => {
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({
      file_name: connectsid,
      login_type: "agent",
      login_status: "no",
    }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/useroutses", headerOptions)
  return fetch(
    `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/useroutses`,
    headerOptions
  ).then((response) => {
    ////console.log(response)
    return response;
  });
  //});
};

export const signoutadmintoken = () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  if (localStorage.getItem("deskktoken")) {
    return removesadminession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    ).then((result) => {
      localStorage.setItem("deskktoken", result.accessToken);
      return removesadminession(result.accessToken);
    });
  }
};

export const removesadminession = (token = null) => {
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({
      file_name: connectsid,
      login_type: "owner",
      login_status: "no",
    }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/useroutses", headerOptions)
  return fetch(
    `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/useroutses`,
    headerOptions
  ).then((response) => {
    ////console.log(response)
    return response;
  });
  //});
};

export const logintoken = async () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  //console.log(localStorage.getItem("deskktoken"),"localStorage.getItem(deskktoken)")
  if (localStorage.getItem("deskktoken")) {
    //console.log(!localStorage.getItem("onLogoutOwner"),"!localStorage.getItem('onLogoutOwner')......1")
    //   let getSessionResp =await getsession(localStorage.getItem("deskktoken"));
    //     let resp =await getSessionResp.json().then((res)=>{
    //         //console.log(res,"getSessionResp")
    //         return res
    //     })
    // //
    // console.log(resp,"resp",resp.userdata === "")
    // if(resp.userdata === ""){
    //     return getsession(localStorage.getItem("deskktoken"),"customer");
    //     //return getSessionResp;

    // }
    // else{
    //     return getsession(localStorage.getItem("deskktoken"));
    // }
    return getsession(localStorage.getItem("deskktoken"));

    //return getAdminSession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        ////console.log(result);
        //console.log(!localStorage.getItem("onLogoutOwner"),"!localStorage.getItem('onLogoutOwner')......2")
        //console.log(result.accessToken,"result.accessToken")
        localStorage.setItem("deskktoken", result.accessToken);

        // let getSessionResp =await getsession(localStorage.getItem("deskktoken"));
        // let resp =await getSessionResp.json().then((res)=>{
        //     //console.log(res,"getSessionResp")
        //     return res
        // })
        //
        // console.log(resp,"resp",resp.userdata)
        // if(resp.userdata === ""){
        //     return getsession(localStorage.getItem("deskktoken"),"customer");
        //     //return getSessionResp;

        // }
        // else{
        //     return getsession(localStorage.getItem("deskktoken"));
        // }
        return getsession(localStorage.getItem("deskktoken"));
        // return getsession(result.accessToken);

        //  return getAdminSession(result.accessToken);
      });
  }
};

export const logintokenRealM = async (login_type) => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  //console.log(localStorage.getItem("deskktoken"),"localStorage.getItem(deskktoken)")
  if (localStorage.getItem("deskktoken")) {
    return getRealMsession(localStorage.getItem("deskktoken"), login_type);
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        localStorage.setItem("deskktoken", result.accessToken);

        return getRealMsession(localStorage.getItem("deskktoken"), login_type);
      });
  }
};
export const loginagenttoken = () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  if (localStorage.getItem("deskktoken")) {
    return getagentsession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        localStorage.setItem("deskktoken", result.accessToken);
        return getagentsession(result.accessToken);
      });
  }
};

export const logincustomertoken = () => {
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    },
    body: qs.stringify({
      grant_type: "password",
      username: "helppedroetb",
      password: "helppassword",
    }),
  };
  if (localStorage.getItem("deskktoken")) {
    return getcustomersession(localStorage.getItem("deskktoken"));
  } else {
    //return fetch("https://commonlogin.pbodev.info/backend/oauth/token", headerOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/oauth/token`,
      headerOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        localStorage.setItem("deskktoken", result.accessToken);
        return getcustomersession(result.accessToken);
      });
  }
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("color");
  localStorage.removeItem("domainname");
  localStorage.removeItem("deskktoken");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("customer");
  localStorage.removeItem("userId");
  localStorage.removeItem("catId");
  localStorage.removeItem("folderId");
  localStorage.setItem("userdata", "");
  localStorage.setItem("login_status", "");
  deleteCookie("61be0d8c3c195df8308def9fac389355");
  deleteCookie("79fefaf2564aabb19d44fe324844c86b");
  deleteCookie("a1840bd4010cc8f4c31a45a679a985cd");

  // window.location.href=`${process.env.REACT_APP_API_HELPDESK}`;
};

/**
 *
 * @param {*}
 */

export const deleteCookie = (name) => {
  let cpath = ".appypie.com";
  let d = new Date();
  let expires = `expires=${d.toUTCString()}`;
  //document.cookie = name + "=" + '0' + "; " + expires + ";path=/;domain="+ '.appypie.com';
  //console.log(expires,"expires")
  //document.cookie = "APPYID" + "=" + '' + "; " + expires + ";path=/;domain="+ '.appypie.com';
  document.cookie =
    name + "=" + "" + "; " + expires + ";path=/;domain=" + ".appypie.com";
  //document.cookie = "APPYID" + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;' + ";path=/;domain="+ '';
  return document.cookie;
};

export const getsession = async (token = null) => {
  //console.log("---------1332-------");
  var connectsid = getCookie("APPYID");
  //console.log(connectsid,">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  var logintype = "both";

  if (localStorage.getItem("user")) {
    var decoded = jwt.decode(localStorage.getItem("token"));
    if (decoded.role_type == "admin") {
      logintype = "owner";
    } else {
      logintype = "agent";
    }
  }
  if (localStorage.getItem("customer")) {
    logintype = "customer";
  }
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({ file_name: connectsid, login_type: logintype }),
  };
  return (
    fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/owneragent`,
      headerOptions
    )
      //return fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/helpdesksession`, headerOptions)
      .then((response) => {
        //console.log(response,"response")
        return response;
      })
  );
};

export const getRealMsession = async (token = null, login_type) => {
  //console.log("---------1332-------");
  var connectsid = getCookie("APPYID"); 
  var logintype = "owner";
  if (login_type) {
    logintype = login_type;
  }

  if (localStorage.getItem("user")) {
    var decoded = jwt.decode(localStorage.getItem("token"));
    if (decoded.role_type == "admin") {
      logintype = "owner";
    } else {
      logintype = "agent";
    }
  }
  if (localStorage.getItem("customer")) {
    logintype = "customer";
  }

  let realApp = await getRealMApp();
  const user = await realApp.currentUser;
  if (user) {
    //console.log(app.users[0].functions,"app>>>>>>")
    //console.log(user,"app>>>>>>")
    //    var useragent = await app.logIn(credentials);
    //    console.log(useragent,"useragent>>>>>>")
    await realApp.currentUser.refreshCustomData();
    return new Promise(async (resolve, reject) => {
      //const result1 = await app.functions.insertLog(logData);
      const result1 = await user.functions.getHelpDeskSession(
        md5(connectsid),
        logintype
      );
      //console.log(result1,"result1")
      resolve(result1);
    });
  } else {
    //await app.currentUser.refreshCustomData();
    var useragent = await realApp.logIn(credentials);
    await realApp.currentUser.refreshCustomData();

    return new Promise(async (resolve, reject) => {
      //const result1 = await app.functions.insertLog(logData);
      const result1 = await useragent.functions.getHelpDeskSession(
        md5(connectsid),
        logintype
      );
      //console.log(result1,"result1")
      resolve(result1);
    });
  }

  //   console.log('get session start... ')
  //   const currentLoggedInUser = await realmAgent.functions.getHelpDeskSession(connectsid,logintype);
  //   console.log('get session data... ', currentLoggedInUser)
};

export const getagentsession = (token = null) => {
  //console.log("---------1335-------");
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({ file_name: connectsid, login_type: "agent" }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/owneragent", headerOptions)
  return (
    fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/owneragent`,
      headerOptions
    )
      //return fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/helpdesksession`, headerOptions)
      .then((response) => {
        //console.log(response)
        return response;
      })
  );
  // });
};

export const getcustomersession = (token = null) => {
  //console.log("---------1335-------");
  var connectsid = getCookie("APPYID");
  const headerOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    body: qs.stringify({ file_name: connectsid, login_type: "customer" }),
  };
  //return fetch("https://commonlogin.pbodev.info/backend/api/agentcustomer/owneragent", headerOptions)
  return (
    fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/owneragent`,
      headerOptions
    )
      //return fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/helpdesksession`, headerOptions)
      .then((response) => {
        //console.log(response)
        return response;
      })
  );
  // });
};

export const login = (username, password) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: username, password: password }),
    //   body: JSON.stringify({
    //     'email': username,
    //     "password": password
    //   })
  };

  return fetch(
    `${process.env.REACT_APP_API_BACKEND_LOGIN}/frontendlogin`,
    requestOptions
  )
    .then((response) => response.json())
    .then(async (user) => {
      if (user.status == 200) {
        var users = user.data;
        //console.log(users,"usersusers>>>>>>>")

        localStorage.setItem("token", users[0].token);
        var company = await getcompany();

        console.log(company, "company>>>>>>>>>>>", company.email);
        users[0].firstname = company.first_name;
        users[0].lastname =
          company.last_name && company.last_name != "NA"
            ? company.last_name
            : "";
        users[0].email = company.email;
        users[0].imageUrl =
          company.pic && company.pic != "notexist"
            ? company.imageurl + "/" + company.pic
            : "";
        const checkDomain = window.location.hostname.split(".")[0];
        console.log(checkDomain, "checkDomain", users[0].domainname);

        if (window.location.href.includes("appypie.com")) {
          if (checkDomain === users[0].domainname) {
            localStorage.setItem("user", JSON.stringify(users));
            localStorage.setItem("userId", users[0].id);
            localStorage.setItem("articlecount", user.aticlecount);
            activityLog("Login", "Admin", users[0].id, users[0].domainname);
            if (users[0].customtoken) {
              let customtoken = users[0].customtoken;
              await firebase
                .auth()
                .signInWithCustomToken(customtoken)
                .then(() => {
                  return true;
                })
                .catch((err) => {
                  console.log(err);
                  //hideAppyLoader()
                  return false;
                });
            }
            return user;
          } else {
            console.log(
              users[0].domainname,
              users[0].companyidentifier,
              "users[0].domainname"
            );

            if (users[0].companyidentifier) {
              console.log("check....");
              var redUrl = window.location.href.replace(
                checkDomain,
                users[0].companyidentifier
              );
              let tickethomespage = users[0]?.tickethomespage;
              //console.log(tickethomespage,"tickethomespage1")
              if (tickethomespage === undefined || tickethomespage === "") {
                tickethomespage = "myopentickets";
              }
              // // console.log(checkDomain,"checkDomain")
              // // console.log(domainname,"domainname")
              // // console.log(redUrl,"redUrl")
              // // console.log(localStorage.getItem('customer'),"customer")
              // localStorage.removeItem('customer')
              // //window.location = `${redUrl}${config.baseFolder}/`
              // //console.log(redUrl,"redUrl.....1")
              // window.location.href=redUrl
              return {
                status: 202,
                message: "User does not exist",
                isCompanyIdentifier: true,
                companyidentifier: users[0].companyidentifier,
                redirectTo: redUrl.replace(`/ticket/${tickethomespage}`),
              };
            } else {
              console.log("check....2");
              return {
                status: 202,
                message: "User does not exist",
              };
            }
          }
        } else {
          localStorage.setItem("user", JSON.stringify(users));
          localStorage.setItem("userId", users[0].id);
          localStorage.setItem("articlecount", user.aticlecount);
          activityLog("Login", "Admin", users[0].id, users[0].domainname);
          if (users[0].customtoken) {
            let customtoken = users[0].customtoken;
            await firebase
              .auth()
              .signInWithCustomToken(customtoken)
              .then(() => {
                return true;
              })
              .catch((err) => {
                console.log(err);
                //hideAppyLoader()
                return false;
              });
          }
          return user;
        }

        // return firebase.auth().signInWithCustomToken(customtoken)
        // .then(() => {
        //     return true;
        // }).catch(err => {
        //     console.log(err);
        //     //hideAppyLoader()
        //     return false
        // })
      } else {
        console.log(user, "....");
        console.log("check....3");
        return user;
      }
      // //console.log(JSON.stringify(user)); return user;
    });
};

export const loginOwner = (username, password) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: username, password: password }),
    //   body: JSON.stringify({
    //     'email': username,
    //     "password": password
    //   })
  };

  return fetch(
    `${process.env.REACT_APP_API_BACKEND_LOGIN}/frontendlogin`,
    requestOptions
  )
    .then((response) => response.json())
    .then(async (user) => {
      if (user.status == 200) {
        var users = user.data;
        //console.log(users,"usersusers>>>>>>>")

        localStorage.setItem("token", users[0].token);
        var company = await getcompany();
        users[0].firstname = company.first_name;
        users[0].lastname =
          company.last_name && company.last_name != "NA"
            ? company.last_name
            : "";
        users[0].email = company.email;
        users[0].imageUrl =
          company.pic && company.pic != "notexist"
            ? company.imageurl + "/" + company.pic
            : "";
        const checkDomain = window.location.hostname.split(".")[0];
        console.log(checkDomain, "checkDomain", users[0].domainname);

        if (checkDomain === users[0].domainname) {
          localStorage.setItem("user", JSON.stringify(users));
          localStorage.setItem("userId", users[0].id);
          localStorage.setItem("articlecount", user.aticlecount);
          activityLog("Login", "Admin", users[0].id, users[0].domainname);
          if (users[0].customtoken) {
            let customtoken = users[0].customtoken;
            await firebase
              .auth()
              .signInWithCustomToken(customtoken)
              .then(() => {
                return true;
              })
              .catch((err) => {
                console.log(err);
                //hideAppyLoader()
                return false;
              });
          }
          return user;
        } else {
          return {
            status: 202,
            message: "Invalid Domain",
            data: users,
          };
        }

        // return firebase.auth().signInWithCustomToken(customtoken)
        // .then(() => {
        //     return true;
        // }).catch(err => {
        //     console.log(err);
        //     //hideAppyLoader()
        //     return false
        // })
      } else {
        return user;
      }
      // //console.log(JSON.stringify(user)); return user;
    });
};
export const checkuser = (orgId, email) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ orgid: orgId, email: email }),
    //   body: JSON.stringify({
    //     'email': username,
    //     "password": password
    //   })
  };

  return fetch(
    `${process.env.REACT_APP_API_BACKEND_LOGIN}/checkuser`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      //console.log(result);
      return result;
    });
};

export const adminLogin = async (email, pwd) => {
  const fileName = await getCookie("APPYID");
  var body = [
    `email=${email}&password=${pwd}&file_name=${encodeURIComponent(fileName)}`,
  ];
  return fetch(
    `${process.env.REACT_APP_COMMON_LOGIN}/api/logses/productlogin`,
    {
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    }
  )
    .then((result) => result.json())
    .then(async (response) => {
      console.log(response);
      if (response.status == 200 && response.cookies_list) {
        await setCookies(response.cookies_list);
        return response;
      } else {
        return response;
      }
    });
};

export const agentlogin = async (orgname, email, password, login_type) => {
  var connectsid = getCookie("APPYID");
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token")
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        company_identifier: orgname,
        email: email,
        password: md5(password),
        login_type: login_type,
        file_name: connectsid,
      }),
      //   body: JSON.stringify({
      //     'email': username,
      //     "password": password
      //   })
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/login`, requestOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/login`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 401) {
          var tokens = gettoken();
          agentlogin(orgname, email, password);
        } else {
          //console.log(result);
          // if(localStorage.getItem('onLogoutOwner')){
          //     localStorage.removeItem('onLogoutOwner')
          // }

          return result;
        }
      });
  } else {
    setTimeout(() => {
      agentlogin(orgname, email, password, login_type);
    }, 1000);
  }
};

export const updatepassword = async (
  orgname,
  email,
  old_password,
  password
) => {
  var connectsid = getCookie("APPYID");
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        company_identifier: orgname,
        email: email,
        existing_password: md5(old_password),
        password: md5(password),
        login_type: "agent",
        file_name: connectsid,
      }),
      //   body: JSON.stringify({
      //      'email': username,
      //     "password": password
      //   })
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/newpassword`, requestOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/newpassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status == 401) {
          var tokens = await gettoken();
          updatepassword(orgname, email, old_password, password);
        } else {
          //console.log(result);
          return result;
        }
      });
  } else {
    setTimeout(() => {
      updatepassword(orgname, email, old_password, password);
    }, 1000);
  }
};

export const agentowndblogin = async (agentid, email = "") => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ agentId: agentid, email: email }),
    //   body: JSON.stringify({
    //     'email': username,
    //     "password": password
    //   })
  };
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_BACKEND_LOGIN}/agentLogin`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        //console.log(result,"agentLogin>>>>>>>>>")
        if (
          result.status === "200" &&
          result.data.length > 0 &&
          result.data[0].customtoken
        ) {
          let customtoken = result.data[0].customtoken;
          //console.log(customtoken,"customtoken...")
          await firebase
            .auth()
            .signInWithCustomToken(customtoken)
            .then(() => {
              //return true;
              //console.log(true,"true")
              return result;
            })
            .catch((err) => {
              console.log(err, "erorr>>>>>");
              //hideAppyLoader()
              return false;
            });
          //return result;
          resolve(result);
        } else {
          //return result;
          resolve(result);
        }
        //return result;
      });
  });
};

export const forgetpassword = async (orgname, email, login_type) => {
  //console.log(orgname,email,"email")
  //   orgname="rav";
  //   email="ap31459@gmail.com"
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token",)
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        company_identifier: orgname,
        email: email,
        login_type: login_type,
      }),
      //   body: JSON.stringify({
      //     'email': username,
      //     "password": password
      //   })
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/forgotpassword`, requestOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/forgotpassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result,"result")
        if (result.status == 401) {
          var tokens = gettoken();
          forgetpassword(orgname, email);
        } else {
          return result;
        }
      });
  } else {
    setTimeout(() => {
      forgetpassword(orgname, email, login_type);
    }, 1000);
  }
};

export const forgetOwnerPassword = async (orgname, email) => {
  //console.log(orgname,email,"email")
  //   orgname="rav";
  //   email="ap31459@gmail.com"
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token",)
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ email: email }),
      //   body: JSON.stringify({
      //     'email': username,
      //     "password": password
      //   })
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/forgotpassword`, requestOptions)
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/common/forgotpassword`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          //console.log(result,"result")
          if (result.status == 401) {
            var tokens = gettoken();
            forgetOwnerPassword(orgname, email);
          } else {
            resolve(result);
          }
        });
    });
  } else {
    setTimeout(() => {
      forgetOwnerPassword(orgname, email);
    }, 1000);
  }
};


export const confirmResetPassword = async (
  orgname,
  email,
  password,
  otp,
  login_type
) => {
  //console.log(orgname,email,"email")
  //   orgname="rav";
  //   email="ap31459@gmail.com"
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token",)
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        company_identifier: orgname,
        email: email,
        login_type: login_type,
        newpasword: md5(password),
        verfication_code: otp,
      }),
      //   body: JSON.stringify({
      //     'email': username,
      //     "password": password
      //   })
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/confirmresetpassword`, requestOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/confirmresetpassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result,"result")
        if (result.status == 401) {
          var tokens = gettoken();
          confirmResetPassword(orgname, email, password, otp);
        } else {
          return result;
        }
      });
  } else {
    setTimeout(() => {
      confirmResetPassword(orgname, email, password, otp, login_type);
    }, 1000);
  }
};

export const confirmResetPasswordAdmin = async (
  orgname,
  email,
  password,
  otp,
  login_type
) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        email: email,
        login_type: login_type,
        newpasword: password,
        verfication_code: otp,
      }),
    };

    //return fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/confirmresetpassword`, requestOptions)
    return fetch(
      `${process.env.REACT_APP_COMMON_LOGIN}/api/common/confirmresetpassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result,"result")
        if (result.status == 401) {
          var tokens = gettoken();
          confirmResetPassword(orgname, email, password, otp);
        } else {
          return result;
        }
      });
  } else {
    setTimeout(() => {
      confirmResetPasswordAdmin(orgname, email, password, otp, login_type);
    }, 1000);
  }
};

const isAuthenticated = (token) => {
  try {
    const { exp } = jwt.decode(token);
    if (exp < (new Date().getTime() + 1) / 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
};

const getJwtToken = async (userData) => {
  return new Promise((resolve, reject) => {
    return jwt.sign(
      { user: userData },
      `${process.env.JWT_SECRET_KEY}/`,
      { expiresIn: "2h" },
      (err, jwt_token) => {
        resolve(jwt_token);
      }
    );
  });
};

export const customerSignup = async (arg) => {
  // let user = JSON.parse(localStorage.getItem('user'));
  arg.company_name = arg.portalName;
  arg.company_identifier = arg.companyidentifier;
  arg.login_type = "customer";
  arg.uid = uniqid();
  let random = arg.uid;
  // const formData = new FormData();
  // formData.append('email', arg.email);
  // formData.append('code', md5(arg.password));
  // formData.append('company_identifier', arg.company_identifier);
  // formData.append('login_type', arg.login_type);
  // formData.append('company_name', arg.company_name);
  //     formData.append('alias', arg.email.split('@')[0]);
  //     //formData.append('timezone', arg.timezone);
  //     formData.append('first_name', arg.email.split('@')[0]);
  //     formData.append('last_name', "");
  //     formData.append('timezone', 'Asia/Kolkatta');
  //    //formData.append('profile_pic', arg.photo);
  //     formData.append('profile_pic', "notexist");
  //formData.append('product_name', "helpdesk");
  arg.firstname = arg.email.split("@")[0];
  arg.lastname = "";
  const userData = {
    email: arg.email,
    pass: md5(arg.password),
    code: btoa(arg.password),
    company_name: arg.company_name,
    company_identifier: arg.company_identifier,
    org_id: arg.org_id,
    uid: arg.uid,
  };
  let jwt_token = await getJwtToken(userData).then((res) => {
    return res;
  });

  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();

  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token")
  if (token) {
    const res = await isValidUserToken(arg.email);
    const user_token = JSON.parse(res).user_token;
    var connectsid = getCookie("APPYID");
    console.log(user_token, "res");
    return new Promise((resolve, reject) => {
      // fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/signup`, {
      //fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/signup`, {
      // https://commonlogin.pbodev.info/backend/api/agentcustomer/customersignup
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/customersignup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //'Authorization': 'Bearer ' + token
          },
          //body: formData,
          body: JSON.stringify({
            email: arg.email,
            code: md5(arg.password),
            login_type: arg.login_type,
            company_identifier: arg.company_identifier,
            company_name: arg.company_name,
            product_name: "helpdesk",
            user_token: user_token,
            file_name: connectsid,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          if (response.status == 401) {
            var tokens = gettoken();
            customerSignup(arg);
          } else if (response.status == 400) {
            resolve(response);
          } else {
            var result = JSON.parse(response.userdata);
            arg.Id = result.userSub;
            response.userToken = `${random}?q=${jwt_token}`;
            await postApiCall(arg, "signup");
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      customerSignup(arg);
    }, 1000);
  }
};

export const getTokenForOTPVerification = async (arg) => {
  arg.company_name = arg.portalName;
  arg.company_identifier = arg.companyidentifier;
  arg.login_type = "customer";
  // arg.uid = uniqid();
  let random = arg.uid;
  arg.firstname = arg.email.split("@")[0];
  arg.lastname = "";
  const userData = {
    email: arg.email,
    pass: md5(arg.password),
    code: btoa(arg.password),
    company_name: arg.company_name,
    company_identifier: arg.company_identifier,
    org_id: arg.org_id,
    uid: arg.uid,
  };

  let jwt_token = await getJwtToken(userData);
  let response = { userToken: `${random}?q=${jwt_token}` };
  return response;
};

export const setSignUpPassword = async (arg, location, login_type) => {
  //console.log(arg, location.split('?q=')[1],"arg, location",location)
  //console.log(isAuthenticated(location.split('?q=')[1]),"isAuthenticated")
  let agentData = jwt.decode(location.split("?q=")[1]);

  //if(isAuthenticated(location.split('?q=')[1])){
  //console.log(agentData,"agentdata")
  let email = agentData.user.email;
  let password = agentData.user.pass;
  arg.company_name = agentData.user.company_name;
  arg.portalName = agentData.user.company_name;
  arg.company_identifier = agentData.user.company_identifier;
  arg.org_id = agentData.user.org_id;
  arg.uid = agentData.user.uid;
  arg.email = email;
  arg.login_type = login_type;

  if (arg.uid) {
    //console.log(email, "agentData",password,arg.org_id);
    let isVerify = false;
    let isActive = true;
    if (login_type === "customer") {
      let userInfo = await postApiCall(arg, "checkUserVerify");

      isVerify = userInfo.data.verify;
    } else if (login_type === "agent") {
      let agentInfo = await postApiCall(arg, "checkUserVerify");
      isVerify = agentInfo?.data?.verify ? agentInfo.data.verify : false;
      if (agentInfo.data?.status) {
        isActive = agentInfo.data.status;
      } else {
        isActive = false;
      }
    }
    //console.log(isVerify,"isVerify")
    if (isVerify) {
      return {
        status: 200,
        exist: true,
        active: true,
        message: "User already verified, please login.",
      };
    }
    if (!isActive) {
      return {
        status: 200,
        exist: true,
        active: false,
        message: "Your account has been deactivated.",
      };
    }

    if (!isVerify && isActive) {
      if (!localStorage.getItem("deskktoken")) {
        var tokens = await gettoken();
      }
      const token = localStorage.getItem("deskktoken");
      let app = await getRealMApp();
      var useragent = await app.logIn(credentials);
      const limit = 0;
      let page = 1;

      //const allAgentss=[]
      //let agentToVerify=[]
      let getAllAgents = async (company_identifier, limit, page) => {
        let allAgentdata = await useragent.functions.getData(
          company_identifier,
          limit,
          page
        );

        allAgentdata.forEach((data) => {
          allAgentss.push(data);
        });

        if (allAgentdata.length >= limit) {
          page += 1;
          getAllAgents(company_identifier, limit, page);
        } else {
          //return allAgentss

          agentToVerify = allAgentss.filter((agent) => {
            if (agent.email === agentData.user.email) {
              return agent;
            }
          });
        }
      };
      //await getAllAgents(arg.company_identifier,limit,page)
      const allAgentss = await useragent.functions.getData(
        arg.company_identifier,
        limit,
        page
      );
      //console.log(token,"token")

      let agentToVerify = allAgentss.filter((agent) => {
        if (agent.email === agentData.user.email) {
          return agent;
        }
      });
      if (agentToVerify.length > 0) {
        let tempPwd = agentToVerify[0].temporary;
        if (token) {
          return new Promise((resolve, reject) => {
            fetch(
              `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/newpassword`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
                //body: JSON.stringify({ 'company_identifier': arg.company_identifier, "email": email,"login_type":login_type,"existing_password":password,"password":md5(arg.password)})
                body: JSON.stringify({
                  company_identifier: arg.company_identifier,
                  email: email,
                  login_type: login_type,
                  existing_password: tempPwd,
                  password: md5(arg.password),
                }),
              }
            )
              .then((res) => res.json())
              .then(async (response) => {
                try {
                  if (response.status == 401) {
                    var tokens = gettoken();
                    setSignUpPassword(arg, location, login_type);
                  } else {
                    if (response.status === 200) {
                      if (login_type == "customer") {
                        //console.log(arg,"customer Arg...")
                        resolve(postApiCall(arg, "verifyOtpAndCreateUser"));
                      } else {
                        resolve(postApiCall(arg, "updateAgentVerify"));
                        //resolve(response);
                      }
                    } else {
                      resolve(response);
                    }
                  }
                } catch (e) {
                  resolve(response);
                }
              });
          });
        } else {
          setTimeout(() => {
            setSignUpPassword(arg, location, login_type);
          }, 1000);
        }
      }
    }
  } else {
    return {
      status: 400,
      exist: false,
      message: "User cannot be verify.",
    };
  }

  //}
  // else{
  //     //console.log(agentData,"agentData")
  //     return({
  //         "message":"Verification link expired",
  //         "link_expired":true
  //     })
  // }
};

export const isUserAndUrlVerify = async (location, login_type) => {
  let agentData = jwt.decode(location.split("?q=")[1]);
  let email = agentData.user.email;
  let password = agentData.user.pass;
  let arg = {};
  arg.company_name = agentData.user.company_name;
  arg.portalName = agentData.user.company_name;
  arg.company_identifier = agentData.user.company_identifier;
  arg.org_id = agentData.user.org_id;
  arg.uid = agentData.user.uid;
  arg.email = email;
  arg.login_type = login_type;
  let isVerify = false;
  if (login_type === "agent") {
    let agentInfo = await postApiCall(arg, "checkUserVerify");
    isVerify = agentInfo?.data?.verify ? agentInfo.data.verify : false;
  }
  if (isVerify) {
    return {
      status: 200,
      exist: true,
      message: "User already verified, please login.",
    };
  } else {
    return {
      status: 200,
      exist: false,
    };
    // if(isAuthenticated(location.split('?q=')[1])){
    //     return({
    //         "status":200,
    //         "exist":false,
    //         "link_expired":false
    //     })
    // }
    // else{
    //     return({
    //         "status":200,
    //         "exist":false,
    //         "message":"Verification link expired",
    //         "link_expired":true
    //     })
    // }
  }
};

export const resendVerificationLink = async (arg, location, login_type) => {
  //console.log(arg, location.split('?q=')[1],"arg, location",location)

  let agentData = jwt.decode(location.split("?q=")[1]);
  let email = agentData.user.email;
  let password = agentData.user.pass;
  arg.company_name = agentData.user.company_name;
  arg.portalName = agentData.user.company_name;
  arg.company_identifier = agentData.user.company_identifier;
  arg.org_id = agentData.user.org_id;
  arg.uid = agentData.user.uid;
  arg.email = email;
  arg.login_type = login_type;
  if (!agentData.user.first_name) {
    agentData.user.first_name = agentData.user.email.split("@")[0];
  }
  if (!agentData.user.last_name) {
    agentData.user.last_name = "NA";
  }

  let isVerify = false;

  if (login_type === "agent") {
    let agentInfo = await postApiCall(arg, "checkUserVerify");
    isVerify = agentInfo.data.verify;
  }
  //console.log(isVerify,"isVerify")
  if (isVerify) {
    return {
      status: 200,
      exist: true,
      message: "User already verified, please login.",
    };
  }

  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  //console.log(token,"token")
  let jwt_token = await getJwtToken(agentData.user).then((res) => {
    return res;
  });

  let verify_link = `${location.split("?q")[0]}?q=${jwt_token}`;
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/resendverifyurl`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            company_identifier: arg.company_identifier,
            company_name: arg.company_name,
            email: email,
            login_type: login_type,
            first_name: agentData.user.first_name,
            last_name: agentData.user.last_name,
            verify_link: verify_link,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              resendVerificationLink(arg, location, login_type);
            } else {
              //resolve(postApiCall(arg, 'updateAgentVerify'))
              resolve(response);
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      resendVerificationLink(arg, location, login_type);
    }, 1000);
  }
};

export const getArticleCreator = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let response = await postApiCall(arg, "getArticleCreator");
    resolve(response);
  });
};

export const checkDeactivate = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let response = await postApiCall(arg, "checkUserDeactivate");
    resolve(response);
  });
};
export const reactivateAccount = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let response = await postApiCall(arg, "reactivateAccount");
    resolve(response);
  });
};

export const reactivateUserSetting = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let response = await postApiCall(arg, "reactivateUserSetting");
    resolve(response);
  });
};

// export const loginUser = (arg,login_type)=>{
//     if(!localStorage.getItem("deskktoken"))
//     var tokens = gettoken();
//     const token = localStorage.getItem('deskktoken');
//     //console.log(token,"token")
//     return new Promise((resolve, reject) => {
//         fetch(`https://commonlogin.pbodev.info/backend/api/agentcustomer/login`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': 'Bearer ' + token
//             },
//             body: JSON.stringify({ 'company_identifier': arg.company_identifier, "email": email,"login_type":login_type,"password":md5(arg.password),"file_name": filename})

//         }).then(res => res.json()).then(async response => {
//         try{
//             if(response.status == 401)
//             {
//                 var tokens = gettoken();
//                 loginUser(arg,login_type);
//             }
//             else{
//                 // if(login_type=="customer"){
//                 //     //console.log(arg,"customer Arg...")
//                 //     resolve(postApiCall(arg, 'verifyOtpAndCreateUser'))
//                 // }
//                 // else{
//                 //     resolve(response);
//                 // }
//                 resolve(response)

//             }
//         } catch (e) {
//             resolve(response);

//         }
//         })
//     })
// }

export const getAdminListing = async () => {
  if (!localStorage.getItem("deskktoken")) {
    var tokens = await gettoken();
  }

  let accessToken = localStorage.getItem("deskktoken");
  // const token = results.data.token
  let token = localStorage.getItem("token");
  //console.log('save token', token)
  //console.log('access token', accessToken)
  // Decode token to get user data
  const decoded = jwt_decode(token);
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/common/getcompany`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ company_identifier: decoded.companyidentifier }),
    })
      .then((res) => {
        return res.json();
      })
      .then((jsonData) => {
        //console.log(jsonData,"res.......get company>>>>>>")
        resolve(jsonData.userdata);
      });
  });
};

/**
 * get agent listing data
 * no need to use this function remove in future versions 
 * TODO: remove this
 */



export const getCompanyUserDetails = async (key, userData) => {
  //console.log(key,"userData,key")
  return new Promise((resolve, reject) => {
    return jwt.verify(userData, key, async (err, decoded) => {
      //console.log(jwt_token,"token")
      //let currentLink=window.location.href
      //console.log(err,decoded,"decoded")
      resolve(decoded);
    });
  });
};

export const getcompany = async () => {
  let usertoken = jwt.decode(localStorage.getItem("token"));
  let user = JSON.parse(localStorage.getItem("user"));

  if (!localStorage.getItem("deskktoken")) {
    await gettoken();
  }
  const token = localStorage.getItem("deskktoken");
  if (usertoken.role_type == "admin") {
    // var url = `${process.env.REACT_APP_COMMON_LOGIN}/api/common/getcompany`;
    // //var body ={'company_identifier': usertoken.domainname}
    // var body ={'company_identifier': usertoken.company_identifier}

    let companyidentifier = usertoken.company_identifier
      ? usertoken.company_identifier
      : usertoken.companyidentifier
      ? usertoken.companyidentifier
      : usertoken.domainname;
    ///RealM integrated for getcompany

    let realApp = await getRealMApp();
    let user = await realApp.currentUser;
    if (!user) {
      user = await realApp.logIn(credentials);
      await realApp.currentUser.refreshCustomData();
    }

    return new Promise(async (resolve, reject) => {
      //const result1 = await app.functions.insertLog(logData);
      const result1 = await user.functions.getCompanyData(companyidentifier);
      //console.log(result1,"result1")
      let userData = result1.userdata;
      let token_key = process.env.REACT_APP_TOKEN_KEY;
      //console.log(token_key,"token_key")
      let key = `${btoa(companyidentifier)}${token_key}`;
      let response = await getCompanyUserDetails(key, userData);
      console.log(response, "response", token_key, companyidentifier);
      if (response) {
        resolve(response);
      } else {
        //resolve({'error':'error'})
        resolve(result1);
      }
    });
  }
  //fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/common/getcompany`, {
  else {
    const res = await isValidUserToken(user[0].email);
    const user_token = JSON.parse(res).user_token;
    var connectsid = getCookie("APPYID");
    //var url = `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/checkuserexist`;
    var url = `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/checkagentexist`;
    //var body ={'email':user[0].email,'company_identifier':usertoken.domainname,'login_type':'agent'}
    var body = {
      email: user[0].email,
      company_identifier: usertoken.company_identifier,
      login_type: "agent",
      user_token: user_token,
      file_name: connectsid,
    };
    if (!localStorage.getItem("deskktoken")) var tokens = gettoken();

    //console.log(token,"token")
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        try {
          if (response.status == 401) {
            var tokens = gettoken();
            getcompany();
          } else {
            //       if(usertoken.role_type == 'admin'){
            //     return response.userdata;
            // }
            // else{
            let userAttributes = response.user.Attributes;
            let userDetails = {};
            userAttributes.forEach((attr) => {
              var attrName = attr.Name.replace("custom:", "");
              userDetails = { ...userDetails, [attrName]: attr.Value };
            });
            return userDetails;
            //}
          }
        } catch (e) {
          return response;
        }
      });
  }
};

export const getCompanyDetails = async (companyidentifier) => {
  //companyidentifier="walter"
  if (!localStorage.getItem("deskktoken")) {
    await gettoken();
  }
  const token = localStorage.getItem("deskktoken");

  if (token) {
    ///RealM integrated for getcompany

    let realApp = await getRealMApp();
    let user = await realApp.currentUser;
    if (!user) {
      user = await realApp.logIn(credentials);
      await realApp.currentUser.refreshCustomData();
    }

    return new Promise(async (resolve, reject) => {
      //const result1 = await app.functions.insertLog(logData);
      const result1 = await user.functions.getCompanyData(companyidentifier);
      //console.log(result1,"result1")
      if (result1.status == 200) {
        let userData = result1.userdata;
        //console.log(userData,"userData")
        if (userData) {
          let token_key = process.env.REACT_APP_TOKEN_KEY;
          //console.log(token_key,"token_key")
          let key = `${btoa(companyidentifier)}${token_key}`;
          //let key =   `${btoa(companyidentifier)}${"live!@#$commonJ)(&^$#*tok43$^&@#en"}` ;
          //console.log(key,"key")
          let response = await getCompanyUserDetails(key, userData);
          //console.log(response,"response")
          if (response) {
            resolve({ status: 200, userdata: response });
          } else {
            resolve(result1);
          }
        } else {
          resolve(result1);
        }
      } else {
        if (result1.error) {
          getCompanyDetails(companyidentifier);
        }
      }
    });

    // if(user){

    //     //console.log(app.users[0].functions,"app>>>>>>")
    //     //console.log(user,"app>>>>>>")
    // //    var useragent = await app.logIn(credentials);
    // //    console.log(useragent,"useragent>>>>>>")
    // await realApp.currentUser.refreshCustomData();
    // return new Promise(async(resolve,reject)=>{
    //     //const result1 = await app.functions.insertLog(logData);
    //     const result1 = await user.functions.getCompanyData(companyidentifier);
    //     //console.log(result1,"result1")
    //     if(result1.status == 200){
    //         let userData = result1.userdata
    //         //console.log(userData,"userData")
    //         if(userData){
    //             let token_key = process.env.REACT_APP_TOKEN_KEY
    //             console.log(token_key,"token_key")
    //             let key =   `${btoa(companyidentifier)}${token_key}` ;
    //             //let key =   `${btoa(companyidentifier)}${"live!@#$commonJ)(&^$#*tok43$^&@#en"}` ;
    //             //console.log(key,"key")
    //             let response= await getCompanyUserDetails(key,userData)
    //             //console.log(response,"response")
    //             if(response){
    //                 resolve({status:200,userdata:response})
    //             }
    //             else{
    //                 resolve(result1)
    //             }

    //         }
    //         else{
    //             resolve(result1)
    //         }

    //     }

    // })

    // }
    // else{

    // //await app.currentUser.refreshCustomData();
    // var useragent = await realApp.logIn(credentials);
    // await realApp.currentUser.refreshCustomData();

    // return new Promise(async(resolve,reject)=>{
    //     //const result1 = await app.functions.insertLog(logData);
    //     const result1 = await useragent.functions.getCompanyData(companyidentifier);
    //     let userData = result1.userData
    //     if(userData){
    //         let token_key = process.env.REACT_APP_TOKEN_KEY
    //         console.log(token_key,"token_key")
    //         let key =   `${btoa(companyidentifier)}${token_key}` ;
    //         //let key =   `${btoa(companyidentifier)}${"live!@#$commonJ)(&^$#*tok43$^&@#en"}` ;
    //         //console.log(key,"key")
    //         let response= await getCompanyUserDetails(key,userData)
    //         console.log(response,"response")
    //         if(response){
    //             resolve({status:200,userdata:response})
    //         }
    //         else{
    //             resolve(result1)
    //         }

    //     }
    //     else{
    //         resolve(result1)
    //     }
    // })

    // }

    // var url = `${process.env.REACT_APP_COMMON_LOGIN}/api/common/getcompany`;
    // var body ={'company_identifier':companyidentifier}
    // return fetch(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + token
    //     },
    //     body: JSON.stringify(body)

    // }).then(res => res.json()).then(response => {
    // try{
    //     if(response.status == 401)
    //     {
    //         var tokens = gettoken();
    //         //getcompany();
    //         getCompanyDetails(companyidentifier);
    //     }
    //     else{

    //         return response;

    //     }
    // } catch (e) {
    //   return response;

    // }
    // })
  }
};

export const confirmUserSignup = async (location, otp, login_type) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (location && token) {
    let agentData = jwt.decode(location.split("?q=")[1]);
    let email = agentData.user.email;
    let password = agentData.user.pass;
    let arg = {};
    arg.company_name = agentData.user.company_name;
    arg.portalName = agentData.user.company_name;
    arg.company_identifier = agentData.user.company_identifier;
    arg.org_id = agentData.user.org_id;
    arg.uid = agentData.user.uid;
    arg.email = email;
    arg.login_type = login_type;

    let isVerify = false;
    if (login_type === "customer") {
      let userData = await postApiCall(arg, "checkUserVerify");

      isVerify = userData.data.verify;
    }
    if (isVerify) {
      return {
        status: 200,
        message: "User already verified, please login.",
      };
    }

    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/confirmsignup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            email: email,
            company_identifier: arg.company_identifier,
            company_name: arg.company_name,
            login_type: login_type,
            confirmation_code: otp,
          })
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              confirmUserSignup(location, otp, login_type);
            }else if(response.status == 400){
               resolve(response);
            }
             else {
              if (login_type == "customer") {
                //console.log(arg,"customer Arg...")
                resolve(postApiCall(arg, "verifyOtpAndCreateUser"));
              }
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      confirmUserSignup(location, otp, login_type);
    }, 1000);
  }
};

export const resendOtpSignup = async (location, login_type) => {
  if (!localStorage.getItem("deskktoken")) 
  var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (location && token) {
    let agentData = jwt.decode(location.split("?q=")[1]);
    let email = agentData.user.email;
    let password = agentData.user.pass;
    let arg = {};
    arg.company_name = agentData.user.company_name;
    arg.portalName = agentData.user.company_name;
    arg.company_identifier = agentData.user.company_identifier;
    arg.org_id = agentData.user.org_id;
    arg.uid = agentData.user.uid;
    arg.email = email;
    arg.login_type = login_type;

    let isVerify = false;
    if (login_type === "customer") {
      let userData = await postApiCall(arg, "checkUserVerify");

      isVerify = userData.data.verify;
    }
    if (isVerify) {
      return {
        status: 200,
        message: "User already verified, please login.",
      };
    }
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/resendverification`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            email: email,
            company_identifier: arg.company_identifier,
            company_name: arg.company_name,
            login_type: login_type,
            product_name: "helpdesk",
          }),
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              resendOtpSignup(location, login_type);
            } else {
              resolve(response);
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      resendOtpSignup(location, login_type);
    }, 1000);
  }
}

/**
 * for customer section send otp to varify
 * @param {*} location 
 * @param {*} login_type 
 * @param {*} arg 
 * @returns 
 */
export const sendOptToUnVerifiedCustomer = async (location, login_type,arg) => {
     
     const token = localStorage.getItem("deskktoken");
     if (location && token) {
       
       return new Promise((resolve, reject) => {
         fetch(
           `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/resendverification`,
           {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               Authorization: "Bearer " + token,
             },
             body: JSON.stringify({
               email: arg.email,
               company_identifier: arg.companyidentifier,
               company_name: arg.portalName,
               login_type: login_type,
               product_name: "helpdesk",
             }),
           }
         )
           .then((res) => res.json())
           .then(async (response) => {
             try {
               resolve(response);

               if (response.status == 401) {
               //   var tokens = gettoken();
               //   console.log('dubara')
               //   sendOptToUnVerifiedCustomer(location, login_type,arg);
                    resolve(response);
               } else {
                    resolve(response);
               }
             } catch (e) {
                    resolve(response);
             }
           });
       });
     } else {
          //   setTimeout(() => {
          //      console.log('settimeOut')
          //      sendOptToUnVerifiedCustomer(location, login_type,arg)
          //   }, 1000);
     }
}





export const addDeskOwner = async (email, password) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_COMMON_LOGIN}/api/common/adddeskowner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ email: email, password: password }),
      })
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              addDeskOwner(email, password);
            } else {
              let userData = await postApiCall(
                { email: email },
                "updateCreatedOn"
              );
              resolve(response);
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      addDeskOwner(email, password);
    }, 1000);
  }
};

export const updateUserDetails = async (
  email,
  domainname,
  login_type,
  portalname,
  alias,
  timezone,
  firstname,
  lastname,
  profilePic,
  pic,
  thumbnail,
  description,
  country,
  phone
) => {
  // arg.user_id = user_id;

  // return new Promise(async (resolve, reject) => {
  //     let updateUser= await postApiCall(arg,'updateUserDetails')
  //     //console.log(updateUser,"updateUser")
  //     resolve(updateUser)
  // })

  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("company_identifier", domainname);
    formData.append("login_type", login_type);
    formData.append("company_name", portalname);
    formData.append("alias", alias ? alias : "NA");
    formData.append("timezone", timezone);
    formData.append("first_name", firstname);
    formData.append("last_name", lastname ? lastname : "NA");
    formData.append("profile_pic", profilePic);
    formData.append("pic", pic);
    formData.append("thumbnail", thumbnail);
    formData.append("country", country);
    formData.append("description", description);
    formData.append("phone_number", phone);
    return new Promise(async (resolve, reject) => {
      try {
        let res = await isValidUserToken(email);
        const user_token = JSON.parse(res).user_token;
        var connectsid = getCookie("APPYID");
        formData.append("user_token", user_token);
        formData.append("file_name", connectsid);
        fetch(
          `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/customerupdateuser`,
          {
            method: "POST",
            body: formData,
            // headers: {
            //     'Authorization': 'Bearer ' + token
            // }
          }
        )
          .then((res) => res.json())
          .then((response) => {
            if (response.status == 401) {
              var tokens = gettoken();
              updateUserDetails(
                email,
                domainname,
                login_type,
                portalname,
                alias,
                timezone,
                firstname,
                lastname,
                profilePic,
                pic,
                thumbnail,
                description,
                country,
                phone
              );
            } else if (response.status == 400) {
              resolve(response);
            } else {
              // var result = JSON.parse(response.userdata);
              //  arg.Id = result.User.Attributes[1].Value;
              resolve(response);
            }
          });
      } catch (e) {
        //console.log(e,"error in fetching api")
      }
    });
  } else {
    setTimeout(() => {
      updateUserDetails(
        domainname,
        login_type,
        portalname,
        alias,
        timezone,
        firstname,
        lastname,
        profilePic,
        pic,
        thumbnail,
        description,
        country,
        phone
      );
    }, 1000);
  }
};

export const fetchUserInfo = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let updateUser = await postApiCall(arg, "fetchUserInfo");
    //console.log(updateUser,"updateUser")
    resolve(updateUser);
  });
};
/**
 * Working
 * @param {*} arg
 * @returns
 */
export const fetchCustomerInfo = async (arg) => {
  return new Promise(async (resolve, reject) => {
    let updateUser = await postApiCall(arg, "fetchCustomerUIDInfoWithEmail");
    //console.log(updateUser,"updateUser")
    resolve(updateUser);
  });
};

export const changeUserPassword = async (
  company_identifier,
  login_type,
  email,
  currentPassword,
  password
) => {
  let checkPwd = await checkUserPassword(
    password,
    email,
    company_identifier,
    login_type
  );
  if (checkPwd.status == 200) {
    return {
      status: 400,
      Message: "Password cannot be same as old password",
    };
  } else {
    if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
    const token = localStorage.getItem("deskktoken");
    if (token) {
      return new Promise(async (resolve, reject) => {
        const res = await isValidUserToken(email);
        const user_token = JSON.parse(res).user_token;
        var connectsid = getCookie("APPYID");
        //console.log(login_type,"login_type")
        fetch(
          `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/changepassword`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
              company_identifier: company_identifier,
              login_type: login_type,
              email: email,
              old_password: md5(currentPassword),
              new_password: md5(password),
              user_token: user_token,
              file_name: connectsid,
            }),
          }
        )
          .then((res) => res.json())
          .then(async (response) => {
            try {
              if (response.status == 401) {
                var tokens = gettoken();
                changeUserPassword(
                  company_identifier,
                  login_type,
                  email,
                  currentPassword,
                  password
                );
              } else {
                resolve(response);
              }
            } catch (e) {
              resolve(response);
            }
          });
      });
    } else {
      setTimeout(() => {
        changeUserPassword(
          company_identifier,
          login_type,
          email,
          currentPassword,
          password
        );
      }, 1000);
    }
  }
};

export const fetchUserDetails = async (
  email,
  company_identifier,
  login_type
) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    try {
      const res = await isValidUserToken(email);
      const user_token = JSON.parse(res).user_token;
      var connectsid = getCookie("APPYID");
      //console.log(login_type,"login_type")
      var body = {
        email: email,
        company_identifier: company_identifier,
        login_type: login_type,
        file_name: connectsid,
        user_token: user_token,
        login_email: email,
        login_email_type: login_type,
      };
      return new Promise((resolve, reject) => {
        fetch(
          `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/checkuserexist`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(body),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((response) => {
            if (response.user) {
              const parseData = response.user;
              ////console.log(parseData.Attributes,"res>>>>>>>>>>>>>")
              const Attributes = parseData.Attributes;
              var allAgentss = [];
              // var agentList = JSON.parse(response.data).Users;
              var newattr = {};
              //newattr[]
              Attributes.forEach((attr) => {
                var attrName = attr.Name.replace("custom:", "");
                //newattr = { ...newattr, [attrName]: attr.Value }
                newattr[attrName] = attr.Value;
                //allAgentss.push(newattr);
              });
              ////console.log(newattr,"allAgentss>>>>>>>>>>>>>>>>>!!!!!!")
              resolve(newattr);
            }
          });
        // }).then((res) =>{
        //     //console.log(res.json(),"res.json()<<<<<<")
        //     //return res.json()} ).then(response => {
        //     // const parseData = JSON.parse(response.data).Users

        //      var allAgentss = [];
        //     // // var agentList = JSON.parse(response.data).Users;
        //     // parseData.forEach((agentDetails) => {
        //     //     var agentAttributes = agentDetails.Attributes;
        //     //     var newattr = {};
        //     //     agentAttributes.forEach((attr) => {
        //     //         var attrName = attr.Name.replace('custom:', '');
        //     //         newattr = { ...newattr, [attrName]: attr.Value }
        //     //     })
        //     //     allAgentss.push(newattr);
        //     // })
        //     //console.log(allAgentss,"allAgentss>>>>>>>>>>>>>>>>>!!!!!!")
        //     resolve(allAgentss)
      });
    } catch (e) {
      //console.log(e,"error in fetching API")
    }
  } else {
    setTimeout(() => {
      fetchUserDetails(email, company_identifier, login_type);
    }, 1000);
  }
};

export const updateUserDetailsOwnDb = async (
  arg,
  org_id,
  user_id,
  imageurl,
  pic,
  thumbnail,
  is_kb_app,
  is_ticket_app,
  domainname,
  portalname,
  login_type,
  firstname,
  lastname
) => {
  arg["user_id"] = user_id;
  arg["org_id"] = org_id;
  arg["imageurl"] = imageurl;
  arg["pic"] = pic;
  arg["thumbnail"] = thumbnail;
  arg["is_kb_app"] = is_kb_app;
  arg["is_ticket_app"] = is_ticket_app;
  arg["domainname"] = domainname;
  arg["portalname"] = portalname;
  arg["login_type"] = login_type;
  arg["firstname"] = firstname;
  arg["lastname"] = lastname;

  return new Promise(async (resolve, reject) => {
    let updateUser = await postApiCall(arg, "updateUserDetails");
    //console.log(updateUser,"updateUser")
    resolve(updateUser);
  });
};

export const deactivateUserAccount = async (arg) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    return new Promise(async (resolve, reject) => {
      let updateUser = await postApiCall(arg, "deactivateUserAccount");
      //console.log(updateUser,"updateUser")
      resolve(updateUser);
    });
  } else {
    deactivateUserAccount(arg);
  }
};

export const checkUserPassword = async (
  checkpassword,
  email,
  company_identifier,
  login_type
) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/checkpassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            company_identifier: company_identifier,
            login_type: login_type,
            email: email,
            password: md5(checkpassword),
          }),
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              checkUserPassword(
                checkpassword,
                email,
                company_identifier,
                login_type
              );
            } else {
              resolve(response);
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      checkUserPassword(checkpassword, email, company_identifier, login_type);
    }, 1000);
  }
};

export const deleteEndUser = async (
  email,
  company_identifier,
  login_type,
  user_id,
  org_id
) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/deleteuser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            company_identifier: company_identifier,
            login_type: login_type,
            email: email,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (response) => {
          try {
            if (response.status == 401) {
              var tokens = gettoken();
              deleteEndUser(
                email,
                company_identifier,
                login_type,
                user_id,
                org_id
              );
            } else {
              let arg = {};
              arg["user_id"] = user_id;
              arg["org_id"] = org_id;
              arg["email"] = email;
              resolve(postApiCall(arg, "deleteUserAccount"));
            }
          } catch (e) {
            resolve(response);
          }
        });
    });
  } else {
    setTimeout(() => {
      deleteEndUser(email, company_identifier, login_type, user_id, org_id);
    }, 1000);
  }
};

export const exportUserData = async (arg) => {
  try {
    return new Promise(async (resolve, reject) => {
      if (arg["type"] == "personal" || arg["type"] == "Personal Information") {
        //for only ticket data export.
        arg["type"] = "personal";
        let exportData = await postApiCall(arg, "exportcsv");
        resolve(exportData);
      } else if (arg["type"] == "tickets") {
        //for only personal information data export.
        arg["attachment"] = [];
        let exportTicketData = await postApiCall(arg, "exportTicketData");
        resolve(exportTicketData);
      } else {
        //for all data export.
        let exportData = await postApiCall(arg, "exportcsv");
        if (exportData.status == 200) {
          arg["attachment"] = exportData["data"];
          arg["userInfo"] = exportData["userInfo"];
          let exportTicketData = await postApiCall(arg, "exportTicketData");
          //console.log(exportTicketData,"exportTicketData")
        }
        resolve(exportData);
      }
    });
  } catch (e) {
    exportUserData(arg);
  }
};

export const contentmoderation = (text) => {
  try {
    //console.log(text);
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      myHeaders.append(
        "Ocp-Apim-Subscription-Key",
        "22fc4810718b48a1b663081c1b857a95"
      );

      var raw = text;

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        `https://eastus.api.cognitive.microsoft.com/contentmoderator/moderate/v1.0/ProcessText/Screen?correct=True&PII=True&classify=True&language=eng`,
        requestOptions
      )
        .then((response) => response.text())
        .then((data) => {
          //console.log(data);
          resolve(JSON.parse(data));
        });
    });
  } catch (e) {
    //fetchUserIp()
    resolve(e);
  }
};

export const fetchUserIp = async () => {
  try {
    return new Promise(async (resolve, reject) => {
      fetch(`https://snappy.appypie.com/mstesting/get-visitor-ip`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });
  } catch (e) {
    //fetchUserIp()
    resolve(e);
  }
};

export const getsearchdata = async (org_id, data) => {
  return fetch(
    `${process.env.REACT_APP_API_CONFIG}/searchkbdetail?organization_id=${org_id}&search=${data}`,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Accept-Charset": "utf-8",
      },
    }
  )
    .then((res) => {
      console.log("it is json");
      // console.log(res.json())
      return res.json();
    })
    .then((json) => {
      console.log("is  working or not");
      console.log(json);
      return json;
    })
    .catch((err) => {
      console.log("bad request err");
      console.log(err.message);
      return false;
    });
};

export const checkUserOwnDb = async (companyidentifier, email, login_type) => {
  if (!localStorage.getItem("deskktoken")) var tokens = await gettoken();
  const token = localStorage.getItem("deskktoken");
  if (token) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await isValidUserToken(email);
        const user_token = JSON.parse(res).user_token;
        var connectsid = await getCookie("APPYID");
        

        //var body ={'email':email,'company_identifier':companyidentifier,login_type,'file_name':connectsid,'user_token':user_token}
        fetch(
          `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/checkuserexist`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              //'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
              email: email,
              login_email: email,
              company_identifier: companyidentifier,
              login_type: login_type,
              login_email_type: login_type,
              file_name: connectsid,
              user_token: user_token,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then(async (response) => {
            if (response.user) {
              const parseData = response.user;
              ////console.log(parseData.Attributes,"res>>>>>>>>>>>>>")
              const Attributes = parseData.Attributes;
              var allAgentss = [];
              // var agentList = JSON.parse(response.data).Users;
              var newattr = {};
              //newattr[]
              Attributes.forEach((attr) => {
                var attrName = attr.Name.replace("custom:", "");
                //newattr = { ...newattr, [attrName]: attr.Value }
                newattr[attrName] = attr.Value;
                //allAgentss.push(newattr);
              });
              ////console.log(newattr,"allAgentss>>>>>>>>>>>>>>>>>!!!!!!")
              //resolve(newattr)
              //console.log(newattr,"newattr>>>>>SSS")
              //let isUserExist= await postApiCall({'id':newattr['sub'],'company_identifier':domainname},'checkAgentExist')
              if (login_type === "agent") {
                let isUserExist = await postApiCall(
                  {
                    id: newattr["preferred_username"],
                    company_identifier: companyidentifier,
                  },
                  "checkAgentExist"
                );
                resolve(isUserExist);
              } else {
                resolve(response);
              }
            } else {
              resolve(response);
            }
          });
      } catch (err) {
        reject(new Error(err));
      }
    });
  } else {
    setTimeout(() => {
      checkUserOwnDb(companyidentifier, email, login_type);
    }, 1000);
  }
};

export const activityLog = async (action) => {
  let app = await getRealMApp();
  const user = await app.currentUser;

  const limit = 100;
  const page = 1;
  //var objectID=ObjectID()
  let userdata = jwt.decode(localStorage.getItem("token"));
  if (userdata) {
    let logData = {
      product_name: "helpdesk",
      action: action,
      user_id: parseInt(userdata.id),
      company_name: userdata.domainname,
      //user_type:"owner",
      user_type: userdata.role_type,
      partition_key: Date.now().toString(),
      addedon: Date.now(),
      _id: ObjectId(),
    };
    //console.log(logData,"objectID")

    if (user) {
      //console.log(app.users[0].functions,"app>>>>>>")
      //console.log(user,"app>>>>>>")
      //    var useragent = await app.logIn(credentials);
      //    console.log(useragent,"useragent>>>>>>")
      await app.currentUser.refreshCustomData();
      return new Promise(async (resolve, reject) => {
        //const result1 = await app.functions.insertLog(logData);
        const result1 = await user.functions.insertLog(logData);
        console.log(result1, "result1");
        resolve(result1);
      });
    } else {
      //await app.currentUser.refreshCustomData();
      var useragent = await app.logIn(credentials);
      await app.currentUser.refreshCustomData();

      return new Promise(async (resolve, reject) => {
        //const result1 = await app.functions.insertLog(logData);
        const result1 = await useragent.functions.insertLog(logData);
        console.log(result1, "result1");
        resolve(result1);
      });
    }
  } else {
    //token not available
  }
};

export const updateProfileOnComments = async (args) => {
  return new Promise(async (resolve, reject) => {
    let response = await postApiCall(args, "updateProfileOnComments");
    resolve(response);
  });
};

export const isValidUserToken = async (email) => {
  const token = localStorage.getItem("deskktoken");

  if (token) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Cookie", "connect.lang=en");
    myHeaders.append("Authorization", "Bearer " + token);
    //headers: {
    //          'Content-Type': 'application/json',
    //          'Authorization': 'Bearer ' + token
    //          },
    var urlencoded = new URLSearchParams();
    var connectsid = getCookie("APPYID");
    urlencoded.append("file_name", connectsid);
    urlencoded.append("email", email);
    if (!localStorage.getItem("deskktoken")) {
      var tokens = await gettoken();
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    console.log(token, "token");
    return new Promise((resolve, reject) => {
      fetch(
        `${process.env.REACT_APP_COMMON_LOGIN}/api/agentcustomer/validuser`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          resolve(result);
        })
        .catch((error) => console.log("error", error));
    });
  }
};

export const getsearchTicketdata = async (user_id, data) => {
  let deskktoken = localStorage.getItem("customer");
  return fetch(
    `${process.env.REACT_APP_API_CONFIG}/ticketGlobalSearch?user_id=${user_id}&stext=${data}&page=1&itemPerPage=10`,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Accept-Charset": "utf-8",
        Authorization: `Bearer ${deskktoken}`,
      },
    }
  )
    .then((res) => {
      console.log("it is json");
      // console.log(res.json())
      return res.json();
    })
    .then((json) => {
      console.log("is  working or not");
      console.log(json);
      if (json.success) {
        return json.results.hits;
      } else {
        return [];
      }
      //return json
    })
    .catch((err) => {
      console.log("bad request err");
      console.log(err.message);
      return false;
    });
};

export const getFilterTicketdata = async (user_id, status) => {
  let deskktoken = localStorage.getItem("customer");
  return fetch(
    `${process.env.REACT_APP_API_CONFIG}/ticketStatusFilter?user_id=${user_id}&status=${status}&page=1&itemPerPage=10`,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Accept-Charset": "utf-8",
        Authorization: `Bearer ${deskktoken}`,
      },
    }
  )
    .then((res) => {
      console.log("it is json");
      // console.log(res.json())
      return res.json();
    })
    .then((json) => {
      console.log("is  working or not");
      console.log(json);
      if (json.success) {
        return json.results.hits;
      } else {
        return [];
      }
      //return json
    })
    .catch((err) => {
      console.log("bad request err");
      console.log(err.message);
      return false;
    });
};
export const getSortTicketdata = async (user_id, type, sortType) => {
  let deskktoken = localStorage.getItem("customer");
  let sortOrder = sortType ? "asc" : "desc";
  console.log(sortOrder, "sortOrder");
  return fetch(
    `${process.env.REACT_APP_API_CONFIG}/ticketSorting?user_id=${user_id}&sortingField=${type}&sortOrder=${sortOrder}&page=1&itemPerPage=10`,
    {
      method: "get",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Accept-Charset": "utf-8",
        Authorization: `Bearer ${deskktoken}`,
      },
    }
  )
    .then((res) => {
      console.log("it is json");
      // console.log(res.json())
      return res.json();
    })
    .then((json) => {
      console.log("is  working or not");
      console.log(json);
      if (json.success) {
        return json.results.hits;
      } else {
        return [];
      }
      //return json
    })
    .catch((err) => {
      console.log("bad request err");
      console.log(err.message);
      return false;
    });
};


export const getFeedbackSetting = async (args) => {
  // return new Promise(async (resolve, reject) => {
  //   let response = await postApiCall(args, "getFeedbackSetting");
  //   resolve(response);
  // });

  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${"getFeedbackSetting"}`, {
      method: "POST",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
        
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};


export const createCustomerFeedback = async (args) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${"createCustomerFeedback"}`, {
      method: "POST",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
        
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};


export const checkFeedbackExists = async (args) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_CONFIG}/${"checkFeedbackExist"}`, {
      method: "POST",
      body: JSON.stringify(args),
      headers: {
        "Content-Type": "application/json",
        
      },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response);
      });
  });
};

export const countries = () => {
  return [
    { name: "Afghanistan", code: "AF" },
    { name: "land Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic PeopleS Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao PeopleS Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
};

export const timeZones = () => {
  return [
    {
      value: "Africa/Abidjan",
      label: "Africa/Abidjan",
    },
    {
      value: "Africa/Accra",
      label: "Africa/Accra",
    },
    {
      value: "Africa/Algiers",
      label: "Africa/Algiers",
    },
    {
      value: "Africa/Bissau",
      label: "Africa/Bissau",
    },
    {
      value: "Africa/Cairo",
      label: "Africa/Cairo",
    },
    {
      value: "Africa/Casablanca",
      label: "Africa/Casablanca",
    },
    {
      value: "Africa/Ceuta",
      label: "Africa/Ceuta",
    },
    {
      value: "Africa/El_Aaiun",
      label: "Africa/El_Aaiun",
    },
    {
      value: "Africa/Johannesburg",
      label: "Africa/Johannesburg",
    },
    {
      value: "Africa/Juba",
      label: "Africa/Juba",
    },
    {
      value: "Africa/Khartoum",
      label: "Africa/Khartoum",
    },
    {
      value: "Africa/Lagos",
      label: "Africa/Lagos",
    },
    {
      value: "Africa/Maputo",
      label: "Africa/Maputo",
    },
    {
      value: "Africa/Monrovia",
      label: "Africa/Monrovia",
    },
    {
      value: "Africa/Nairobi",
      label: "Africa/Nairobi",
    },
    {
      value: "Africa/Ndjamena",
      label: "Africa/Ndjamena",
    },
    {
      value: "Africa/Sao_Tome",
      label: "Africa/Sao_Tome",
    },
    {
      value: "Africa/Tripoli",
      label: "Africa/Tripoli",
    },
    {
      value: "Africa/Tunis",
      label: "Africa/Tunis",
    },
    {
      value: "Africa/Windhoek",
      label: "Africa/Windhoek",
    },
    {
      value: "America/Adak",
      label: "America/Adak",
    },
    {
      value: "America/Anchorage",
      label: "America/Anchorage",
    },
    {
      value: "America/Araguaina",
      label: "America/Araguaina",
    },
    {
      value: "America/Argentina/Buenos_Aires",
      label: "America/Argentina/Buenos_Aires",
    },
    {
      value: "America/Argentina/Catamarca",
      label: "America/Argentina/Catamarca",
    },
    {
      value: "America/Argentina/Cordoba",
      label: "America/Argentina/Cordoba",
    },
    {
      value: "America/Argentina/Jujuy",
      label: "America/Argentina/Jujuy",
    },
    {
      value: "America/Argentina/La_Rioja",
      label: "America/Argentina/La_Rioja",
    },
    {
      value: "America/Argentina/Mendoza",
      label: "America/Argentina/Mendoza",
    },
    {
      value: "America/Argentina/Rio_Gallegos",
      label: "America/Argentina/Rio_Gallegos",
    },
    {
      value: "America/Argentina/Salta",
      label: "America/Argentina/Salta",
    },
    {
      value: "America/Argentina/San_Juan",
      label: "America/Argentina/San_Juan",
    },
    {
      value: "America/Argentina/San_Luis",
      label: "America/Argentina/San_Luis",
    },
    {
      value: "America/Argentina/Tucuman",
      label: "America/Argentina/Tucuman",
    },
    {
      value: "America/Argentina/Ushuaia",
      label: "America/Argentina/Ushuaia",
    },
    {
      value: "America/Asuncion",
      label: "America/Asuncion",
    },
    {
      value: "America/Atikokan",
      label: "America/Atikokan",
    },
    {
      value: "America/Bahia",
      label: "America/Bahia",
    },
    {
      value: "America/Bahia_Banderas",
      label: "America/Bahia_Banderas",
    },
    {
      value: "America/Barbados",
      label: "America/Barbados",
    },
    {
      value: "America/Belem",
      label: "America/Belem",
    },
    {
      value: "America/Belize",
      label: "America/Belize",
    },
    {
      value: "America/Blanc-Sablon",
      label: "America/Blanc-Sablon",
    },
    {
      value: "America/Boa_Vista",
      label: "America/Boa_Vista",
    },
    {
      value: "America/Bogota",
      label: "America/Bogota",
    },
    {
      value: "America/Boise",
      label: "America/Boise",
    },
    {
      value: "America/Cambridge_Bay",
      label: "America/Cambridge_Bay",
    },
    {
      value: "America/Campo_Grande",
      label: "America/Campo_Grande",
    },
    {
      value: "America/Cancun",
      label: "America/Cancun",
    },
    {
      value: "America/Caracas",
      label: "America/Caracas",
    },
    {
      value: "America/Cayenne",
      label: "America/Cayenne",
    },
    {
      value: "America/Chicago",
      label: "America/Chicago",
    },
    {
      value: "America/Chihuahua",
      label: "America/Chihuahua",
    },
    {
      value: "America/Costa_Rica",
      label: "America/Costa_Rica",
    },
    {
      value: "America/Creston",
      label: "America/Creston",
    },
    {
      value: "America/Cuiaba",
      label: "America/Cuiaba",
    },
    {
      value: "America/Curacao",
      label: "America/Curacao",
    },
    {
      value: "America/Danmarkshavn",
      label: "America/Danmarkshavn",
    },
    {
      value: "America/Dawson",
      label: "America/Dawson",
    },
    {
      value: "America/Dawson_Creek",
      label: "America/Dawson_Creek",
    },
    {
      value: "America/Denver",
      label: "America/Denver",
    },
    {
      value: "America/Detroit",
      label: "America/Detroit",
    },
    {
      value: "America/Edmonton",
      label: "America/Edmonton",
    },
    {
      value: "America/Eirunepe",
      label: "America/Eirunepe",
    },
    {
      value: "America/El_Salvador",
      label: "America/El_Salvador",
    },
    {
      value: "America/Fort_Nelson",
      label: "America/Fort_Nelson",
    },
    {
      value: "America/Fortaleza",
      label: "America/Fortaleza",
    },
    {
      value: "America/Glace_Bay",
      label: "America/Glace_Bay",
    },
    {
      value: "America/Goose_Bay",
      label: "America/Goose_Bay",
    },
    {
      value: "America/Grand_Turk",
      label: "America/Grand_Turk",
    },
    {
      value: "America/Guatemala",
      label: "America/Guatemala",
    },
    {
      value: "America/Guayaquil",
      label: "America/Guayaquil",
    },
    {
      value: "America/Guyana",
      label: "America/Guyana",
    },
    {
      value: "America/Halifax",
      label: "America/Halifax",
    },
    {
      value: "America/Havana",
      label: "America/Havana",
    },
    {
      value: "America/Hermosillo",
      label: "America/Hermosillo",
    },
    {
      value: "America/Indiana/Indianapolis",
      label: "America/Indiana/Indianapolis",
    },
    {
      value: "America/Indiana/Knox",
      label: "America/Indiana/Knox",
    },
    {
      value: "America/Indiana/Marengo",
      label: "America/Indiana/Marengo",
    },
    {
      value: "America/Indiana/Petersburg",
      label: "America/Indiana/Petersburg",
    },
    {
      value: "America/Indiana/Tell_City",
      label: "America/Indiana/Tell_City",
    },
    {
      value: "America/Indiana/Vevay",
      label: "America/Indiana/Vevay",
    },
    {
      value: "America/Indiana/Vincennes",
      label: "America/Indiana/Vincennes",
    },
    {
      value: "America/Indiana/Winamac",
      label: "America/Indiana/Winamac",
    },
    {
      value: "America/Inuvik",
      label: "America/Inuvik",
    },
    {
      value: "America/Iqaluit",
      label: "America/Iqaluit",
    },
    {
      value: "America/Jamaica",
      label: "America/Jamaica",
    },
    {
      value: "America/Juneau",
      label: "America/Juneau",
    },
    {
      value: "America/Kentucky/Louisville",
      label: "America/Kentucky/Louisville",
    },
    {
      value: "America/Kentucky/Monticello",
      label: "America/Kentucky/Monticello",
    },
    {
      value: "America/La_Paz",
      label: "America/La_Paz",
    },
    {
      value: "America/Lima",
      label: "America/Lima",
    },
    {
      value: "America/Los_Angeles",
      label: "America/Los_Angeles",
    },
    {
      value: "America/Maceio",
      label: "America/Maceio",
    },
    {
      value: "America/Managua",
      label: "America/Managua",
    },
    {
      value: "America/Manaus",
      label: "America/Manaus",
    },
    {
      value: "America/Martinique",
      label: "America/Martinique",
    },
    {
      value: "America/Matamoros",
      label: "America/Matamoros",
    },
    {
      value: "America/Mazatlan",
      label: "America/Mazatlan",
    },
    {
      value: "America/Menominee",
      label: "America/Menominee",
    },
    {
      value: "America/Merida",
      label: "America/Merida",
    },
    {
      value: "America/Metlakatla",
      label: "America/Metlakatla",
    },
    {
      value: "America/Mexico_City",
      label: "America/Mexico_City",
    },
    {
      value: "America/Miquelon",
      label: "America/Miquelon",
    },
    {
      value: "America/Moncton",
      label: "America/Moncton",
    },
    {
      value: "America/Monterrey",
      label: "America/Monterrey",
    },
    {
      value: "America/Montevideo",
      label: "America/Montevideo",
    },
    {
      value: "America/Nassau",
      label: "America/Nassau",
    },
    {
      value: "America/New_York",
      label: "America/New_York",
    },
    {
      value: "America/Nipigon",
      label: "America/Nipigon",
    },
    {
      value: "America/Nome",
      label: "America/Nome",
    },
    {
      value: "America/Noronha",
      label: "America/Noronha",
    },
    {
      value: "America/North_Dakota/Beulah",
      label: "America/North_Dakota/Beulah",
    },
    {
      value: "America/North_Dakota/Center",
      label: "America/North_Dakota/Center",
    },
    {
      value: "America/North_Dakota/New_Salem",
      label: "America/North_Dakota/New_Salem",
    },
    {
      value: "America/Nuuk",
      label: "America/Nuuk",
    },
    {
      value: "America/Ojinaga",
      label: "America/Ojinaga",
    },
    {
      value: "America/Panama",
      label: "America/Panama",
    },
    {
      value: "America/Pangnirtung",
      label: "America/Pangnirtung",
    },
    {
      value: "America/Paramaribo",
      label: "America/Paramaribo",
    },
    {
      value: "America/Phoenix",
      label: "America/Phoenix",
    },
    {
      value: "America/Port-au-Prince",
      label: "America/Port-au-Prince",
    },
    {
      value: "America/Port_of_Spain",
      label: "America/Port_of_Spain",
    },
    {
      value: "America/Porto_Velho",
      label: "America/Porto_Velho",
    },
    {
      value: "America/Puerto_Rico",
      label: "America/Puerto_Rico",
    },
    {
      value: "America/Punta_Arenas",
      label: "America/Punta_Arenas",
    },
    {
      value: "America/Rainy_River",
      label: "America/Rainy_River",
    },
    {
      value: "America/Rankin_Inlet",
      label: "America/Rankin_Inlet",
    },
    {
      value: "America/Recife",
      label: "America/Recife",
    },
    {
      value: "America/Regina",
      label: "America/Regina",
    },
    {
      value: "America/Resolute",
      label: "America/Resolute",
    },
    {
      value: "America/Rio_Branco",
      label: "America/Rio_Branco",
    },
    {
      value: "America/Santarem",
      label: "America/Santarem",
    },
    {
      value: "America/Santiago",
      label: "America/Santiago",
    },
    {
      value: "America/Santo_Domingo",
      label: "America/Santo_Domingo",
    },
    {
      value: "America/Sao_Paulo",
      label: "America/Sao_Paulo",
    },
    {
      value: "America/Scoresbysund",
      label: "America/Scoresbysund",
    },
    {
      value: "America/Sitka",
      label: "America/Sitka",
    },
    {
      value: "America/St_Johns",
      label: "America/St_Johns",
    },
    {
      value: "America/Swift_Current",
      label: "America/Swift_Current",
    },
    {
      value: "America/Tegucigalpa",
      label: "America/Tegucigalpa",
    },
    {
      value: "America/Thule",
      label: "America/Thule",
    },
    {
      value: "America/Thunder_Bay",
      label: "America/Thunder_Bay",
    },
    {
      value: "America/Tijuana",
      label: "America/Tijuana",
    },
    {
      value: "America/Toronto",
      label: "America/Toronto",
    },
    {
      value: "America/Vancouver",
      label: "America/Vancouver",
    },
    {
      value: "America/Whitehorse",
      label: "America/Whitehorse",
    },
    {
      value: "America/Winnipeg",
      label: "America/Winnipeg",
    },
    {
      value: "America/Yakutat",
      label: "America/Yakutat",
    },
    {
      value: "America/Yellowknife",
      label: "America/Yellowknife",
    },
    {
      value: "Antarctica/Casey",
      label: "Antarctica/Casey",
    },
    {
      value: "Antarctica/Davis",
      label: "Antarctica/Davis",
    },
    {
      value: "Antarctica/DumontDUrville",
      label: "Antarctica/DumontDUrville",
    },
    {
      value: "Antarctica/Macquarie",
      label: "Antarctica/Macquarie",
    },
    {
      value: "Antarctica/Mawson",
      label: "Antarctica/Mawson",
    },
    {
      value: "Antarctica/Palmer",
      label: "Antarctica/Palmer",
    },
    {
      value: "Antarctica/Rothera",
      label: "Antarctica/Rothera",
    },
    {
      value: "Antarctica/Syowa",
      label: "Antarctica/Syowa",
    },
    {
      value: "Antarctica/Troll",
      label: "Antarctica/Troll",
    },
    {
      value: "Antarctica/Vostok",
      label: "Antarctica/Vostok",
    },
    {
      value: "Asia/Almaty",
      label: "Asia/Almaty",
    },
    {
      value: "Asia/Amman",
      label: "Asia/Amman",
    },
    {
      value: "Asia/Anadyr",
      label: "Asia/Anadyr",
    },
    {
      value: "Asia/Aqtau",
      label: "Asia/Aqtau",
    },
    {
      value: "Asia/Aqtobe",
      label: "Asia/Aqtobe",
    },
    {
      value: "Asia/Ashgabat",
      label: "Asia/Ashgabat",
    },
    {
      value: "Asia/Atyrau",
      label: "Asia/Atyrau",
    },
    {
      value: "Asia/Baghdad",
      label: "Asia/Baghdad",
    },
    {
      value: "Asia/Baku",
      label: "Asia/Baku",
    },
    {
      value: "Asia/Bangkok",
      label: "Asia/Bangkok",
    },
    {
      value: "Asia/Barnaul",
      label: "Asia/Barnaul",
    },
    {
      value: "Asia/Beirut",
      label: "Asia/Beirut",
    },
    {
      value: "Asia/Bishkek",
      label: "Asia/Bishkek",
    },
    {
      value: "Asia/Brunei",
      label: "Asia/Brunei",
    },
    {
      value: "Asia/Chita",
      label: "Asia/Chita",
    },
    {
      value: "Asia/Choibalsan",
      label: "Asia/Choibalsan",
    },
    {
      value: "Asia/Colombo",
      label: "Asia/Colombo",
    },
    {
      value: "Asia/Damascus",
      label: "Asia/Damascus",
    },
    {
      value: "Asia/Dhaka",
      label: "Asia/Dhaka",
    },
    {
      value: "Asia/Dili",
      label: "Asia/Dili",
    },
    {
      value: "Asia/Dubai",
      label: "Asia/Dubai",
    },
    {
      value: "Asia/Dushanbe",
      label: "Asia/Dushanbe",
    },
    {
      value: "Asia/Famagusta",
      label: "Asia/Famagusta",
    },
    {
      value: "Asia/Gaza",
      label: "Asia/Gaza",
    },
    {
      value: "Asia/Hebron",
      label: "Asia/Hebron",
    },
    {
      value: "Asia/Ho_Chi_Minh",
      label: "Asia/Ho_Chi_Minh",
    },
    {
      value: "Asia/Hong_Kong",
      label: "Asia/Hong_Kong",
    },
    {
      value: "Asia/Hovd",
      label: "Asia/Hovd",
    },
    {
      value: "Asia/Irkutsk",
      label: "Asia/Irkutsk",
    },
    {
      value: "Asia/Jakarta",
      label: "Asia/Jakarta",
    },
    {
      value: "Asia/Jayapura",
      label: "Asia/Jayapura",
    },
    {
      value: "Asia/Jerusalem",
      label: "Asia/Jerusalem",
    },
    {
      value: "Asia/Kabul",
      label: "Asia/Kabul",
    },
    {
      value: "Asia/Kamchatka",
      label: "Asia/Kamchatka",
    },
    {
      value: "Asia/Karachi",
      label: "Asia/Karachi",
    },
    {
      value: "Asia/Kathmandu",
      label: "Asia/Kathmandu",
    },
    {
      value: "Asia/Khandyga",
      label: "Asia/Khandyga",
    },
    {
      value: "Asia/Kolkata",
      label: "Asia/Kolkata",
    },
    {
      value: "Asia/Krasnoyarsk",
      label: "Asia/Krasnoyarsk",
    },
    {
      value: "Asia/Kuala_Lumpur",
      label: "Asia/Kuala_Lumpur",
    },
    {
      value: "Asia/Kuching",
      label: "Asia/Kuching",
    },
    {
      value: "Asia/Macau",
      label: "Asia/Macau",
    },
    {
      value: "Asia/Magadan",
      label: "Asia/Magadan",
    },
    {
      value: "Asia/Makassar",
      label: "Asia/Makassar",
    },
    {
      value: "Asia/Manila",
      label: "Asia/Manila",
    },
    {
      value: "Asia/Nicosia",
      label: "Asia/Nicosia",
    },
    {
      value: "Asia/Novokuznetsk",
      label: "Asia/Novokuznetsk",
    },
    {
      value: "Asia/Novosibirsk",
      label: "Asia/Novosibirsk",
    },
    {
      value: "Asia/Omsk",
      label: "Asia/Omsk",
    },
    {
      value: "Asia/Oral",
      label: "Asia/Oral",
    },
    {
      value: "Asia/Pontianak",
      label: "Asia/Pontianak",
    },
    {
      value: "Asia/Pyongyang",
      label: "Asia/Pyongyang",
    },
    {
      value: "Asia/Qatar",
      label: "Asia/Qatar",
    },
    {
      value: "Asia/Qostanay",
      label: "Asia/Qostanay",
    },
    {
      value: "Asia/Qyzylorda",
      label: "Asia/Qyzylorda",
    },
    {
      value: "Asia/Riyadh",
      label: "Asia/Riyadh",
    },
    {
      value: "Asia/Sakhalin",
      label: "Asia/Sakhalin",
    },
    {
      value: "Asia/Samarkand",
      label: "Asia/Samarkand",
    },
    {
      value: "Asia/Seoul",
      label: "Asia/Seoul",
    },
    {
      value: "Asia/Shanghai",
      label: "Asia/Shanghai",
    },
    {
      value: "Asia/Singapore",
      label: "Asia/Singapore",
    },
    {
      value: "Asia/Srednekolymsk",
      label: "Asia/Srednekolymsk",
    },
    {
      value: "Asia/Taipei",
      label: "Asia/Taipei",
    },
    {
      value: "Asia/Tashkent",
      label: "Asia/Tashkent",
    },
    {
      value: "Asia/Tbilisi",
      label: "Asia/Tbilisi",
    },
    {
      value: "Asia/Tehran",
      label: "Asia/Tehran",
    },
    {
      value: "Asia/Thimphu",
      label: "Asia/Thimphu",
    },
    {
      value: "Asia/Tokyo",
      label: "Asia/Tokyo",
    },
    {
      value: "Asia/Tomsk",
      label: "Asia/Tomsk",
    },
    {
      value: "Asia/Ulaanbaatar",
      label: "Asia/Ulaanbaatar",
    },
    {
      value: "Asia/Urumqi",
      label: "Asia/Urumqi",
    },
    {
      value: "Asia/Ust-Nera",
      label: "Asia/Ust-Nera",
    },
    {
      value: "Asia/Vladivostok",
      label: "Asia/Vladivostok",
    },
    {
      value: "Asia/Yakutsk",
      label: "Asia/Yakutsk",
    },
    {
      value: "Asia/Yangon",
      label: "Asia/Yangon",
    },
    {
      value: "Asia/Yekaterinburg",
      label: "Asia/Yekaterinburg",
    },
    {
      value: "Asia/Yerevan",
      label: "Asia/Yerevan",
    },
    {
      value: "Atlantic/Azores",
      label: "Atlantic/Azores",
    },
    {
      value: "Atlantic/Bermuda",
      label: "Atlantic/Bermuda",
    },
    {
      value: "Atlantic/Canary",
      label: "Atlantic/Canary",
    },
    {
      value: "Atlantic/Cape_Verde",
      label: "Atlantic/Cape_Verde",
    },
    {
      value: "Atlantic/Faroe",
      label: "Atlantic/Faroe",
    },
    {
      value: "Atlantic/Madeira",
      label: "Atlantic/Madeira",
    },
    {
      value: "Atlantic/Reykjavik",
      label: "Atlantic/Reykjavik",
    },
    {
      value: "Atlantic/South_Georgia",
      label: "Atlantic/South_Georgia",
    },
    {
      value: "Atlantic/Stanley",
      label: "Atlantic/Stanley",
    },
    {
      value: "Australia/Adelaide",
      label: "Australia/Adelaide",
    },
    {
      value: "Australia/Brisbane",
      label: "Australia/Brisbane",
    },
    {
      value: "Australia/Broken_Hill",
      label: "Australia/Broken_Hill",
    },
    {
      value: "Australia/Currie",
      label: "Australia/Currie",
    },
    {
      value: "Australia/Darwin",
      label: "Australia/Darwin",
    },
    {
      value: "Australia/Eucla",
      label: "Australia/Eucla",
    },
    {
      value: "Australia/Hobart",
      label: "Australia/Hobart",
    },
    {
      value: "Australia/Lindeman",
      label: "Australia/Lindeman",
    },
    {
      value: "Australia/Lord_Howe",
      label: "Australia/Lord_Howe",
    },
    {
      value: "Australia/Melbourne",
      label: "Australia/Melbourne",
    },
    {
      value: "Australia/Perth",
      label: "Australia/Perth",
    },
    {
      value: "Australia/Sydney",
      label: "Australia/Sydney",
    },
    {
      value: "CET",
      label: "CET",
    },
    {
      value: "CST6CDT",
      label: "CST6CDT",
    },
    {
      value: "EET",
      label: "EET",
    },
    {
      value: "EST",
      label: "EST",
    },
    {
      value: "EST5EDT",
      label: "EST5EDT",
    },
    {
      value: "Etc/GMT",
      label: "Etc/GMT",
    },
    {
      value: "Etc/GMT+1",
      label: "Etc/GMT+1",
    },
    {
      value: "Etc/GMT+10",
      label: "Etc/GMT+10",
    },
    {
      value: "Etc/GMT+11",
      label: "Etc/GMT+11",
    },
    {
      value: "Etc/GMT+12",
      label: "Etc/GMT+12",
    },
    {
      value: "Etc/GMT+2",
      label: "Etc/GMT+2",
    },
    {
      value: "Etc/GMT+3",
      label: "Etc/GMT+3",
    },
    {
      value: "Etc/GMT+4",
      label: "Etc/GMT+4",
    },
    {
      value: "Etc/GMT+5",
      label: "Etc/GMT+5",
    },
    {
      value: "Etc/GMT+6",
      label: "Etc/GMT+6",
    },
    {
      value: "Etc/GMT+7",
      label: "Etc/GMT+7",
    },
    {
      value: "Etc/GMT+8",
      label: "Etc/GMT+8",
    },
    {
      value: "Etc/GMT+9",
      label: "Etc/GMT+9",
    },
    {
      value: "Etc/GMT-1",
      label: "Etc/GMT-1",
    },
    {
      value: "Etc/GMT-10",
      label: "Etc/GMT-10",
    },
    {
      value: "Etc/GMT-11",
      label: "Etc/GMT-11",
    },
    {
      value: "Etc/GMT-12",
      label: "Etc/GMT-12",
    },
    {
      value: "Etc/GMT-13",
      label: "Etc/GMT-13",
    },
    {
      value: "Etc/GMT-14",
      label: "Etc/GMT-14",
    },
    {
      value: "Etc/GMT-2",
      label: "Etc/GMT-2",
    },
    {
      value: "Etc/GMT-3",
      label: "Etc/GMT-3",
    },
    {
      value: "Etc/GMT-4",
      label: "Etc/GMT-4",
    },
    {
      value: "Etc/GMT-5",
      label: "Etc/GMT-5",
    },
    {
      value: "Etc/GMT-6",
      label: "Etc/GMT-6",
    },
    {
      value: "Etc/GMT-7",
      label: "Etc/GMT-7",
    },
    {
      value: "Etc/GMT-8",
      label: "Etc/GMT-8",
    },
    {
      value: "Etc/GMT-9",
      label: "Etc/GMT-9",
    },
    {
      value: "Etc/UTC",
      label: "Etc/UTC",
    },
    {
      value: "Europe/Amsterdam",
      label: "Europe/Amsterdam",
    },
    {
      value: "Europe/Andorra",
      label: "Europe/Andorra",
    },
    {
      value: "Europe/Astrakhan",
      label: "Europe/Astrakhan",
    },
    {
      value: "Europe/Athens",
      label: "Europe/Athens",
    },
    {
      value: "Europe/Belgrade",
      label: "Europe/Belgrade",
    },
    {
      value: "Europe/Berlin",
      label: "Europe/Berlin",
    },
    {
      value: "Europe/Brussels",
      label: "Europe/Brussels",
    },
    {
      value: "Europe/Bucharest",
      label: "Europe/Bucharest",
    },
    {
      value: "Europe/Budapest",
      label: "Europe/Budapest",
    },
    {
      value: "Europe/Chisinau",
      label: "Europe/Chisinau",
    },
    {
      value: "Europe/Copenhagen",
      label: "Europe/Copenhagen",
    },
    {
      value: "Europe/Dublin",
      label: "Europe/Dublin",
    },
    {
      value: "Europe/Gibraltar",
      label: "Europe/Gibraltar",
    },
    {
      value: "Europe/Helsinki",
      label: "Europe/Helsinki",
    },
    {
      value: "Europe/Istanbul",
      label: "Europe/Istanbul",
    },
    {
      value: "Europe/Kaliningrad",
      label: "Europe/Kaliningrad",
    },
    {
      value: "Europe/Kiev",
      label: "Europe/Kiev",
    },
    {
      value: "Europe/Kirov",
      label: "Europe/Kirov",
    },
    {
      value: "Europe/Lisbon",
      label: "Europe/Lisbon",
    },
    {
      value: "Europe/London",
      label: "Europe/London",
    },
    {
      value: "Europe/Luxembourg",
      label: "Europe/Luxembourg",
    },
    {
      value: "Europe/Madrid",
      label: "Europe/Madrid",
    },
    {
      value: "Europe/Malta",
      label: "Europe/Malta",
    },
    {
      value: "Europe/Minsk",
      label: "Europe/Minsk",
    },
    {
      value: "Europe/Monaco",
      label: "Europe/Monaco",
    },
    {
      value: "Europe/Moscow",
      label: "Europe/Moscow",
    },
    {
      value: "Europe/Oslo",
      label: "Europe/Oslo",
    },
    {
      value: "Europe/Paris",
      label: "Europe/Paris",
    },
    {
      value: "Europe/Prague",
      label: "Europe/Prague",
    },
    {
      value: "Europe/Riga",
      label: "Europe/Riga",
    },
    {
      value: "Europe/Rome",
      label: "Europe/Rome",
    },
    {
      value: "Europe/Samara",
      label: "Europe/Samara",
    },
    {
      value: "Europe/Saratov",
      label: "Europe/Saratov",
    },
    {
      value: "Europe/Simferopol",
      label: "Europe/Simferopol",
    },
    {
      value: "Europe/Sofia",
      label: "Europe/Sofia",
    },
    {
      value: "Europe/Stockholm",
      label: "Europe/Stockholm",
    },
    {
      value: "Europe/Tallinn",
      label: "Europe/Tallinn",
    },
    {
      value: "Europe/Tirane",
      label: "Europe/Tirane",
    },
    {
      value: "Europe/Ulyanovsk",
      label: "Europe/Ulyanovsk",
    },
    {
      value: "Europe/Uzhgorod",
      label: "Europe/Uzhgorod",
    },
    {
      value: "Europe/Vienna",
      label: "Europe/Vienna",
    },
    {
      value: "Europe/Vilnius",
      label: "Europe/Vilnius",
    },
    {
      value: "Europe/Volgograd",
      label: "Europe/Volgograd",
    },
    {
      value: "Europe/Warsaw",
      label: "Europe/Warsaw",
    },
    {
      value: "Europe/Zaporozhye",
      label: "Europe/Zaporozhye",
    },
    {
      value: "Europe/Zurich",
      label: "Europe/Zurich",
    },
    {
      value: "HST",
      label: "HST",
    },
    {
      value: "Indian/Chagos",
      label: "Indian/Chagos",
    },
    {
      value: "Indian/Christmas",
      label: "Indian/Christmas",
    },
    {
      value: "Indian/Cocos",
      label: "Indian/Cocos",
    },
    {
      value: "Indian/Kerguelen",
      label: "Indian/Kerguelen",
    },
    {
      value: "Indian/Mahe",
      label: "Indian/Mahe",
    },
    {
      value: "Indian/Maldives",
      label: "Indian/Maldives",
    },
    {
      value: "Indian/Mauritius",
      label: "Indian/Mauritius",
    },
    {
      value: "Indian/Reunion",
      label: "Indian/Reunion",
    },
    {
      value: "MET",
      label: "MET",
    },
    {
      value: "MST",
      label: "MST",
    },
    {
      value: "MST7MDT",
      label: "MST7MDT",
    },
    {
      value: "PST8PDT",
      label: "PST8PDT",
    },
    {
      value: "Pacific/Apia",
      label: "Pacific/Apia",
    },
    {
      value: "Pacific/Auckland",
      label: "Pacific/Auckland",
    },
    {
      value: "Pacific/Bougainville",
      label: "Pacific/Bougainville",
    },
    {
      value: "Pacific/Chatham",
      label: "Pacific/Chatham",
    },
    {
      value: "Pacific/Chuuk",
      label: "Pacific/Chuuk",
    },
    {
      value: "Pacific/Easter",
      label: "Pacific/Easter",
    },
    {
      value: "Pacific/Efate",
      label: "Pacific/Efate",
    },
    {
      value: "Pacific/Enderbury",
      label: "Pacific/Enderbury",
    },
    {
      value: "Pacific/Fakaofo",
      label: "Pacific/Fakaofo",
    },
    {
      value: "Pacific/Fiji",
      label: "Pacific/Fiji",
    },
    {
      value: "Pacific/Funafuti",
      label: "Pacific/Funafuti",
    },
    {
      value: "Pacific/Galapagos",
      label: "Pacific/Galapagos",
    },
    {
      value: "Pacific/Gambier",
      label: "Pacific/Gambier",
    },
    {
      value: "Pacific/Guadalcanal",
      label: "Pacific/Guadalcanal",
    },
    {
      value: "Pacific/Guam",
      label: "Pacific/Guam",
    },
    {
      value: "Pacific/Honolulu",
      label: "Pacific/Honolulu",
    },
    {
      value: "Pacific/Kiritimati",
      label: "Pacific/Kiritimati",
    },
    {
      value: "Pacific/Kosrae",
      label: "Pacific/Kosrae",
    },
    {
      value: "Pacific/Kwajalein",
      label: "Pacific/Kwajalein",
    },
    {
      value: "Pacific/Majuro",
      label: "Pacific/Majuro",
    },
    {
      value: "Pacific/Marquesas",
      label: "Pacific/Marquesas",
    },
    {
      value: "Pacific/Nauru",
      label: "Pacific/Nauru",
    },
    {
      value: "Pacific/Niue",
      label: "Pacific/Niue",
    },
    {
      value: "Pacific/Norfolk",
      label: "Pacific/Norfolk",
    },
    {
      value: "Pacific/Noumea",
      label: "Pacific/Noumea",
    },
    {
      value: "Pacific/Pago_Pago",
      label: "Pacific/Pago_Pago",
    },
    {
      value: "Pacific/Palau",
      label: "Pacific/Palau",
    },
    {
      value: "Pacific/Pitcairn",
      label: "Pacific/Pitcairn",
    },
    {
      value: "Pacific/Pohnpei",
      label: "Pacific/Pohnpei",
    },
    {
      value: "Pacific/Port_Moresby",
      label: "Pacific/Port_Moresby",
    },
    {
      value: "Pacific/Rarotonga",
      label: "Pacific/Rarotonga",
    },
    {
      value: "Pacific/Tahiti",
      label: "Pacific/Tahiti",
    },
    {
      value: "Pacific/Tarawa",
      label: "Pacific/Tarawa",
    },
    {
      value: "Pacific/Tongatapu",
      label: "Pacific/Tongatapu",
    },
    {
      value: "Pacific/Wake",
      label: "Pacific/Wake",
    },
    {
      value: "Pacific/Wallis",
      label: "Pacific/Wallis",
    },
    {
      value: "WET",
      label: "WET",
    },
  ];
};
