import React,{useState, useEffect} from 'react';
import { useHistory } from "react-router-dom"
import {getFeedbackSetting,checkFeedbackExists,createCustomerFeedback} from "../../utils/services"
import Loader from "../../components/Shared/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {firebase} from '../../utils/db'

const CustomerFeedback = (props) => {   
    const [orgId, setOrgId] = useState(props.match.params.orgId)
    const [ticketId, setTicketId] = useState(props.match.params.ticketId)
    const [docId, setDocId] = useState(props.match.params.docId)
    const history = useHistory();
    const [settingData,setSettingData] = useState('')
    const [loading, setLoading] = useState(false)
    const [feedbackQuestion,setFeedbackQuestion] = useState('')
    const [feedType, setfeedTypes ]= useState('');
    const [feedValue, setFeedValue ]= useState('');
    const [incCommentBox, setIncCommentBox ]= useState('');
    const [incCommentMandatory,setIncCommentMandatory] =useState('');
    const [commentDescription, setCommentDescription ]= useState('');
    const [comment,setComment] = useState('');
    const [isGood, setIsGood] = useState(true);
    const [isOk, setIsOk] = useState(false);
    const [isBad, setIsBad] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFeedback,setIsFeedback] = useState(true)
    const [noData,setNoData] = useState(true)
    const [success,setSuccess] = useState(false)
    const [thankyou, setThankyou ]= useState('');
    const [isResults,setResults] =useState('');
    const [active, setActive] = useState("");
    const [logID, setLogID] = useState("");
   // const [isActiveClass,setActiveClass] = useState(false);
    
   
    



useEffect(() => {
    checkFeedbackExist()
    //getSettingFeedback()

},[])


  const successURL= msg => {
       
            history.push(
            {
              pathname: '/customer-feedback/success',
              state: { detail: msg,orgId:orgId ,logID:logID}
          }
            )
       
    }

const checkFeedbackExist = async() =>{
    setLoading(true)
let feedbackData = await checkFeedbackExists({'orgId':orgId,'ticketId':ticketId,'docId':docId});
 
if(Object.keys(feedbackData.results).length > 0){
    setLoading(false)
    if(feedbackData.results.isSubmit === true){
        setLoading(false)
        setIsFeedback(false)
    }else{
        setLoading(false)
        setIsFeedback(true)
        await getSettingFeedback()
    }

}
else{  
   setLoading(false);
    setNoData(false)
    //await getSettingFeedback()
}
}
const getSettingFeedback = async() =>{
    setLoading(true)
let setting = await getFeedbackSetting({'orgId':orgId,'ticketId':ticketId});
    setSettingData(setting.response)
   
    if(Object.keys(setting).length > 0){
        setLoading(false)
        setFeedbackQuestion(setting.response.feedbackQuestion)
        setfeedTypes(setting.response.feedType)
        setIncCommentBox(setting.response.incCommentBox)
        setIncCommentMandatory(setting.response.incCommentMandatory)
        setCommentDescription(setting.response.commentDescription)
        setThankyou(setting.response.thankyouPageContent)
        setFeedValue(setting.response.feedType === 'smiley' ? 'good'  :'10')
        setActive(setting.response.feedType === 'scale' ? '10':'undefined')
    }


}

const toggleGood = () => {
    setIsGood(true);
    setIsOk(false);
    setIsBad(false);
    setFeedValue('good')
  };
  const toggleOk = () => {
    setIsGood(false);
    setIsOk(true);
    setIsBad(false);
    setFeedValue('ok')
  };
  const toggleBad = () => {
    setIsGood(false);
    setIsOk(false);
    setIsBad(true);
    setFeedValue('bad')
  };


  const handleChange = e => {
    const {name, value} = e.target;
    setFeedValue(value);
    setActive(e.target.id);

}

const handleValidation =()=> {
    let fields = {incCommentMandatory:incCommentMandatory,comment:comment,feedValue:feedValue};
    //console.log(fields);
    let errors = {};
    let formIsValid = true;
    if (fields.incCommentMandatory && !fields.comment) {
      formIsValid = false;
      errors["incCommentMandatory"] = "Comment is required";
    }
    // if (!fields.feedValue ) {
    //     formIsValid = false;
    //     errors["ratingError"] = "Rating is required";
    //   }
    
    setErrors(errors);
    return formIsValid;
   
  }
const handleSubmit =  (evt) => {
    evt.preventDefault();
if(isResults) {toast.error('You have already submitted feedback'); return }
   const isValid = handleValidation();
    //console.log(isValid);
   if(!isValid) return
    setLoading(true)
    let datatoSave ={
        feedbackQuestion:feedbackQuestion, 
        orgId:orgId,
        ticketId:ticketId,
        feedType:feedType,
        feedValue:feedValue,
        comment:comment,
        // commentDescription:commentDescription,
        docId:docId

    }
  //  console.log('jkjhkhkhhkhjgg',datatoSave); return
    createCustomerFeedback(datatoSave).then(async(res)=>{
        setLoading(false);
        setSuccess(true) 
        if(res.results){ 
            setResults(res.results)
            successURL(thankyou)
            //setSubmit(true)
       // toast.success(thankyou ? thankyou: 'Feedback submitted successfully')
        }

        const feedbackData = {
          "ticket_id" : ticketId,
          "events" : {
            "click_link" : true,
            "submit_button_click" : false,
            "form_submit" : false
          } 
        }

await firebase.firestore().collection("SupportRootCollections").doc(`${orgId}`).collection("customer_feedback_logs").add(feedbackData).then((documents)=>{
  console.log(documents.id,'customer_feedback_logs');
  setLogID(documents.id)
}).catch(err=>{
  console.log(err);
  return false
})
  
}).catch(err => {
    setLoading(false); 
    return false;
})

}


    return (
        <>
        { loading ? <div className="shadow">
        <Loader /> 
        </div>: null}
        <div className="container main-container">
        <ToastContainer />
  <div className="centerMainContainer">
    <div className="row mt-4">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <form  id="myForm" onSubmit={handleSubmit} >
        <div className="customerSelctSurbay bg-white">
            {!isFeedback ? <div className="text-center px-5 py-5"><img src="../../../images/good-feedback.png"/><p>You have already submitted feedback</p></div> :
            
            ( !noData)?<div className="text-center px-5 py-5"><img src="../../../images/department-no-added.svg"/><p>Invalid url you are entering!</p></div>:
           ( isFeedback && Object.keys(settingData).length > 0 )? 
          <div className="allCustomerSelctSurbay px-5 py-5">
            <div className="ratingType mb-5">
              <div className="smilly text-center">
                <h5 className="card-title mb-0 text-center fnt-bold">
                 {feedbackQuestion ? feedbackQuestion :''}
                </h5>
            {feedType === 'smiley' ?  (<ul>
                  <li>
                    <span className="emojiIcon"  onClick={() => toggleGood()}>
                        { isGood ?  <img src="../../../images/green.svg" />:  <img src="../../../images/gray-green.svg" /> }
                   </span>
                    <p className="fnt-12 mb-0">Good</p>
                  </li>
                  <li>
                  <span className="emojiIcon"  onClick={() => toggleOk()}>
                        { isOk ?  <img src="../../../images/yellow.svg" />:  <img src="../../../images/yellow-gray.svg" /> }
                   </span>
                    <p className="fnt-12 mb-0">Okay</p>
                  </li>
                  <li>
                  <span className="emojiIcon"  onClick={() => toggleBad()}>
                        { isBad ?  <img src="../../../images/red.svg" />:  <img src="../../../images/red-gray.svg" /> }
                   </span>
                    <p className="fnt-12 mb-0">Bad</p>
                  </li>
                </ul>):null
                
                }
                {feedType === 'smiley' && errors.ratingError ? <p className="error">{errors.ratingError}</p>:''} 
                </div>
              {feedType === 'scale' ?  (  <div className="scaleRating text-center">
                                      {/* <p className="text-mutede">How would you rate this</p> */}
                                      <ul>
  <li className={active === "1" ? "active" : undefined}>
    <p>1</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          value={1}
          id={1}

          //onChange={(e) =>setFeedValue(e.target.value)}
          onChange={(e) =>handleChange(e)}
         
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "2" ? "active" : undefined}>
    <p>2</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={2}
          value={2}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "3" ? "active" : undefined}>
    <p>3</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={3}
          value={3}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "4" ? "active" : undefined}>
    <p>4</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={4}
          value={4}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "5" ? "active" : undefined}>
    <p>5</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={5}
          value={5}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "6" ? "active" : undefined}>
    <p>6</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={6}
          value={6}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "7" ? "active" : undefined}>
    <p>7</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={7}
          value={7}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li  className={active === "8" ? "active" : undefined}>
    <p>8</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={8}
          value={8}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "9" ? "active" : undefined}>
    <p>9</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={9}
          value={9}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
  <li className={active === "10" ? "active" : undefined}>
    <p>10</p>
    <div className="scaleRatingRadio">
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          name="optradio"
          id={10}
          defaultChecked
          value={10}
          onChange={(e) =>handleChange(e)}
        />
        <label className="form-check-label" htmlFor="radio2">
          &nbsp;
        </label>
      </div>
    </div>
  </li>
</ul>
{errors.ratingError ? <p className="error">{errors.ratingError}</p>:''} </div> ):null}
            </div>
            {incCommentBox ?
           (<div className="allIncludingAdditnalComment mb-3">
              <h5 className="card-title mb-0">{commentDescription} {incCommentMandatory ? '*' : ''}</h5>
              <div className="feedBackQuestionTextarea">
               
                <textarea name = "commentDescription" className="commentTextArea" placeholder="Enter your comment here"
                     id="commentDescription"
                     value = {comment}
                     onChange={(e) => setComment(e.target.value)}
                      >
               </textarea>
               {errors.incCommentMandatory ? <p className="error">{errors.incCommentMandatory}</p>:''}
              </div>
            </div>)
:null}
            <div className="allIncludingAdditnalComment mt-4 text-center">
            <button type="submit" value="Save" className="btnBlue" style={{width:'150px'}}  alt = "submit">Submit</button>
            </div>
          </div> :''}

       
        </div>
       </form>
      </div>
    </div>
  </div>
  {/*End centerMainContainer*/}
</div>

</>
    );
}

export default CustomerFeedback;