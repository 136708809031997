// import { Link, useHistory } from "react-router-dom"
import React, { useState, useEffect } from "react"
import Dropzone from "react-dropzone";
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from "react-router-dom"
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux"
import BaseLayout from "../BaseLayout"
import { getApiCall, postApiCall, getDomainNameByUrl, attachmentAllowFormat } from "../../utils/ticketServices"
import Loader from "../../components/Shared/Loader"
import * as yup from 'yup';
import { firebase } from '../../utils/db'

//import {FROALAEDITORKEY} from '../../../__config/apiSetting'
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
//import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
import Froala from 'froala-editor'

import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/css/plugins/draggable.min.css'

import 'froala-editor/js/third_party/embedly.min.js'
import 'froala-editor/css/third_party/embedly.min.css'
import 'froala-editor/js/third_party/image_tui.min.js'
import 'froala-editor/css/third_party/image_tui.min.css'

import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/css/plugins/emoticons.min.css'

import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/js/plugins/files_manager.min.js'
import 'froala-editor/css/plugins/files_manager.min.css'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/css/plugins/special_characters.min.css'
//import 'froala-editor/js/plugins/spell_checker.min.js' //not available
//import 'froala-editor/css/plugins/spell_checker.min.css' //not available
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/align.min.js'
// const attachmentAllowFormat = [
//     '.doc',
//     '.docx',
//     '.pdf', 
//     '.xls', 
//     '.xlsx',
//     'application/pdf',
//     'image/png',
//     'image/gif',
//     'image/jpg',
//     'image/jpeg',
//     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//     'application/msword',
//     'application/vnd.ms-excel',
//     'text/csv',
//     '.csv',
//     'video/x-flv',
//     'video/mp4',
//     'application/x-mpegURL',
//     'video/MP2T',
//     'video/3gpp',
//     'video/quicktime',
//     'video/x-msvideo',
//     'video/x-ms-wmv'
// ]


/**
 * @param {*} props 
 */
const SubmitTicket = (props) => {
    const history = useHistory()
    const [loaderStatus, setLoaderStatus] = useState(false)
    const [formProcess, setFormProcess] = useState(false)

    const [statusObjList, setStatusObjList] = useState([])
    const [firstname, setfirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [body, setUserContent] = useState('')
    // const [statusID, setStatusId] = useState('')
    const [priorityID, setPriorityId] = useState('')
    const [errorForm, setErrorForm] = useState({})
    const [tickeCreated, setTickeCreated] = useState(false)
    const baseData = useSelector(state => state.baseReducer)
    /**
     * Save on server  on submit button 
     */
    const [tempUploadFile, setTempUploadFile] = useState([])
    const [attachmentList, setAttachmentList] = useState([])
    const [setEditorModel, editorModel] = useState('')

    let ticketFormSchema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string(),
        // age: yup.number().required().positive().integer(),
        email: yup.string().email().required(),
        subject: yup.string().required(),
        body: yup.string().max(1500).required(),
    });

    /**
     *  Save on server  on drop handler 
     */
    // const [uploadedFile, setUploadFile] = useState([]) // AFTER FILE UPLOAD SAVE IN THIS FIELD
    // const [ticketAttachmnetList,setTicketAttachmentList] = useState([]) // WHEN FILE STILL IN PROGRESS

    useEffect(() => {
        const customer = localStorage.getItem('customer');
        if(customer)
        {
            //console.log('in effect',baseData);
            history.push('/create-ticket');
        }
        
    }, [])

    useEffect(() => {
        getStatusData();
    }, [])

    const getStatusData = async () => {
        const domainname = getDomainNameByUrl()
        getApiCall({domainname:domainname}, 'getPriorityData')
            .then((response) => {
                const statuslist = response.results
                //console.log('get status list object', statuslist)
                setStatusObjList(statuslist)
            }).catch((error) => {
                console.log(error)
            })
    }

    const statusOnSelection = (e) => {
        // setStatusId(e.target.value)
        setPriorityId(e.target.value)
    }

    /**
     * @param {Object} files
     */
    const onSelectDrop = (files) => {

        //console.log('upload file for MIME......... ', files)

        if (files.length === 0) {
            // console.log(files.length)
            return;
        }
        // console.log(tempUploadFile)
        // let  uploadFile = [];
        let uploadFile = [...tempUploadFile]

        //console.log(uploadFile)

        files.forEach((file, i) => {
            const ftype = file.type
            const ftypeext =  file.name.split('.').pop();
            // console.log('extention me kya aaya',ftypeext);
            if (attachmentAllowFormat.includes(ftype) || attachmentAllowFormat.includes(ftypeext)) {
                let kfile = file
                uploadFile.push(kfile);
            }
        });
        // console.log('uploadFile',uploadFile);
        let fsize = 0;
        uploadFile.forEach((file, i) => {
            fsize += file.size
        })
        //console.log('size of all file', fsize)
        if (fsize > 15 * 1000 * 1000) {
            //console.log('Files size should not be more than 15 MB')
            toast.error('Files size should not be more than 15 MB') 
        }

        // console.log('---------', uploadFile)
        // console.log('---------beforeupadate', tempUploadFile)
        setTempUploadFile(uploadFile)
        // console.log('afterupdate', tempUploadFile)
    }

    /**
     * Functions hendler on drop started upload to server aferr drop  
     * @param {file} aceptedfile 
     */

    // const onSelectDrop = (fileArray) => {

    //     fileArray.map(file => {
    //         // setUploadFile()
    //         const uniqId = Date.now().toString()
    //         const fileName = file.name;
    //         const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    //         const newFileName = `${uniqId}_${fileName}.${extension}`;
    //         let arrayanc = ticketAttachmnetList

    //         // ticketAttachmnetList,setTicketAttachmentList
    //         arrayanc.push({ name: fileName ,newFileName: newFileName, uploadStatus : 0})
    //         setTicketAttachmentList(arrayanc)

    //         // console.log(file)
    //         // formData.append(`file`, file)
    //         const storageRef = firebase.storage()
    //         // const customMetadata = {  }
    //         var myCustomMetadata = {
    //             customMetadata: {
    //                 'originalfilename': fileName
    //             }
    //         }

    //         // const org_id = tokenDecodedata.org_id
    //         const org_id = 'abc2'
    //         // const return storageRef.ref(`attachment/${org_id}/${newFileName}`).put(file, myCustomMetadata)
    //         const uploadTask = storageRef.ref(`attachment2/${org_id}/${newFileName}`).put(file, myCustomMetadata)
    //         uploadTask.on('state_changed', (snapshot) => {
    //             // Observe state change events such as progress, pause, and resume
    //             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //             var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //             // console.log('Upload is ' + progress + '% done');

    //             let updateProgressList = ticketAttachmnetList
    //             console.log('uploaded before', updateProgressList);
    //             console.log('innner templete fileName...',fileName)
    //             //find the index of object from array that you want to update
    //             const objIndex = updateProgressList.findIndex(obj => obj.name === fileName);

    //             console.log(objIndex,'objIndex')
    //             // make new object of updated object.   
    //             const updatedObj = { ...updateProgressList[objIndex], uploadStatus: progress};

    //             // make final new array of objects by combining updated object.
    //             const updatedProjects = [
    //             ...updateProgressList.slice(0, objIndex),
    //             updatedObj,
    //             ...updateProgressList.slice(objIndex + 1),
    //             ];

    //             console.log('updatedProjects after',updatedProjects)
    //             setTicketAttachmentList(updatedProjects) 

    //             switch (snapshot.state) {
    //                 case firebase.storage.TaskState.PAUSED: // or 'paused'
    //                     console.log('Upload is paused');
    //                     break;
    //                 case firebase.storage.TaskState.RUNNING: // or 'running'
    //                     console.log('Upload is running');
    //                     break;
    //             }
    //         }, (error) => {
    //             // Handle unsuccessful uploads
    //         }, () => {
    //             // Handle successful uploads on complete
    //             // For instance, get the download URL: https://firebasestorage.googleapis.com/...

    //             let metadataFile = uploadTask.snapshot.metadata

    //             uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //                 console.log('File available at', downloadURL);
    //                 const fileData = {
    //                     filename: fileName,
    //                     metadata: metadataFile,
    //                     newfilename: newFileName,
    //                     path: downloadURL
    //                 }

    //                 let newcollection = uploadedFile
    //                 newcollection.push(fileData)
    //                 console.log('newcollection', newcollection)
    //                 let clarray = newcollection.map(row => {
    //                     return row
    //                 })

    //                 // update file list
    //                 setUploadFile(clarray)
    //                 setAttachmentList(clarray)

    //             });
    //         });
    //     })
    // }



    // const updateveribleData = (newcollection) => {
    //     let clarray = newcollection.map(row => {
    //         return row
    //     })
    //     console.log('yha aaya ye ', clarray);
    //     setUploadFile(clarray)
    // }

    /**
     *  
     * Upload File On server when click submit button 
     */
    const uploadFileTOServer = async () => {

        //console.log('uploading is now start .....')
        // const { uploadFile, uploadedFileURL } = this.state
        let alliploadFile = [];
        let uploadedFileURL = attachmentList
        if (!checkFileSizeValidaion()) {
            return false
        }

        // const tokenDecodedata = getTokenData();        
        alliploadFile = tempUploadFile.map(async (file, i) => {
            // req.attach(file.name, file);
            //const formData = new FormData()
            const uniqId = Date.now().toString()
            const fileName = file.name;
            const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
            const newFileName = `${uniqId}_${fileName}.${extension}`;
            // console.log(file)
            // formData.append(`file`, file)
            const storageRef = firebase.storage()
            // const customMetadata = {  }
            var myCustomMetadata = {
                customMetadata: {
                    'originalfilename': fileName
                }
            }

            // const org_id = tokenDecodedata.org_id
            const org_id = baseData?.settings?.usersettings?.domainname || 'abc2'
            return storageRef.ref(`attachment2/${org_id}/${newFileName}`).put(file, myCustomMetadata)
            // return Model.postImage(formData)
            // .then(res => {
            //     return res
            //     // console.log(res)
            //     // uploadedFileURL.push(res.data.results.filepathPublic)
            //     // this.setState({uploadedFileURL:uploadedFileURL})
            // }).catch(err => {
            //     console.error(err);
            // })
        });

        let reBoolean = await Promise.all(alliploadFile).then(async fileData => {
            let retundata = fileData.map(async row => {
                // console.log(row);
                let url = await row.ref.getDownloadURL()
                const attachedFileMetadata = {
                    filename: row.metadata.customMetadata.originalfilename,
                    metadata: row.metadata,
                    path: url
                }
                return attachedFileMetadata
                // uploadedFileURL.push(attachedFileMetadata)
            })


            return await Promise.all(retundata).then(fileArray => {
                fileArray.map(singleRow => {
                    //console.log('singlefileURL', singleRow)
                    uploadedFileURL.push(singleRow)
                })

                // console.log('uploadedFileURlData');
                // console.log(uploadedFileURL);
                setAttachmentList(uploadedFileURL)
                return true
            }).catch(err => {
                console.log(err);
            })

        }).catch(err => {
            console.log('upload error');
            console.log(err);
            return false
        })
        return reBoolean
    }

    /** 
     * Check File Size Validation -
     */
    const checkFileSizeValidaion = () => {
        let fsize = 0;
        tempUploadFile.forEach((file, i) => {
            fsize += file.size
        })

        if (fsize > 15 * 1000 * 1000) {
            //console.log('Files size should not be more than 15 MB');
            toast.error('Files size should not be more than 15 MB');
            return false;
        }
        return true;
    }

    /**
     * form submit handler
     * @param {*} e 
     */
    const submitForm = async (e) => {
        e.preventDefault();
        let dataObject = {
            firstname,
            lastname,
            email,
            subject,
            body,
            domainname: baseData?.settings?.usersettings?.domainname || 'zyx',
            // status_id: statusID,
            priority_id: priorityID,
            // attachment: uploadedFile // when upload file on server on drop event 
        }

        //console.log('data to validate', dataObject);
        setFormProcess(true);
        ticketFormSchema.validate(dataObject, { abortEarly: false })
            .then(async (valid) => {
                //console.log(valid)

                setLoaderStatus(true);
                if (tempUploadFile?.length > 0) {
                    const response = await uploadFileTOServer();
                    if(!response){
                        setLoaderStatus(false);
                        setFormProcess(false);
                        return false;
                    }
                    dataObject['attachment'] = attachmentList
                }
                //console.log('dataObject', dataObject);
                // now data id valid post to create a new ticket 
                postApiCall(dataObject, 'createNewTicket')
                    .then((response) => {
                        //console.log(response);
                        setLoaderStatus(false);
                        setTickeCreated(true);
                        setFormProcess(false);
                    }).catch((error) => {
                        setFormProcess(false);
                        console.log(error)
                    })
            }).catch((error) => {
                console.log(error)
                let customeError = {}
                // loop and set error
                error.inner.map(errorRow => {
                    customeError[errorRow.path] = errorRow.message
                })
                setErrorForm(customeError)
                setFormProcess(false);
            })
    }

    const handleModelChange = (text) => {
        console.log(text)
        // setEditorModel(text)
        // setUserContent(text)
        //setFormReplyData(text)
    }
    return (

        <BaseLayout>
            { loaderStatus && <Loader />}

            {/* <Loader /> */}

            {/* {console.log('basedata..... ',  baseData?.settings?.usersettings?.domainname)} */}

            <div className="mainFullWidth">
                <div className="forms-section ticket-section box-section my-3">
                    <div className="container">

                    {/* <div className="breadcrumb-customes tickting-bredcumb mb-2">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">My Area</li>
                                                <li className="breadcrumb-item"><a href="#">Subject Line of Ticket</a></li>
                                            </ol>
                                        </nav>
                                    </div> */}
                                    
                        { !tickeCreated ?
                        <div className="ticket-validation">
                            <p className="card-body-text mb-3">Fields marked with an asterisk (*) are mandatory to be filled.</p>
                        </div> : '' }

                        <div className="row">

                        { !tickeCreated ?

                            (<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                <div className="sumbit-ticket">
                                    <div className="ticket-form-box">
                                        <h4 className="card-title mt-2 mb-4">Submit Ticket</h4>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <div className="md-form md-form-custom my-0">
                                                {/* <input type="text" id="inputMDEx1" className="form-control" /> */}
                                                <input id="firstnameIDMax" className={errorForm?.firstname ? 'form-control in-valid' : 'form-control'} type="text" name="firstname" 
                                                    
                                                    maxLength="55"
                                                    onChange={(e) => {

                                                        delete errorForm?.firstname
                                                        setErrorForm(errorForm)
                                                        setfirstname(e.target.value)
                                                    }}
                                                />

                                                <label htmlFor="firstnameIDMax">First name* </label>
                                                <p className="errorText">{errorForm?.firstname ? "First name is required" : ''}</p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <div className="md-form md-form-custom my-0">
                                                {/* <input type="text" id="inputMDEx2" className="form-control" /> */}

                                                <input id="inputMDEx2" className='form-control' type="text" name="lastname" maxLength="55" 
                                                    onChange={(e) => {
                                                        setLastname(e.target.value)
                                                    }}
                                                />
                                                <label htmlFor="inputMDEx2">Last name</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="md-form md-form-custom my-0">
                                                {/* <input type="text" id="inputMDEx3" className="form-control" /> */}
                                                <input id="inputEmailMDEx3" className={errorForm?.email ? 'form-control in-valid' : 'form-control'} type="text" name="email"
                                                    maxLength="55" 
                                                    onChange={(e) => {
                                                        delete errorForm?.email
                                                        setErrorForm(errorForm)
                                                        setEmail(e.target.value)
                                                    }}
                                                />
                                                <label htmlFor="inputEmailMDEx3">Email* </label>
                                                <p className="errorText">{errorForm?.email ? "Please enter a valid email" : '' }</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="md-form md-form-custom my-0">
                                                {/* <input type="text" id="inputSubjectMDEx3" className="form-control" /> */}
                                                <input id="inputSubjectMDEx3" className={errorForm?.subject ? 'form-control in-valid' : 'form-control'} type="text" name="subject" 
                                                    maxLength="255" 
                                                    onChange={(e) => {
                                                        delete errorForm?.subject
                                                        setErrorForm(errorForm)
                                                        setSubject(e.target.value)
                                                    }}
                                                />
                                                <label htmlFor="inputSubjectMDEx3">Subject* </label>
                                                <p className="errorText">{errorForm?.subject ? "Subject is required" : '' }</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="md-form md-form-custom my-0">

                                            <div className="textAreaEdit">
                                                {/* <Editor
                                                    initialValue={''}
                                                    // apiKey='kpqm9cs87bavvn1yu7fqlj5lcmsmw4zh9h21b9pjdl3w977m'
                                                    apiKey='qhr0m0gv8rkrme6g9ar4pd20kz6mqy88n5dh3522tn00v5k9'
                                                    init={{
                                                        placeholder: 'Enter description*',
                                                        height: 200,
                                                        menubar: false,
                                                        skin: 'naked',
                                                        icons: 'small',
                                                        content_style: "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; font-weight:400; font-size:14px}",
                                                        branding: false,
                                                        toolbar_location: 'top',
                                                        menubar: false,
                                                        statusbar: false,
                                                        plugins: [
                                                            'advlist autolink lists image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar:
                                                            'undo redo | bold italic | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist | removeformat ' ,
                                                    }}
                                                    onEditorChange={(content, editor) => {
                                                        // this.handleEditorChange
                                                        delete errorForm?.body
                                                        setErrorForm(errorForm)
                                                        setUserContent(content)
                                                    }}
                                                    
                                                    value={`${body}`}
                                                /> */}

                                <FroalaEditor
                                    // ref={(el) => {  
                                    //     quillObj = el;  
                                    //     }} 
                                        tag='textarea'
                                        config={{
                                            placeholderText: 'Enter description*',
                                            //charCounterCount: false,
                                            heightMin: 200,
                                            heightMax: 250,
                                            fontSizeUnit: 'pt',
                                            fileDropUpload:false,
                                            attribution: false,
                                            key: `${process.env.REACT_APP_EDITOR_FROALAKEY}`,
                                            imageMultipleStyles: true,
                                            autofocus: true,
                                            direction: 'ltr',
                                            wordPasteModal:true,
                                            imagePaste:false,
                                            pasteImage: false,
                                            //wordPasteKeepFormatting:true,
                                            enter: FroalaEditor.ENTER_DIV,
                                            //fontSizeSelection: true,
                                            //pasteAllowedStyleProps: ['font-family', 'font-size', 'color'],
                                            //wordAllowedStyleProps: ['font-family', 'font-size', 'background', 'color', 'width', 'text-align', 'vertical-align', 'background-color', 'padding', 'margin', 'height', 'margin-top', 'margin-left', 'margin-right', 'margin-bottom', 'text-decoration', 'font-weight', 'font-style', 'text-indent', 'border', 'border-.*'],
                                            fontSize:['8', '9', '10', '11', '12', '14','16', '18','20','22', '24', '30', '36', '48', '60', '72', '96'],
                                            //pastePlain: true,
                                            //documentReady: true,
                                            colorsBackground: ["#000000", "#444444", "#666666", "#999999", "#CCCCCC", "#EEEEEE", '#F3F3F3', '#FFFFFF',
                                            "#FF0000", "#FF9900", "#FFFF00", "#00FF00", "#00FFFF", "#0000FF", "#9900FF", "#FF00FF",
                                            "#F4CCCC", "#FCE5CD", "#FFF2CC", "#D9EAD3", "#D0E0E3", "#CFE2F3", "#D9D2E9", "#EAD1DC",
                                            "#EA9999", "#F9CB9C", "#FFE599", "#B6D7A8", "#A2C4C9", "#9FC5E8", "#B4A7D6", "#D5A6BD",
                                            "#E06666", "#F6B26B", "#FFD966", "#93C47D", "#76A5AF", "#6FA8DC", "#8E7CC3", "#C27BA0",
                                            "#CC0000", "#E69138", "#F1C232", "#6AA84F", "#45818E", "#3D85C6", "#674EA7", "#A64D79",
                                            "#990000", "#B45F06", "#BF9000", "#38761D", "#134F5C", "#0B5394", "#351C75", "#741B47",
                                            "#660000", "#783F04", "#7F6000", "#274E13", "#0C343D", "#073763", "#20124D", "#4C1130"],
                                             colorsStep: 8,
                                            colorsText: ["#000000", "#444444", "#666666", "#999999", "#CCCCCC", "#EEEEEE", '#F3F3F3', '#FFFFFF',
                                            "#FF0000", "#FF9900", "#FFFF00", "#00FF00", "#00FFFF", "#0000FF", "#9900FF", "#FF00FF",
                                            "#F4CCCC", "#FCE5CD", "#FFF2CC", "#D9EAD3", "#D0E0E3", "#CFE2F3", "#D9D2E9", "#EAD1DC",
                                            "#EA9999", "#F9CB9C", "#FFE599", "#B6D7A8", "#A2C4C9", "#9FC5E8", "#B4A7D6", "#D5A6BD",
                                            "#E06666", "#F6B26B", "#FFD966", "#93C47D", "#76A5AF", "#6FA8DC", "#8E7CC3", "#C27BA0",
                                            "#CC0000", "#E69138", "#F1C232", "#6AA84F", "#45818E", "#3D85C6", "#674EA7", "#A64D79",
                                            "#990000", "#B45F06", "#BF9000", "#38761D", "#134F5C", "#0B5394", "#351C75", "#741B47",
                                            "#660000", "#783F04", "#7F6000", "#274E13", "#0C343D", "#073763", "#20124D", "#4C1130"],
                                            colorsHEXInput: false,
                                            lineHeights: {
                                                '1.15': '1.15',
                                                '1.5': '1.5',
                                                Double: '2',
                                                '2.5':'2.5'
                                              },
                                            toolbarButtons: {
                                                moreText: {
                                                buttons: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikeThrough",
                                                    //"subscript",
                                                    //"superscript",
                                                    "fontFamily",
                                                    "fontSize",
                                                    "textColor",
                                                    "backgroundColor",
                                                    "inlineClass",
                                                    "inlineStyle",
                                                    "clearFormatting",
                                                    
                                                    
                                                    
                                                ],
                                                //align: "right",
                                               // buttonsVisible: 11
                                                },
                                                moreParagraph: {
                                                    buttons: [
                                                        'align',
                                                    // "alignLeft",
                                                    // "alignCenter",
                                                    // //"formatOLSimple",
                                                    // "alignRight",
                                                    // "alignJustify",
                                                    "formatOL",
                                                    "formatUL",
                                                    "paragraphFormat",
                                                    "paragraphStyle",
                                                    //"lineHeight",
                                                    //"outdent",
                                                    //"indent",
                                                    //"quote",
                                                    
                                                    ]
                                                },
                                                moreMisc: {
                                                    buttons: [
                                                    "undo",
                                                    "redo",
                                                   
                                                    ],
                                                    align: "left",
                                                    buttonsVisible: 2
                                                },
                                               
                                            },
                                            
                                        pluginsEnabled: [
                                            //"table",
                                            "fontFamily",
                                            "fontSize",
                                            "spell",
                                            //"quote",
                                            "save",
                                            //"quickInsert",
                                            // "paragraphFormat",
                                            // "paragraphStyle",
                                            "help",
                                            "draggable",
                                            "align",
                                            //"link",
                                            "lists",
                                            //"file",
                                            //"image",
                                            "emoticons",
                                            "url",
                                            "video",
                                            "embedly",
                                            "colors",
                                            "entities",
                                            //"inlineClass",
                                            //"inlineStyle",
                                            // 'codeBeautif '
                                            // 'spellChecker',
                                            //"imageTUI",
                                            "lineHeight",
                                            //"insertFiles"
                                            "wordPaste",
                                            "image"
                                        ],

                                        //const self = this;

                                        events: {
                                            
                                            'image.beforeUpload': function (currentFileList, imgEl) {
                                                // File was uploaded to the server.
                                                console.log(currentFileList, imgEl,"e, editor, images")
                                                imgEl[0].remove();
                                                return false;
                                            },
                                           
                                            }
                                    
                                        }}
                                        //model={this.state.model}
                                        model={body}
                                        //onModelChange={this.handleModelChange}
                                        //onModelChange={handleModelChange}
                                        onModelChange={(content, editor) => {
                                            // this.handleEditorChange
                                            delete errorForm?.body
                                            setErrorForm(errorForm)
                                            setUserContent(content)
                                        }}
                                        
                                        />   

                                            </div>

                                            <p className="errorText">{errorForm?.body ? "Content is required and should be max 1500 characters." : ''} </p>
                                            
                                            </div>
                                            <p className="card-body-text text-dark-gray"><strong>Note:</strong> Please enter the details of your ticket so that our support team can respond to you at the earliest.</p>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="attchement-section md-form-custom mt-0 mb-3">
                                                <h5 className="card-title">Attachements <span className="text-dark-gray">(Optional)</span></h5>
                                                <div className="attachement-file cursor-pointer">
                                                    <div className="attchment-inner">

                                                    {/* accept={attachmentAllowFormat.toString()} */}

                                                    <Dropzone onDrop={acceptedFiles => onSelectDrop(acceptedFiles)} accept={attachmentAllowFormat.toString()} >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <span>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width={28} height={59} viewBox="0 0 28 59">
                                                                            <text id="_" data-name="+" transform="translate(14 47)" fill="gray" fontSize={51} fontFamily="Roboto-Bold, Roboto" fontWeight={700} opacity="0.2"><tspan x="-13.908" y={0}>+</tspan></text>
                                                                        </svg>
                                                                    </span>
                                                                    <p className="card-body-text">Drag &amp; Drop or <span className="text-light-blue cursor-pointer"><u>Click</u></span> to upload a file</p>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>

                                                        {/* <input type="file" />
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={59} viewBox="0 0 28 59">
                                                                <text id="_" data-name="+" transform="translate(14 47)" fill="gray" fontSize={51} fontFamily="Roboto-Bold, Roboto" fontWeight={700} opacity="0.2"><tspan x="-13.908" y={0}>+</tspan></text>
                                                            </svg>
                                                        </span>
                                                        <p className="card-body-text">Drag &amp; Drop or <span className="text-light-blue cursor-pointer"><u>Click</u></span> to upload a file</p>
                                                    */}
                                                   
                                                   
                                                    </div>
                                                </div>


                                                <div className="attched-file-section">
                                                        {
                                                          tempUploadFile.map((file, index) => {
                                                                return (<div className="attched-file" key={file.name}>
                                                                            <div className="attched-icon" title={file.name}>
                                                                                <img src="images/pdf.svg" />
                                                                            </div>
                                                                            <span className="close-file" onClick={(e) => {
                                                                                    // removeImageFromArray(index)
                                                                                    // console.log('filename',file.name);
                                                                                    let refinefileList = tempUploadFile.filter(row => row.name != file.name)
                                                                                    setTempUploadFile(refinefileList);

                                                                                }} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                                                                    <g id="Group_2906" data-name="Group 2906" transform="translate(-87 -267)">
                                                                                        <circle id="Ellipse_127" data-name="Ellipse 127" cx={9} cy={9} r={9} transform="translate(87 267)" fill="#9f9f9f" />
                                                                                        <path id="Path_2071" data-name="Path 2071" d="M-134.477,555.841h9" transform="translate(225.977 -279.341)" fill="none" stroke="#fff" strokeWidth={2} />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                               )
                                                            })
                                                        }
                                                        </div>


                                                {/* {(<div className="attched-file-section">
                                                        {
                                                            tempUploadFile.map((file, index) => {
                                                                return <div key={file.name} className="file-att-selct">
                                                                    <div className="file-attached-Name">
                                                                        <div className="file-name-attched-files">
                                                                            <span>
                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-paperclip" fillRule="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd" d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                                                                </svg>
                                                                            </span>
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="closeBuoon pull-right" onClick={(e) => {
                                                                            // removeImageFromArray(index)
                                                                            // console.log('filename',file.name);
                                                                            let refinefileList = tempUploadFile.filter(row => row.name != file.name)
                                                                            setTempUploadFile(refinefileList);

                                                                        }}><span className="circlebtn"><svg xmlns="http://www.w3.org/2000/svg" width="14.294" height="14.294" viewBox="0 0 14.294 14.294"><path id="ic_clear_24px" d="M19.294,6.44,17.855,5l-5.707,5.707L6.44,5,5,6.44l5.707,5.707L5,17.855l1.44,1.44,5.707-5.707,5.707,5.707,1.44-1.44-5.707-5.707Z" transform="translate(-5 -5)" fill="#bebebe"></path></svg></span></div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>)
                                                    } */}

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mb-3">
                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                            <div className="md-form md-form-custom my-0">
                                                <select className="customArrow" onChange={statusOnSelection} >
                                                    <option>None</option>
                                                    
                                                    {
                                                        statusObjList.map(row => {
                                                            return (<option key={row.priority_id} value={row.priority_id} >{row.name}</option>)
                                                        })
                                                    }
                                                </select>
                                                <label htmlFor="inputMDEx5" className="active">Priority </label>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                {
                                    formProcess ?
                                        (<div className="button-form-section mt-3 mt-xs-cus-4">
                                            <button className="info-btn form-btn px-4 py-2" >Submit</button>                                        
                                            <button className="blank-btn form-btn px-3 py-2">Cancel</button>
                                        </div>)
                                        : (
                                            <div className="button-form-section mt-3 mt-xs-cus-4">
                                                <button className="info-btn form-btn px-4 py-2" onClick={submitForm} > Submit</button>
                                                
                                                <button className="blank-btn form-btn px-3 py-2"
                                                    onClick={() => { 
                                                        history.push('/')
                                                    }}
                                                >Cancel</button>
                                            </div>  
                                        )
                                     }


                            </div>)

                               :                      


                            (<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                <div className="congratulation-page mt-5 pt-5 mt-xs-cus-0 pt-xs-cus-0">
                                    <h2 className="card-title mb-0">Congratulations!!!</h2>
                                    <p className="text-mutede text-dark-gray">Your ticket has been successfully submitted.</p>
                                    <p className="text-mutede text-dark-gray mt-4">An e-mail has been sent to you for your reference.</p>
                                    <p className="text-mutede text-dark-gray">You can sign in or sign up to view your tickets and actions taken on your ticket.</p>
                                    <p className="text-mutede mt-4">
                                        <span style={{cursor: 'pointer'}} className="text-light-blue"
                                            onClick={() => { 
                                                history.push('/')
                                            }}
                                            >Go to Home
                                        </span>
                                    </p>
                                </div>
                            </div>)

                            }


                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div className="notification-ticket mt-xs-cus-5">
                                    <div className="ticket-not-box">
                                        <div className="icon">
                                            <img src="/images/icon/002-log-in.svg" />
                                        </div>
                                        <div className="icon-aside">
                                            <h5 className="card-title mb-1">Already a member? <span className="text-light-blue cursor-pointer" onClick={()=>{ history.push('/login') }}>Sign In</span></h5>
                                            <p className="text-mutede text-dark-gray">Sign in to view your tickets, engage in community topics and our articles to stay informed.</p>
                                        </div>
                                    </div>
                                    <div className="ticket-not-box">
                                        <div className="icon">
                                            <img src="/images/icon/001-profile-user.svg" />
                                        </div>
                                        <div className="icon-aside">
                                            <h5 className="card-title mb-1">New to “{baseData?.settings?.usersettings?.portalname}”? <span className="text-light-blue cursor-pointer" onClick={()=>{ history.push('/signup') }} >Sign Up</span></h5>
                                            <p className="text-mutede text-dark-gray">Create an account to submit tickets, read articles and engage in our community.</p>
                                        </div>
                                    </div>
                                    <div className="ticket-not-box">
                                        <div className="icon">
                                            <img src="/images/icon/forgot-pass.svg" />
                                        </div>
                                        <div className="icon-aside">
                                            <h5 className="card-title mb-1">Forgot Password? <span className="text-light-blue cursor-pointer" onClick={()=>{ history.push('/forgot-password/user') }} >Reset</span></h5> 
                                            {/* forgot-password/user */}
                                            <p className="text-mutede text-dark-gray">We will send a password reset link to your email address.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </BaseLayout>
    )

}

export default SubmitTicket