import React,{useEffect,useState} from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import BaseLayout from "../BaseLayout"
import { useHistory } from "react-router-dom"
import {useSelector,useDispatch} from "react-redux"
import {setForgotPasswordSuccessPage,forgotPasswordVerifyCodeRequest,resetErrors, resendOtpFailure} from "../BaseLayout/actions";
import { confirmResetPassword,confirmResetPasswordAdmin, forgetpassword, forgetOwnerPassword } from "../../utils/services"
import { toast } from 'react-toastify';
import Loader from "../../components/Shared/Loader"
const Index = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();
    const baseData = useSelector(state => state.baseReducer)
    const getEmail = useSelector(state => state);
    const [loading, setLoading] = useState(false)
    if (baseData.loadForgotPasswordSuccessPage) {
        history.push(`/forgot-password-success`)
        dispatch(setForgotPasswordSuccessPage(false));
    }
    //console.log(props.match.params,"props data")
   
    useEffect(() => {
        dispatch(resetErrors())
    },[])

    // let resendOtp  = (async()=>{
    //     setLoading(true)
    //     let login_type=props.match.params.type;
    //     let email=atob(props.match.params.id);
    //     let domainname = baseData.settings.usersettings.domainname;

    //     var forget = await forgetpassword(domainname,email,login_type);
    //     console.log(forget.status);
    //     if(forget.status == 200){
    //         setLoading(false)
    //         toast.success(forget.message);
    //     }
    //     else{
    //         setLoading(false)
    //         toast.error(forget.message);
    //     }
    // })

    return (
        <BaseLayout>
        { loading && <Loader />}
        {/* { loading ? <div className="shadow">
            <Loader /> 
            </div>: null} */}
        <Formik
            initialValues={{
                password: '',
                confirmPassword: '',
                verify: '',
            }}
            validationSchema={Yup.object().shape({
                verify: Yup.string()
                    .required('Verification Code is required')
                    .matches(/^[0-9]{6,}$/, { message: "Must Contain 6 Numeric Characters" }),
                password: Yup.string()
                    .required('Password is required')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_\$%\^&\*\-])(?=.{8,})/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    ),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required'),
            })}
            onSubmit={async fields => {
                setLoading(true)
                //console.log(atob(props.match.params.id),"getEmail")
                let login_type=props.match.params.type

                //Call api for Password Change
                if(login_type == 'admin'){
                    let forget = await confirmResetPasswordAdmin(baseData.settings.usersettings.domainname,atob(props.match.params.id),fields.password,fields.verify,login_type);
                    if(forget.status == 200){
                        toast.success(forget.message);
                        //history.push(`/confirm-password`)
                        setLoading(false)
                        setTimeout(()=>{
                            history.push(`/forgot-password-success/${btoa(props.match.params.id)}/${login_type}`)
                        },3000)

                    }
                    else{
                        setLoading(false)
                        toast.error(forget.message);
                    }
                }
                else{
                    let forget = await confirmResetPassword(baseData.settings.usersettings.domainname,atob(props.match.params.id),fields.password,fields.verify,login_type);
                    //console.log(forget.status);
                
                    if(forget.status == 200){
                        //console.log('ashu');
                        toast.success(forget.message);
                        //history.push(`/confirm-password`)
                        setLoading(false)
                        // if(login_type == "customer"){
                        //     history.push(`/login`)
                        // }
                        // else{
                        //     history.push(`/agent-login`)
                        // }
                        history.push(`/forgot-password-success/${btoa(props.match.params.id)}/${login_type}`)
                        
                    }
                    else{
                        setLoading(false)
                        toast.error(forget.message);
                    }
                }
                

                //dispatch(forgotPasswordVerifyCodeRequest({ email: atob(props.match.params.id),otp: fields.verify, org_id: baseData.settings.usersettings.userId,password: fields.password,portalName:baseData.settings.usersettings.portalname,url:`https://${window.location.hostname.split('.')[0]}.appypiedesk.com` }))
            }}
        >
            {({ errors, status, touched }) => (
                <Form>
                    <div className="main-tickting-frontend frontends tickting-login-screen">
                        <div className="container">
                            <div className="login-centerd">
                                <div className="login-frontend">
                                     <div className="lock-secure text-right pb-3">
                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                        <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                        </svg>
                                        <span>Secure Area</span></p>
                                    </div>
                                    <div className="login-box">
                                        
                                        <div className="headings-login-signup">
                                            <h4 className="card-title">Create your new password don't Worry !!!</h4>
                                            <p className="text-mutede">Please enter the verification code that we sent you on your provided mail to continue.</p>
                                        </div>
                                        <div className="feilds-new">

                                            <div className="user-feilds">
                                            <div className="md-form md-form-custom">
                                                <Field name="verify" id="input-char-counter1"  maxLength="6" type="text" className={'form-control' + (errors.verify && touched.verify ? ' is-invalid' : '')} />
                                                <label htmlFor="input-char-counter1">Enter Verification Code*</label>
                                                <ErrorMessage name="verify" component="div" className="invalid-feedback" />
                                            </div>
                                            </div>


                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="password" id="input-char-counter11" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter11">Enter New Password*</label>
                                                </div>
                                            </div>

                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="confirmPassword" id="input-char-counter12" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter12">Confirm New Password*</label>
                                                </div>
                                            </div>

                                            {/* <div className="user-feilds">
                                                <div className="login-signup-btn k-flex align-items-center">
                                                    <span onClick={()=>resendOtp()}>Resend Verification Code</span>
                                                </div>

                                            </div> */}
                                            <p>
                                                <span className="cursor-pointer text-light-blue card-body-text text-left"
                                                onClick={ async() => {
                                                    setLoading(true)
                                                    let login_type=props.match.params.type;
                                                    let email=atob(props.match.params.id);
                                                    let domainname = baseData.settings.usersettings.domainname;
                                            
                                                    if(login_type !=="admin"){
                                                        var forget = await forgetpassword(domainname,email,login_type);
                                                        //console.log(forget.status);
                                                        if(forget.status == 200){
                                                            setLoading(false)
                                                            toast.success(forget.message);
                                                        }
                                                        else{
                                                            setLoading(false)
                                                            toast.error(forget.message);
                                                        }
                                                    }
                                                    else{
                                                        let forgetOwnerPass = await forgetOwnerPassword(domainname,email);
                                                        //console.log(forgetOwnerPass,"forgetOwnerPass")
                                                        if(forgetOwnerPass.status == 200){ 
                                                            setLoading(false)
                                                            toast.success(forgetOwnerPass.message);
                                                            //console.log(email,login_type)
                                                        }
                                                        else{
                                                            setLoading(false)
                                                            toast.error(forgetOwnerPass.message);
                                                        }
                                                    }
                                                    
                                                }}
                                                >Resend Verification Code</span>
                                                </p>

                                            <div className="user-feilds">
                                                <div className="login-signup-btn k-flex align-items-center">
                                                    <button disabled={loading} type="submit">Continue</button>
                                                </div>

                                            </div>

                                        </div>
                                        {baseData.forgotPasswordVerifyOtpError?
                                            <p className="errorText">{baseData.forgotPasswordVerifyOtpError}</p>
                                            : null
                                        }
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
        </BaseLayout>
    );
}

export default Index;