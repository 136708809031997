import React,{useState, useEffect} from 'react';
import { useLocation,useHistory } from "react-router-dom";
import {firebase} from '../../utils/db'

const Success = (props) => {   

    const location = useLocation();
    const [thankyou, setThankyou ]= useState('');
    const history = useHistory();



useEffect(async() => {
if(!location.state?.orgId){
    history.push('/404')
}else{
    setThankyou(location.state.detail)

    const feedbackData = {
      "ticket_id" : props.location.state.ticketId,
      "events" : {
        "click_link" : true,
        "submit_button_click" : true,
        "form_submit" : true
      } 
    }

await firebase.firestore().collection("SupportRootCollections").doc(`${props.location.state.orgId}`).collection("customer_feedback_logs").doc(`${props.location.state.logID}`).update(feedbackData)
}

  
},[location])



    return (
        <>
      
        <div className="container main-container">
       
  <div className="centerMainContainer">
    <div className="row mt-4">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div className="customerSelctSurbay bg-white">
      <div className="text-center px-5 py-5">
      {thankyou ? thankyou :'Your feedback is submitted successfully'}</div>
      </div>
      </div>
    </div>
  </div>
  {/*End centerMainContainer*/}
</div>

</>
    );
}

export default Success;