import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
// import InfiniteScroll from 'react-infinite-scroller';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useHistory } from "react-router-dom"

import * as Yup from 'yup';
import * as Services from "../../utils/services";
import { toast } from 'react-toastify';
import BaseLayout from "../BaseLayout"
import Loader from "../../components/Shared/Loader"
const jwt = require('jsonwebtoken')


const AgentVerify = (prop) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const baseData = useSelector(state => state.baseReducer)
    //console.log(baseData,"href")
    const [loading, setLoading] = useState(false)
    const [isLinkExpire, setLinkExpire] = useState(false)
    const settings = baseData.settings.usersettings;
    
    useEffect( () => {
        //const res = await Services.setSignUpPassword(fields,window.location.href,"agent" )
        async function fetchCheckUrlAPI() {
            let res= await Services.isUserAndUrlVerify(window.location.href,"agent")
            //let res= await getArticleCreator({user_id:'92104e73-c2b5-4d9d-b449-fcd56abeed9e'})
            //console.log(res,"creator")
            if(res.exist){
                setTimeout(()=>{
                    history.push(`/agent-login`)
                })
                toast.success(res.message);    
            }
          }
      
          fetchCheckUrlAPI()

    }, []);

    const clearAll =() =>{
        //console.log("clear all")
        //deleteDbInIndex();
        Services.logout();
        // dispatch(signoutUser())
        // dispatch(updateVoteColor({ upvote: false, downvote: false }))
        // dispatch(showSuccessToast('Logged Out Successfully.'))
    }

    return (

        <BaseLayout>
{(!localStorage.getItem('user'))?
      <div className="main-wrapper" id="main-wrapper">

        <div>
          <div className="container-fluid main-container">
            <div className="centerMainContainer">

            { loading &&<Loader /> }
            {/* { loading ? <div className="shadow">
            <Loader /> 
            </div>: null} */}

            <Formik
                enableReinitialize
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    isLinkExpire:isLinkExpire
                }}
            
            validationSchema={Yup.object().shape({
                // isLinkExpire: Yup.boolean(),
                // password: Yup.string().when('isLinkExpire', {
                //     is: false,
                //     then: Yup.string().required('Password is required')
                //     .matches(
                //         /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                //         "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                //     ),
                //   }),
                    
                // confirmPassword: Yup.string().when('isLinkExpire', {
                //     is: false,
                //     then: Yup.string().required('Password is required')
                //     .oneOf([Yup.ref('password'), null], 'Passwords must match')
                //     .required('Confirm Password is required'),
                //   }),

                  password: Yup.string()
                    .required('Password is required')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_\$%\^&\*\-])(?=.{8,})/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    ),
       
                confirmPassword: Yup.string()
                .required('Password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm Password is required'),
                  
            })}
            onSubmit={async fields => {
                setLoading(true)                
                //if(!isLinkExpire){
                    const res = await Services.setSignUpPassword(fields,window.location.href,"agent" )
                    console.log(res,"res")
                    if(res.status == 200){
                        //setLoading(false)                        
                        //history.push(`/agent-login`)
                        if(!res.exist){
                            let location=window.location.href
                            let agentData=jwt.decode(location.split('?q=')[1])
                            var loginagent = await Services.agentlogin(settings.companyidentifier, agentData.user.email, fields.password, "agent");
                            if(loginagent.status_msg == 200 || loginagent.status == 200){
                                var userdata = JSON.parse(loginagent.userdata);
                                await Services.activityLog("Login by Common Login")

                                //var owndbagent = await Services.agentowndblogin(userdata.idToken.payload.sub, userdata.idToken.payload.email);
                                var owndbagent = await Services.agentowndblogin(userdata.idToken.payload.preferred_username, userdata.idToken.payload.email);
                                var owndata = userdata.idToken.payload;

                                if (owndbagent.status == '200') {
                                    //console.log(owndbagent.data);
                                    if(owndbagent.data[0].status){
                                        toast.success(loginagent.message)
                                        owndbagent.data[0].firstName = owndata['custom:first_name']
                                        owndbagent.data[0].lastName = owndata['custom:last_name']
                                        owndbagent.data[0].alias = owndata['custom:alias']
                                        owndbagent.data[0].email = owndata['email']
                                        owndbagent.data[0].photo = owndata['custom:pic']
                                        owndbagent.data[0].timezone = owndata['custom:timezone']
                                        owndbagent.data[0].sub = owndbagent.data[0].id
                                        owndbagent.data[0].id = owndbagent.data[0].parentId
                                        localStorage.setItem('user', JSON.stringify(owndbagent.data));
                                        localStorage.setItem('userId', owndbagent.data[0].parentId);
                                        localStorage.setItem('token', owndbagent.data[0].token);
                                        localStorage.setItem('articlecount', owndbagent.aticlecount);
                                        let tickethomespage=owndbagent.data[0]?.tickethomespage
                                        if(tickethomespage === undefined || tickethomespage === ""){
                                                tickethomespage="myopentickets"
                                        }
                                        
                                        Services.activityLog("Login by own database");
                                        var agentLoginCookies = {
                                            "61be0d8c3c195df8308def9fac389355" : 1
                                        }
                                        await Services.setCookies(agentLoginCookies);
                                        //toast.success(res.message); 
                                        if(baseData.settings.usersettings?.is_ticket_app) {
                                            //window.location.replace('/ticket/all-inbox')
                                            window.location.replace(`/ticket/${tickethomespage}`)                                            
                                        }
                                        else if(baseData.settings.usersettings?.is_kb_app){
                                            window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)
                                        }
                                        else{
                                            //window.location.replace('/ticket/all-inbox')
                                            window.location.replace(`/ticket/${tickethomespage}`)
                                        }                                        
                                    }
                                    else{
                                        toast.error("Your account has been deactivated.")
                                        await Services.signoutagenttoken()
                                        clearAll()
                                        setLoading(false)
                                    }
                                                                        
                                } else {
                                    setLoading(false)
                                    toast.error(owndbagent.message)
                                }
    
                                return false;
                            }
                            else{
                                if (loginagent.status_msg == 400 && loginagent.message != 'User not found' && loginagent.message != 'Incorrect  User Name and password'){
                                    //dispatch(showUpdatePasswordPopUp())
                                    setLoading(false)
                                }else if (loginagent.status_msg == 400 && (loginagent.message == 'User not found' || loginagent.message == 'Incorrect  User Name and password')){
                                    toast.error(loginagent.message)
                                    setLoading(false)
                                }                                
                            }
                            
                        }else{
                            if(!res.active){
                                toast.error(res.message); 
                            }
                            else{
                                toast.success(res.message); 
                            } 
                            history.push(`/agent-login`)
                        }
                                                                  
                      }
                      else{
                        setLoading(false)
                        if(res.link_expired){
                            //toast.error(res.message);
                            setLinkExpire(true)
                        }
                        toast.error(res.message);                        
                    }   
            }}
        >
            {({ errors, status, touched }) => (
                <Form>
                    
                    <div className="main-tickting-frontend frontends tickting-login-screen">
                        <div className="container">
                            <div className="login-centerd">
                                <div className="login-frontend">
                                    <div className="login-box">
                                        
                                        <div className="headings-login-signup paraLoginScreenHeading">
                                            <h4 className="card-title">Set Password</h4>
                                            {/* <p className="text-mutede">Reset your password in easy steps.</p> */}
                                        </div>
                                        <div className="feilds-new">
                                            <div className="user-feilds">
                                                {/* <div className="md-form md-form-custom">
                                                    <Field name="email" id="input-char-counter1" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter1">Email ID*</label>
                                                </div> */}
                                            </div>
                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="password" id="input-char-counter11" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter11">Enter New Password*</label>
                                                </div>
                                            </div>

                                            <div className="user-feilds">
                                                <div className="md-form md-form-custom">
                                                    <Field name="confirmPassword" id="input-char-counter12" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                                    <label htmlFor="input-char-counter12">Confirm New Password*</label>
                                                </div>
                                            </div>
                                                                                       
                                            <div className="user-feilds">
                                                <div className="login-signup-btn k-flex align-items-center">
                                                    <button disabled={loading} type="submit">Continue</button>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>

    </div>
    </div>
    </div>

</div>

:(window.location.replace('/'))}
</BaseLayout>
       
    )

}

export default AgentVerify;