import { all, fork, takeEvery, put, call,delay } from 'redux-saga/effects'
import * as Services from "../../utils/services"
import * as Actions from "./actions"
import * as Constants from "./constants"
import jwt_decode from "jwt-decode";
const jwt = require('jsonwebtoken')
//fetchers
function* fetchSettings(action) {
    try {
        const settings = yield call(Services.getApiCall, { subdomain: action.payload.domainname }, 'getSettings');
        //console.log(settings,"settings")
        if (settings.status === '200') {
            yield put(Actions.fetchSettingsSuccess(settings.data));
            action.payload.resolve({"status":settings.status,"message":"Success"})
        }
        else if (settings.status === '404') {
            yield put(Actions.fetchSettingsFailure());
            action.payload.resolve({"status":settings.status,"message":"Failure"})
        }
    } catch (e) {
        yield put(Actions.fetchSettingsFailure());
        action.payload.resolve({"status":'404',"message":"Failure"})
    }
}

function* loginRequestt(action) {
    try {
        //console.log("asasassdjshdjhd");
        //const res = yield call(Services.postApiCall,action.payload, 'login');
        const res = yield call(Services.postApiCall,action.payload, 'logincustomer');
        console.log(action.payload,"action.payload")
       
        if (res.status === 200) {
            yield put(Actions.showSuccessToast('Logged in successfully'));
            //yield put(Actions.loadLastPage(true));
            //window.location.replace(action.payload.path)
            yield put(Actions.setAuthenticationStatus(true));
            yield put(Actions.loginSuccess(res.token));
            const decode = jwt_decode(res.token)
            yield put(Actions.setAuthUser(decode))
            // localStorage.setItem('user',JSON.stringify({
            //     token:res.token
            // }))
            // localStorage.setItem('customer',JSON.stringify({
            //     token:res.token
            // }))
            localStorage.setItem('customer',res.token)
        }
        else if (res.status === 400) {
            yield put(Actions.loginFailure(res.message));
        }
    } catch (e) {
        yield put(Actions.loginFailure());
    }
}




function* signupRequestt(action) {
    try {
        const res = yield call(Services.postApiCall,action.payload, 'signup');
        if (res.status === 200) {
            yield put(Actions.signupSuccess());
        }
        else{
            yield put(Actions.signupFailure(res.message));
        }
    } catch (e) {
        yield put(Actions.signupFailure());
    }
}

function* verifyOtp(action) {
    try {
        //console.log(action,'action')
        const res = yield call(Services.postApiCall,action.payload, 'verifyOtpAndCreateUser');
        if (res.status === 200) {
            yield put(Actions.verifyOtpSuccess(res.message));
          //  localStorage.removeItem('user')
        }
        else{
            yield put(Actions.verifyOtpFailure(res.message));
        }
    } catch (e) {
        //console.log(e,'action err')
        yield put(Actions.verifyOtpFailure());
    }
}

function* resendOtp(action) {
    try {
        const res = yield call(Services.postApiCall,action.payload, 'resendVerificationCode');
        if (res.status === 200) {
            yield put(Actions.resendOtpSuccess(res.message));
        }
        else{
            yield put(Actions.resendOtpFailure(res.message));
        }
    } catch (e) {
        yield put(Actions.resendOtpFailure());
    }
}

function* forgotPasswordSendCodee(action) {
    try {
        const res = yield call(Services.postApiCall,action.payload, 'forgotPasswordSendCode');
        if (res.status === 200) {
            yield put(Actions.forgotPasswordSendCodeSuccess(res.message));
        }
        else{
            yield put(Actions.forgotPasswordSendCodeFailure(res.message));
        }
    } catch (e) {
        yield put(Actions.forgotPasswordSendCodeFailure());
    }
}

function* forgotPasswordVerifyCodee(action) {
    try {
        //console.log(action,"action")
        const res = yield call(Services.postApiCall,action.payload, 'verifyOtpAndUpdatePassword');
        if (res.status === '200') {
            yield put(Actions.forgotPasswordVerifyCodeSuccess(res.message));
        }
        else{
            yield put(Actions.forgotPasswordVerifyCodeFailure(res.message));
        }
    } catch (e) {
        yield put(Actions.forgotPasswordVerifyCodeFailure());
    }
}
function* setProfilePic(action) {
    try {
        
       let decode = jwt_decode(action.payload)
       //console.log(decode,"action.payload")
       yield put(Actions.setAuthUser(decode))
       localStorage.setItem('customer',action.payload)
    }
    catch(e){
        console.log(e,"error>>>>>>>>>>>>>>>>>")
    }
    
    // let decode = jwt_decode(action.payload)
    // console.log(decode,"action.payload")
    //yield put(Actions.setAuthUser(action.payload))
    //localStorage.setItem('customer',action.payload)
}


//watchers
function* fetchSettingsSaga() {
    yield takeEvery(Constants.FETCH_SETTINGS_REQUEST, fetchSettings)
}

function* loginSaga() {
    yield takeEvery(Constants.LOGIN_REQUEST,loginRequestt)
}

function* signupSaga() {
    yield takeEvery(Constants.SIGNUP_REQUEST,signupRequestt)
}


function* resendOtpSaga() {
    yield takeEvery(Constants.RESEND_OTP_REQUEST, resendOtp)
}

function* verifyOtpSaga() {
    //console.log('verifyOtpSaga called')
    yield takeEvery(Constants.VERIFY_OTP_REQUEST, verifyOtp)
}

function* forgotPasswordSendCodeSaga() {
    yield takeEvery(Constants.FORGOT_PASSWORD_SEND_CODE_REQUEST, forgotPasswordSendCodee)
}

function* forgotPasswordVerifyCodeSaga() {
    yield takeEvery(Constants.FORGOT_PASSWORD_VERIFY_OTP_REQUEST, forgotPasswordVerifyCodee)
}
function* setProfilePicSaga() {
    yield takeEvery(Constants.SET_PROFILE_PIC,setProfilePic)
}
//export sagas
export default function* rootSaga() {
    yield all([
        fork(fetchSettingsSaga),
        fork(loginSaga),
        fork(signupSaga),
        fork(resendOtpSaga),
        fork(verifyOtpSaga),
        fork(forgotPasswordSendCodeSaga),
        fork(forgotPasswordVerifyCodeSaga),
        fork(setProfilePicSaga),
    ])
};