import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"

// import ArticlePage from "../containers/ArticlePage"
// import ArticlesPage from "../containers/ArticlesPage"
// import CategoryPage from "../containers/CategoryPage"
import ConfirmPasswordPage from "../containers/ConfirmPasswordPage"
// import FolderPage from "../containers/FolderPage"
import ForgotPasswordPage from "../containers/ForgotPasswordPage"
import ForgotPasswordCustomerPage from "../containers/ForgotPasswordPage/CustomerForgetPassword"
import ForgotPasswordSuccess from "../containers/ForgotPasswordSuccess"
import LoginPage from "../containers/LoginPage"
import AgentLogin from "../containers/AgentLogin"
// import PreviewPage from "../containers/PreviewPage"
import SignupPage from "../containers/SignupPage"
import SignupSuccessPage from "../containers/SignupSuccessPage"
import VerifyPasswordPage from "../containers/VerifyPasswordPage"
// import IndexPage from "../containers/IndexPage"
import MyAreaPage from "../containers/MyAreaPage"
import ErrorPage from "../containers/404Page";
import AgentVerify from "../containers/AgentVerify";
import CustomerVerify from "../containers/CustomerVerify";
import TicketList from './../containers/TicketSection/TicketList'
import TicketDetail from './../containers/TicketSection/TicketDetail'
import SubmitTicket from './../containers/TicketSection/SubmitTicket'
import AuthUserSubmitTicket from './../containers/TicketSection/AuthticatedUserCreateTicket'
import Profilesettings from './../containers/ProfileSetting/index'
import CustomerFeedback from './../containers/CustomerFeedback/CustomerFeedback'
import Success from './../containers/CustomerFeedback/Success'
import NoData from '../components/NoData/NoData'
import Redirection from '../components/Redirection';

export default function Routes() {
 
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" component={IndexPage} />
        <Route exact path="/categories" component={CategoryPage} />
        <Route exact path="/category/:id" component={FolderPage} />
        <Route exact path="/folder/:id" component={ArticlesPage} />
        <Route exact path="/article/:id" component={ArticlePage} /> */}
        <Route exact path='/' component={Redirection}/>
        <Route exact path='/categories' component={Redirection}/>
        <Route exact path='/category/:id' component={Redirection}/>
        <Route exact path='/folder/:id' component={Redirection}/>
        <Route exact path='/article/:id' component={Redirection}/>
        <Route exact path='/preview/:id' component={Redirection}/>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/forgot-password-success/:id/:type" component={ForgotPasswordSuccess} />
        <Route exact path="/signup-success/:id" component={SignupSuccessPage} />
        <Route exact path="/forgot-password/agent" component={ForgotPasswordPage} />
        <Route exact path="/forgot-password/user" component={ForgotPasswordCustomerPage} />
        
        <Route exact path="/confirm-password/:id/:type" component={ConfirmPasswordPage}/>
        <Route exact path="/verify" component={VerifyPasswordPage} />
        {/* <Route exact path="/preview/:id" component={PreviewPage} />   */}
        <Route exact path="/my-area" component={MyAreaPage} />  
        <Route exact path="/agent-login" component={AgentLogin} />
        <Route exact path="/agent-verify/:id" component={AgentVerify} />
        <Route exact path="/customer-verify/:id" component={CustomerVerify} />
        <Route exact path="/list-ticket" component={TicketList} />      
        <Route exact path="/detail-ticket/:id" component={TicketDetail} />      
        <Route exact path="/submit-ticket" component={SubmitTicket} />     
        <Route exact path="/create-ticket" component={AuthUserSubmitTicket} />
        <Route exact path="/profilesettings" component={Profilesettings} />  
        <Route exact path="/customer-feedback/:orgId/:ticketId/:docId" component={CustomerFeedback} />   
        <Route exact path="/customer-feedback/success" component={Success} />        
        <Route exact path="/not-exist" component={NoData} />        
        {/* <Route exact path="/404" component={ErrorPage} />   */}
        <Route  path="*" component={ErrorPage} />  
        <Redirect to="/404" /> 
      </Switch>
    </Router>
  )
}

