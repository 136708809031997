import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import React, { useState } from "react"
import { updatepassword } from "../../utils/services"
import { useSelector, useDispatch } from "react-redux"
import { hideUpdatePasswordPopUp } from "../BaseLayout/actions"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { Helmet } from "react-helmet";

const Updatepassword = (props) => {
    // const [state, setstate] = useState({
    //     message: '',
    //     show: false,
    //     showsubmit: false
    // })

    let dispatch = useDispatch()
    // const articleData = useSelector(state => state.articleReducer)
     const baseData = useSelector(state => state.baseReducer)
     const settings = baseData.settings.usersettings; 
    const hidePasswordPopUp=()=>{
        dispatch(hideUpdatePasswordPopUp());
     }

    

    return (
        <div className="comment-writeArea">
                    <Formik
                        initialValues={{
                            oldpassword: '',
                            newpassword: ''
                        }}
                        validationSchema={Yup.object().shape({
                            oldpassword: Yup.string()
                                .required('Old Password is required'),
                            newpassword: Yup.string()
                                .required('New Password is required'),
                        })}

                        onSubmit={async fields => {
                            var getresponse = await updatepassword(settings.companyidentifier,props.useremail,fields.oldpassword,fields.newpassword)
                            if(getresponse.status == 400)
                                toast.error(getresponse.message);
                            else{
                                dispatch(hideUpdatePasswordPopUp());
                                toast.success(getresponse.message);
                            }
                        }}

                    >
                        {({ errors, status, touched }) => (
                            <Form>
                                <div className="captch-popup-design" style={{minWidth: '30%'}}>
                                    <div className="captcha-section" >
                                        <p className="text-right close-icon"><img src="/images/close.png" alt="close" className="closing"
                                           onClick={() => {
                                                hidePasswordPopUp()
                                            }}/>
                                        </p>
                                        <p>Update Password</p>
                                        <div className="user-feilds captcha-feilds">
                                            <div className="md-form md-form-custom">

                                                <Field name="oldpassword" id="inputMDEx7" type="text" placeholder="Enter Old Password" className={'form-control' + (errors.oldpassword && touched.oldpassword ? ' is-invalid' : '')} />
                                                <ErrorMessage name="oldpassword" component="div" className="invalid-feedback" />

                                            </div>
                                        </div>
                                        <div className="user-feilds captcha-feilds">
                                            <div className="md-form md-form-custom">

                                                <Field name="newpassword" id="inputMDEx7" type="text" placeholder="Enter New Password" className={'form-control' + (errors.newpassword && touched.newpassword ? ' is-invalid' : '')} />
                                                <ErrorMessage name="newpassword" component="div" className="invalid-feedback" />

                                            </div>
                                        </div>
                                       

                                            <div className="login-signup-btn k-flex align-items-center text-right">
                                                <button className="btn-captcha" type="submit" >Save</button>
                                            </div>
                                           

                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
               
        </div>
    )
}

export default Updatepassword;