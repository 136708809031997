import React, { useEffect, useState,Fragment } from "react"
import { useLocation, NavLink } from 'react-router-dom';
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { signoutUser, showSuccessToast } from "../../containers/BaseLayout/actions"
import { updateVoteColor } from "../../containers/ArticlePage/actions"
import { signouttoken,signoutagenttoken,logout , signoutadmintoken,getBaseURL, activityLog, deleteCookie} from "../../utils/services"

import {Tabs, Tab, Modal, Button} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { db } from "../../utils/db"
const jwt = require('jsonwebtoken')
const Header = (props) => {

    const pathname = useLocation().pathname
    const dispatch = useDispatch()
    const baseData = useSelector(state => state.baseReducer)
    //console.log(baseData,"basedata>>>>>")
    const role = (baseData.authUser[0])? baseData.authUser[0]?.role_type||baseData.authUser[0]?.role :(baseData.authUser?.role_type)?baseData.authUser?.role_type:'';
    const [toogle, settoogle] = useState(true)
    var baseurl = getBaseURL();
    const pathname1= pathname.split('/')[1]
    const [showAgent, setAgentShow] = useState(false);
    const handleAgentClose = () => setAgentShow(false);
    const handleAgentShow = () => setAgentShow(true);
    const [isDeactived,setDeactivated]= useState(false)
    const history = useHistory() 
    //console.log(pathname1,"pathname1",pathname)
    //console.log(baseurl,"baseurl")
    //console.log(`${(window.location.href).split('.com')[0]}.com/agent-verify/`,"verify link>>>>>>")
    //console.log(role,"role!!!!!!!!!!!!!!!")
    // let knowledgeUrl=''
    // //if((window.location.href).includes('appydesk.appypie.com')){
    // if(!(window.location.href).includes('dev-desk.pbodev.info')){
    //     knowledgeUrl='/knowledge'
    // }
    // else{
    //     knowledgeUrl='/kb'
    // }
    //console.log(knowledgeUrl,"knowledgeUrl>>>>>>>",btoa("adpappy@yopmail.com" + '-' + "Zxcvb@123"))
    

    

    const removeLogoutOwner = async () =>{
        localStorage.removeItem('onLogoutOwner')
        
        return true
    }

    const clearAll =() =>{
        //console.log("clear all")
        logout();
        dispatch(signoutUser())
        dispatch(updateVoteColor({ upvote: false, downvote: false }))
        dispatch(showSuccessToast('Logged Out Successfully.'))

    }
    const signout= async () =>{

        //const role = baseData.authUser[0]?.role_type||baseData.authUser[0]?.role;
        //console.log(role,"role>>>>>")
        let user = JSON.parse(localStorage.getItem('user'))
        handleAgentClose()
        if(role && role == 'agent') {
          await signoutagenttoken().then(data =>{ 
              //console.log(data) 
              activityLog("Logout",'Agent',user[0]?.id,baseData.authUser[0]?.domainname)
              deleteCookie("61be0d8c3c195df8308def9fac389355")
            })
            
          clearAll()
        }else if(role && role == 'admin'){
            activityLog("Logout",'Admin',user[0]?.id,baseData.authUser[0]?.domainname)
            await clearAll()
            //window.location = `https://commonlogin.pbodev.info/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
            //window.location = `https://accounts.appypie.com/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
            window.location = `${process.env.REACT_APP_POPUP_URL}/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
            // signoutadmintoken().then(data =>{ console.log(data);
            //     window.location = `https://commonlogin.pbodev.info/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
            // })
            //await  signoutadmintoken().then(data =>{ console.log(data)  }) 
        }else{
            await signouttoken().then(data =>{ 
                //console.log(data)
                deleteCookie("a1840bd4010cc8f4c31a45a679a985cd")
            }) 
            clearAll()
            window.location.reload();
        }

        
         

        
        
    }
    useEffect( () => {
       
        
    //    if((baseData?.settings?.usersettings?.kbdeactivated) || (baseData?.settings?.usersettings?.ticketdeactivated) && !isDeactived && role){
    //        console.log("signout called......",role)
    //         //signout()
    //         setDeactivated(true)
    //         if(role == 'admin'){
    //             signout()
    //         }
    //         else{
    //             handleAgentShow()
    //         }
    //    }

    //if(localStorage.getItem('token')){
    if((Object.keys(baseData?.settings?.usersettings).length > 0)){
        //let decoded = jwt.decode(localStorage.getItem('token'));
        let id = (baseData?.settings?.usersettings)?baseData?.settings?.usersettings.id:(jwt.decode(localStorage.getItem('token'))).org_id;
        db.collection("usersettings").doc(id)
        .onSnapshot(async(snap) => {
            //console.log(history,">>>>>test")
            //console.log(snap.data(),">>>>>test")
            // if(snap.data().kbdeactivated && history.location.pathname != "/deactivate")
            // window.location.href = `${config.deactivepath}/deactivate`;
            // if(history.location.pathname == "/deactivate" && !snap.data().kbdeactivated)
            // window.location.href = `${config.deactivepath}/`;
            if(snap.data()?.kbdeactivated){
               // setDeactivated(true)
               console.log(role,">>>>>>>>>>>>>>>>>>>>")
               let user = JSON.parse(localStorage.getItem('user'))
                if(role == 'admin'){
                    //signout()
                    activityLog("Logout",'Admin',user[0]?.id,baseData.authUser[0]?.domainname)
                    await clearAll()
                    //window.location = `https://commonlogin.pbodev.info/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
                    //window.location = `https://accounts.appypie.com/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
                    window.location = `${process.env.REACT_APP_POPUP_URL}/logout?frompage=${baseurl}/agent-login&website=${baseurl}`
                      
                }
                else{
                   // handleAgentShow()

                   if(role && role == 'agent' || role == "customer") {
                        window.location.replace(`/not-exist`)
                   }
                    
                }
            }
            if(snap.data()?.isdelete){
                
                //window.location.href=`https://appypie.com.undefined${knowledgeUrl}/orgname`
                //window.location.href=`/not-exist`
                window.location.replace(`/not-exist`)
            }
            
        })

        if(baseData?.authUser && Array.isArray(baseData?.authUser) && baseData?.authUser.length>0 && baseData?.authUser[0].role == "agent"){
            
            const docId = baseData?.authUser[0].id
            const onAgentDeactivate= db.collection('users').doc(docId).onSnapshot((documentSet => {
                   
                if (documentSet.empty) {
                        //Do nothing             
                }
                else {
                    //Agent has been deactivated
                    //console.log(documentSet.data()?.status,"documentSet.data()?.status>>>>>>>>")
                    if(documentSet.data()?.status && documentSet.data()?.status !== true){
                        console.log(">>>>>>>>")
                        signout()
                    }
                    
                }       
            }))
        }
            
       
            
            //console.log(user[0]?.id,"user>>>>>>>>>>>>")
            
            
       
    
    }
        //console.log(baseData,"baseData")

    });
    
    useEffect(() => {
        let visitedPage=""
        if(pathname1 === ""){
            visitedPage="home"
        }
        else{
            visitedPage=pathname1
        }
        if (window.performance) {
            if (performance.navigation.type == 1) {
              //Do nothing
            } else {
              //Create Log on page visit
              //activityLog(`visited page ${pathname}` )
              activityLog(`Visited page ${visitedPage}`)
            }
          }

        return history.listen((location) => { 
           //console.log(`You changed the page to: ${location.pathname}`) 
        }) 
     },[history]) 

    return (
        
        <Fragment>
            {(!['/agent-login','/login'].includes('/'+pathname1))?
            <Modal className="disableModel" show={showAgent}  aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="py-0 border-bottom-0"  onClick={handleAgentClose}>
        
            </Modal.Header>
            <Modal.Body className="py-0 px-0">
                <div className="mb-4">
                    <h4 className="card-title mb-0 font-bold mb-3">Deactivate</h4>
                    <p className="card-body-text text-dark-gray">This portal has been deactivated. Please contact your admin.</p>
                </div>

                    <>
                    {(localStorage.getItem('user')|| localStorage.getItem('customer'))?
                                        <button className="info-btn form-btn py-2 px-4" type="button" onClick={() => signout()}>Log Out</button>

                    :null}
                    {/* <span className="ml-2 mr-3 cursor-pointer text-dark-gray" onClick={() => window.location.replace('/agent-login')}>Cancel</span> */}
                    </>
                
                    
                
            </Modal.Body>
              
        </Modal>:
        null}
            
        {(toogle && localStorage.getItem('user'))?
        
         <div className="top-header py-1"  id="topHeader" >
                <div className="container">
                    <div className="top-header-menu text-right">
                        <ul>
                            <li>
                                {/* <div className="text-center" onClick={()=>window.location.replace('/knowledge')}> */}
                                {(baseData.settings.usersettings?.is_ticket_app) ?
                                <div className="text-center" onClick={()=>window.location.replace('/ticket/myopentickets')}>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="18.517" height="17.699" viewBox="0 0 18.517 17.699">
                                        <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                                            <path id="Icon_awesome-server" data-name="Icon awesome-server" d="M17.36,7.307H1.157A1.214,1.214,0,0,1,0,6.043V3.514A1.214,1.214,0,0,1,1.157,2.25h16.2a1.214,1.214,0,0,1,1.157,1.264V6.043A1.214,1.214,0,0,1,17.36,7.307ZM15.624,3.83a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,15.624,3.83Zm-2.315,0a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,13.309,3.83Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,12.364V9.835A1.214,1.214,0,0,1,1.157,8.571h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,13.628Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,10.151Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,10.151Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,18.685V16.156a1.214,1.214,0,0,1,1.157-1.264h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,19.949Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,16.472Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,16.472Z" transform="translate(0 -2.25)" fill="#a2abd1"></path>
                                        </g>
                                        </svg>
                                    </span>
                                    <p className="text-dark-gray card-body-text">Go to My Panel</p>
                                </div>
                                :(baseData.settings.usersettings?.is_kb_app)?
                                <div className="text-center" onClick={()=>window.location.replace(`${process.env.REACT_APP_KNOWLEDGE_URL}`)}>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="18.517" height="17.699" viewBox="0 0 18.517 17.699">
                                        <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                                            <path id="Icon_awesome-server" data-name="Icon awesome-server" d="M17.36,7.307H1.157A1.214,1.214,0,0,1,0,6.043V3.514A1.214,1.214,0,0,1,1.157,2.25h16.2a1.214,1.214,0,0,1,1.157,1.264V6.043A1.214,1.214,0,0,1,17.36,7.307ZM15.624,3.83a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,15.624,3.83Zm-2.315,0a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,13.309,3.83Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,12.364V9.835A1.214,1.214,0,0,1,1.157,8.571h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,13.628Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,10.151Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,10.151Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,18.685V16.156a1.214,1.214,0,0,1,1.157-1.264h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,19.949Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,16.472Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,16.472Z" transform="translate(0 -2.25)" fill="#a2abd1"></path>
                                        </g>
                                        </svg>
                                    </span>
                                    <p className="text-dark-gray card-body-text">Go to My Panel</p>
                                </div>
                                :<div className="text-center" onClick={()=>window.location.replace('/ticket/myopentickets')}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="18.517" height="17.699" viewBox="0 0 18.517 17.699">
                                    <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                                        <path id="Icon_awesome-server" data-name="Icon awesome-server" d="M17.36,7.307H1.157A1.214,1.214,0,0,1,0,6.043V3.514A1.214,1.214,0,0,1,1.157,2.25h16.2a1.214,1.214,0,0,1,1.157,1.264V6.043A1.214,1.214,0,0,1,17.36,7.307ZM15.624,3.83a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,15.624,3.83Zm-2.315,0a.911.911,0,0,0-.868.948.871.871,0,1,0,1.736,0A.911.911,0,0,0,13.309,3.83Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,12.364V9.835A1.214,1.214,0,0,1,1.157,8.571h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,13.628Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,10.151Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,10.151Zm4.051,9.8H1.157A1.214,1.214,0,0,1,0,18.685V16.156a1.214,1.214,0,0,1,1.157-1.264h16.2a1.214,1.214,0,0,1,1.157,1.264v2.528A1.214,1.214,0,0,1,17.36,19.949Zm-1.736-3.477a.952.952,0,1,0,.868.948A.911.911,0,0,0,15.624,16.472Zm-2.315,0a.952.952,0,1,0,.868.948A.911.911,0,0,0,13.309,16.472Z" transform="translate(0 -2.25)" fill="#a2abd1"></path>
                                    </g>
                                    </svg>
                                </span>
                                <p className="text-dark-gray card-body-text">Go to My Panel</p>
                            </div>}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>: null}
                
            <div className="header">
                <div className="container">
                {(localStorage.getItem('user'))?<div className={'top-header-down-up' +((!toogle)?' iconUpDown':'')} id="clickIcon" onClick={() => settoogle(!toogle)}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.777" height="9.742" viewBox="0 0 15.777 9.742">
                         <path id="Icon_material-keyboard-arrow-up" data-name="Icon material-keyboard-arrow-up" d="M10.854,12l6.035,6.021L22.924,12l1.854,1.854-7.889,7.888L9,13.854Z" transform="translate(-9 -12)" fill="#64b5f6"></path>
                    </svg>                                                    
                </span>
            </div>:null}
                    <div className="header-menu-nav k-flex align-items-baseline">

                        <div className="logo-section mRa">


                            {(baseData.settings.frontendsettings.logo !== undefined && baseData.settings.frontendsettings.logo.length > 0) ?
                                <Link to="/"> <img src={baseData.settings.frontendsettings.logo} alt="logo" /></Link>
                                : null}

                        </div>

                        <div className="menu-nav">
                            <nav className="menu-nav-list">
                                <ul>
                                    <li >
                                        <Link to="/" className={pathname === "/" ? "actives" : ""}>
                                            {baseData.settings.frontendsettings.Headerlink[0].charAt(0).toUpperCase() + baseData.settings.frontendsettings.Headerlink[0].slice(1).toLowerCase()}
                                        </Link>
                                    </li>
                                    {/* <li >
                                        <Link to="/categories" className={pathname === "/categories" ? "actives" : ""}>
                                            {baseData.settings.frontendsettings.Headerlink[1]}
                                        </Link>
                                    </li> */}
                                    <li >
                                        <a href="/kb/categories" className={['/categories', '/category','/article','/folder'].includes('/'+pathname1) ? "actives" : ""}>
                                        {baseData.settings.frontendsettings.Headerlink[1]}
                                        </a>
                                    </li>

                                    {/* {console.log(role,"role",baseData.isAuthenticated,baseData)} */}
                                    {/* {(role == "admin" || role == "agent" && baseData.isAuthenticated === true)?
                                    <li >
                                        <a href="/ticket/all-inbox" className={pathname === "/ticket/all-inbox" ? "actives" : ""}>
                                            Ticketing
                                        </a>
                                    </li>
                                     :null} */}
                                    
                                     {(localStorage.getItem('customer') && baseData.isAuthenticated === true)?
                                      <li >
                                        <a href="/kb/list-ticket" className={['/list-ticket', '/create-ticket','/detail-ticket'].includes('/'+pathname1) ? "actives" : ""}>
                                            My Area
                                        </a>
                                    </li>
                                    :null}
                                    
                                    {(baseData.isAuthenticated !== true)?
                                    <li>
                                        <a href="/kb/submit-ticket" className={pathname === "/submit-ticket" ? "actives" : ""}>
                                            Submit a Ticket
                                        </a>
                                    </li>
                                    :null}
                                    {/* <li >
                                        <Link to="/my-area" className={pathname === "/my-area" ? "actives" : ""} >
                                            {baseData.settings.frontendsettings.Headerlink[2]}
                                        </Link>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>

                        <div className="userHeader">
                            <ul>

                                {(baseData.settings.frontendsettings.allowsignup) ?
                                        (baseData.isAuthenticated === false) ?
                                            <Fragment>
                                            {(localStorage.getItem('onLogoutOwner'))?
                                            <li>
                                            <Link onClick={async() => {
                                                
                                               await removeLogoutOwner()
                                                
                                                window.location.href="/"
                                                                                             
                                                
                                                }}>Log In</Link>
                                        </li>:
                                        <li>
                                            <a href="/kb/login" className={pathname === "/login" || pathname === "/agent-login" ? "actives" : ""} >Log In</a>
                                        </li>
                                        }
                                                
                                                <li>
                                                    <a href="/kb/signup" className={pathname === "/signup" ? "actives" : ""} >Sign Up</a>
                                                </li>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <li >
                                                    {/* <div data-toggle="dropdown" className="user-pic cursor-pointer" >
                                                        {console.log(baseData.authUser,"baseData.authUser",(baseData.authUser[0]?.photo === 'notexist' || baseData.authUser[0]?.photo?.length==0))}
                                                        
                                                        {(Object.keys(baseData.authUser).length > 0 && baseData.authUser.email !== undefined) ?
                                                            <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                                {`${baseData.authUser.email[0].toUpperCase()}${baseData.authUser.email[1].toUpperCase()}`}
                                                            </div>
                                                        
                                                        :(baseData.authUser !== undefined)&& (baseData.authUser.length>0 && baseData.authUser[0]?.photo !== undefined && (baseData.authUser[0]?.photo === 'notexist' || baseData.authUser[0]?.photo?.length==0))?
                                                    
                                                        <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                            {`${baseData.authUser[0].email[0].toUpperCase()}${baseData.authUser[0].email[1].toUpperCase()}`}
                                                        </div>
                                                        :(baseData.authUser !== undefined)&& (baseData.authUser.length>0 && baseData.authUser[0]?.photo === undefined && baseData.authUser[0]?.email !== undefined)?
                                                    
                                                        <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                            {`${baseData.authUser[0].email[0].toUpperCase()}${baseData.authUser[0].email[1].toUpperCase()}`}
                                                        </div>
                                                        :null
                                                        // <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                        //         {`${baseData.authUser[0].email[0].toUpperCase()}${baseData.authUser[0].email[1].toUpperCase()}`}
                                                        //     </div>
                                                        }

                               

                                                    </div> */}
                                                    {(localStorage.getItem("customer") && baseData.isAuthenticated === true)?
                                                    <div data-toggle="dropdown" className="user-pic cursor-pointer" >
                                                        {(Object.keys(baseData.authUser).length > 0 && baseData.authUser?.photo !== undefined && baseData.authUser?.photo?.length > 0 && baseData.authUser?.photo !== "notexist") ?
                                                        <div className="avtare-cus-new" >
                                                        <img src={baseData.authUser.photo} alt="" style={{"height":"40px","width":"40px","borderRadius":"50%","backgroundColor":"#ffffff",'marginTop': '-2px'}}></img>
                                                        </div>:
                                                        <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                            {/* {console.log(baseData.authUser,"baseData.authUser")} */}
                                                        {`${baseData.authUser?.email[0]?.toUpperCase()}${baseData.authUser?.email[1]?.toUpperCase()}`}
                                                        </div>}
                                                    </div>:
                                                    null
                                                    }
                                                    {(localStorage.getItem("user") && baseData.isAuthenticated === true)?
                                                        <div data-toggle="dropdown" className="user-pic cursor-pointer" >
                                                        {(baseData.authUser !== undefined)&& (baseData.authUser.length>0 && baseData.authUser[0]?.photo !== undefined && (baseData.authUser[0]?.photo === 'notexist' || baseData.authUser[0]?.photo?.length==0))?
                                                        <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                        {`${baseData.authUser[0].email[0].toUpperCase()}${baseData.authUser[0].email[1].toUpperCase()}`}
                                                        </div>:
                                                        (baseData.authUser !== undefined)&& (baseData.authUser[0].length>0 && baseData.authUser[0]?.imageurl !== undefined && baseData.authUser[0]?.imageurl.length > 0 && baseData.authUser[0]?.thumbnail !== 'notexist' && baseData.authUser[0]?.thumbnail?.length>0)?
                                                        <div className="avtare-cus-new" >
                                                        <img src={`${baseData.authUser.imageurl}/${baseData.authUser.thumbnail}`} alt="" style={{"height":"40px","width":"40px","borderRadius":"50%","backgroundColor":"#ffffff",'marginTop': '-2px'}}></img>
                                                        </div>
                                                        :
                                                        <div className="avtare-cus-new" style={{ "backgroundColor": '#6c757d' }}>
                                                        {`${baseData.authUser[0]?.email[0]?.toUpperCase()}${baseData.authUser[0]?.email[1]?.toUpperCase()}`}
                                                        </div>}
                                                    </div>:
                                                    null
                                                    }
                                                    
                                                    <div className="navbarDropdown dropdown-menu dropdown-menu-right myPro_drp px-2 py-1" aria-labelledby="navbarDropdown1">
                                                    {(localStorage.getItem('customer') && baseData.isAuthenticated === true)?
                                                    <div onClick={() => {
                                                        window.location.href="/profilesettings"
                                                        }}>
                                                        <a className="dropdown-item border-bottom py-2" ><span className="preview-item-content" >My Profile</span></a>
                                                    </div>:
                                                    null}

                                                    <div>
                                                        <button className="dropdown-item py-2"
                                                            onClick={(e) => {
                                                            //  localStorage.removeItem('user')
                                                            signout();
                                                            
                                                            }}
                                                        >Sign Out
                                                        </button>
                                                    </div>
                          
                                                </div>
                                                </li>
                                               
                                                <li className="signOutBtn">
                                                    {/* <button
                                                        onClick={() => {
                                                          //  localStorage.removeItem('user')
                                                          signout();
                                                           
                                                        }}
                                                    >Sign Out</button> */}
                                                </li>
                                            </Fragment>                                      
                                    : null}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Html Added for preview */}

            {(pathname === '/preview/article') ?

                (Object.keys(baseData.settings).length > 0) ?
                    <div className="preivew-screen-header">
                        <div className="container">
                            <div className="header-menu-nav k-flex align-items-baseline justify-content-center py-3">

                                <p className="card-body-text" style={{ color: (baseData.settings.frontendsettings.themescolor)?baseData.settings.frontendsettings.themescolor :"#1b223c" }}>You are viewing the preview screen</p>

                                <Link to="/">
                                    <button className="preview-btn-screen" style={{ backgroundColor: (baseData.settings.frontendsettings.themescolor)?baseData.settings.frontendsettings.themescolor :"#1b223c" }}>View Help Portal</button>
                                </Link>


                            </div>
                        </div>
                    </div>
                    : null
                : null}


            {/* Html Added for preview */}

        </Fragment>
    )
}

export default Header;