import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux"
import * as yup from 'yup';
import _ from 'lodash'
import BaseLayout from "../BaseLayout"
import { getApiCall, postApiCall, textelipsify, attachmentAllowFormat } from "../../utils/ticketServices"
import ThreadList from "./ThreadList"
// import { Editor } from '@tinymce/tinymce-react';
import Loader from "../../components/Shared/Loader"
import ReactTooltip from "react-tooltip";
import Moment from 'react-moment';
import 'moment-timezone';
import { firebase } from '../../utils/db'
import { 
    // signoutUser, 
    showSuccessToast 
} from "../../containers/BaseLayout/actions"

import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
//import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';
// import Froala from 'froala-editor'

import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/css/plugins/draggable.min.css'

import 'froala-editor/js/third_party/embedly.min.js'
import 'froala-editor/css/third_party/embedly.min.css'
import 'froala-editor/js/third_party/image_tui.min.js'
import 'froala-editor/css/third_party/image_tui.min.css'

import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/css/plugins/emoticons.min.css'

import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/js/plugins/files_manager.min.js'
import 'froala-editor/css/plugins/files_manager.min.css'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/save.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/css/plugins/special_characters.min.css'
//import 'froala-editor/js/plugins/spell_checker.min.js' //not available
//import 'froala-editor/css/plugins/spell_checker.min.css' //not available
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/align.min.js'
// import moment from "moment";

// const attachmentAllowFormat = [
//     'application/pdf',
//     'image/png',
//     'image/gif',
//     'image/jpg',
//     'image/jpeg',
//     'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//     'application/msword',
//     'application/vnd.ms-excel',
//     'text/csv',
//     '.csv',
//     'video/x-flv',
//     'video/mp4',
//     'application/x-mpegURL',
//     'video/MP2T',
//     'video/3gpp',
//     'video/quicktime',
//     'video/x-msvideo',
//     'video/x-ms-wmv'
// ]

let ticketFormSchema = yup.object().shape({
    // firstname: yup.string().required(),
    // lastname: yup.string(),
    // age: yup.number().required().positive().integer(),
    // email: yup.string().email().required(),
    // subject: yup.string().required(),
    body: yup.string().required(),
});

/**
 * 
 * @param {*} props 
 */
const TicketDetail = (props) => {
    const [loaderStatus, setLoaderStatus] = useState(false)
    const [initalLoadDataComp, setInitialLoadDataComp] = useState(false)
    const history = useHistory();
    const [ticketId, setTicketID] = useState(props.match.params.id)

    const [ticketData, setTicketData] = useState()
    const [threadData, setThreadData] = useState([])
    const [formDescription, setFormDescription] = useState('')
    const baseData = useSelector(state => state.baseReducer)
    const [formSubmitInProgress, setformSubmitInProgress] = useState(false)
    
    const dispatch = useDispatch()

    /**
    * Save on server  on submit button 
    */
    const [tempUploadFile, setTempUploadFile] = useState([])
    const [attachmentList, setAttachmentList] = useState([])

    useEffect(() => {
        const customer = localStorage.getItem('customer');
        if (!customer) {
            //console.log('in effect', baseData);
            history.push('/login');
        }
    }, [])

    useEffect(() => {
        // fetchApiCall 
        setTicketID(props.match.params.id)
        fetchData()
    }, [])

    async function fetchData() {
        setLoaderStatus(true)
        // let ticketData = await 
        getApiCall({ ticket_id: ticketId }, 'fetchTicketDetails')
            .then((ticketData) => {

                //('get all ticket', ticketData);
                setTicketData(ticketData.TicketInfo)
                const combinedTicketData = ticketData.threadList
                const sortedObjs = _.orderBy(combinedTicketData, ['created_on._seconds'], ['asc']);
                setThreadData(sortedObjs)
                setLoaderStatus(false)
                setInitialLoadDataComp(true)
            }).catch((err) => {
                //console.log('console.log');
                setLoaderStatus(false)
            })
    }

    /**
     *  Save Reply over post to database  
     */
    const replyOverTicket = async (action = 'none') => {
        // if (!formDescription) {
        //     return
        // }
        // wo after design part 
        const postdata = {
            "ticket_id": ticketId,
            "body": formDescription,
            action: action
            // "reply_to": [{ "email": "testEmail@jaka.com", "firstname": "test", "lastname": "api" }]
        }

        ticketFormSchema.validate(postdata, { abortEarly: false })
            .then(async (valid) => {
                setLoaderStatus(true)
                setformSubmitInProgress(true)
                if (tempUploadFile?.length > 0) {
                    const response = await uploadFileTOServer()
                    if (!response) {
                        setLoaderStatus(false);
                        setformSubmitInProgress(false)
                        return false;
                    }
                    postdata['attachment'] = attachmentList
                }
                //console.log('dataObject', postdata);
                const getResult = await postApiCall(postdata, 'createTicketsThread');
                //console.log('returning data form backend', getResult);

                dispatch(showSuccessToast('Reply has been send successfully.'))

                const combinedTicketData = getResult.results
                const sortedObjs = _.orderBy(combinedTicketData, ['created_on._seconds'], ['asc']);
                setThreadData(sortedObjs)
                setTicketData(getResult.ticketInfo)

                setFormDescription('');
                setTempUploadFile([])
                setLoaderStatus(false)
                setformSubmitInProgress(false)
                setTempUploadFile([])
                fetchData()

            }).catch(err => {
                setformSubmitInProgress(false)
                toast.error('Content field should not be empty');
                console.log(err)
            })
    }

    /**
     *  
     * Upload File On server when click submit button 
     */
    const uploadFileTOServer = async () => {

        //console.log('uploading is now start .....')
        // const { uploadFile, uploadedFileURL } = this.state
        let alliploadFile = [];
        let uploadedFileURL = attachmentList
        if (!checkFileSizeValidaion()) {
            return false
        }

        // const tokenDecodedata = getTokenData();        
        alliploadFile = tempUploadFile.map(async (file, i) => {
            // req.attach(file.name, file);
            //const formData = new FormData()
            const uniqId = Date.now().toString()
            const fileName = file.name;
            const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
            const newFileName = `${uniqId}_${fileName}.${extension}`;
            // console.log(file)
            // formData.append(`file`, file)
            const storageRef = firebase.storage()
            // const customMetadata = {  }
            var myCustomMetadata = {
                customMetadata: {
                    'originalfilename': fileName
                }
            }

            // const org_id = tokenDecodedata.org_id
            const org_id = baseData?.settings?.usersettings?.domainname || 'abc2' // TODO: make dyamic with orgnization id by domain name 
            return storageRef.ref(`attachment2/${org_id}/${newFileName}`).put(file, myCustomMetadata)
        });

        let reBoolean = await Promise.all(alliploadFile).then(async fileData => {
            let retundata = fileData.map(async row => {
                let url = await row.ref.getDownloadURL()
                const attachedFileMetadata = {
                    filename: row.metadata.customMetadata.originalfilename,
                    metadata: row.metadata,
                    path: url
                }
                return attachedFileMetadata
                // uploadedFileURL.push(attachedFileMetadata)
            })

            return await Promise.all(retundata).then(fileArray => {
                fileArray.map(singleRow => {
                    //console.log('singlefileURL', singleRow)
                    uploadedFileURL.push(singleRow)
                })

                // console.log('uploadedFileURlData');
                // console.log(uploadedFileURL);
                setAttachmentList(uploadedFileURL)
                return true
            }).catch(err => {
                console.log(err);
            })

        }).catch(err => {
            console.log('upload error');
            console.log(err);
            return false
        })
        return reBoolean
    }

    /** 
     * Check File Size Validation -
     */
    const checkFileSizeValidaion = () => {
        let fsize = 0;
        tempUploadFile.forEach((file, i) => {
            fsize += file.size
        })

        if (fsize > 15 * 1000 * 1000) {
            toast.error('Files size should not be more than 15 MB');
            //console.log('Files size should not be more than 15 MB');

            return false;
        }
        return true;
    }

    /**
     * @param {Object} files
     */
    const onSelectDrop = (files) => {
        if (files.length === 0) {
            // console.log(files.length)
            return;
        }
        // console.log(tempUploadFile)
        // let  uploadFile = [];
        let uploadFile = [...tempUploadFile]
        //console.log(uploadFile)
        files.forEach((file, i) => {
            const ftype = file.type
            const ftypeext =  file.name.split('.').pop();
            if (attachmentAllowFormat.includes(ftype) || attachmentAllowFormat.includes(ftypeext)) {
                let kfile = file
                uploadFile.push(kfile);
            }
        });
        // console.log('uploadFile',uploadFile);
        let fsize = 0;
        uploadFile.forEach((file, i) => {
            fsize += file.size
        })
        //console.log('size of all file', fsize)
        if (fsize > 15 * 1000 * 1000) {
            toast.error('Files size should not be more than 15 MB');
            //console.log('Files size should not be more than 15 MB')

        }
        // console.log('---------', uploadFile)
        // console.log('---------beforeupadate', tempUploadFile)
        setTempUploadFile(uploadFile)
        // console.log('afterupdate', tempUploadFile)
    }

    /**
     * marked as resolve 
     * @param {*} action 
     */
    const markedAsResolved = async () => {
        const postdata = {
            "ticket_id": ticketId,
            'action': 'closed'
            // "reply_to": [{ "email": "testEmail@jaka.com", "firstname": "test", "lastname": "api" }]
        }

        setLoaderStatus(true)
        postApiCall(postdata, 'markedAsClosed')
            .then(response => {
                dispatch(showSuccessToast('Ticket has been resolved successfully.'))
                //console.log('...', response)
                //setTicketData(response.results)
                setLoaderStatus(false)
            })
            .catch(err => {
                setLoaderStatus(false)
                console.log(err)
            })

    }

    const getName = (ticketData) => {
            let lastname = ticketData.assigneeStaff.lastname !== 'NA' ? ticketData.assigneeStaff.lastname : '' 
            return `${ticketData.assigneeStaff.firstname} ${lastname}`
    }
    const getTeamName = (ticketData) => {
            return `${ticketData.assignee_team.name}`     
    }
    return (
        <BaseLayout>
            { loaderStatus ? <Loader /> : ''}

            <div className="mainFullWidth">
                <div className="forms-section ticket-section box-section my-3">
                    <div className="container submit-a-ticket">

                        {/* <div> My Area / <span onClick={()=>{
                                history.push('/list-ticket');
                             }} style={{cursor: 'pointer'}} > View Tickets </span> </div> */}

                        <div className="breadcrumb-customes breadcrumb-customes2 tickting-bredcumb">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"> <Link to="/list-ticket"> View Tickets</Link></li>

                                    {/* <li className="breadcrumb-item active"> {textelipsify(ticketData?.subject, 55, true)} </li> */}
                                    <li className="breadcrumb-item"> {textelipsify(ticketData?.subject, 55, true)} </li>
                                </ol>
                            </nav>
                        </div>

                        <div className="ticket-form-box area-heading-title">
                            <h3 className="card-title mt-3 ">{ticketData?.subject}   </h3>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                <div className="sumbit-ticket mb-xs-cus-3">
                                    <div className="comment-section">
                                        <>

                                            {/* Working on loop to create list detail  */}
                                            {
                                                threadData.map((thread) => {
                                                    return <ThreadList key={thread.thread_id} {...thread} />
                                                })
                                            }
                                        </>

                                        {/* <div className="coment-row">
                                                <div className="user-comment">
                                                    <img src="./images/user-comment.svg" />
                                                </div>
                                                <div className="comment-text mt-2">
                                                    <div className="comment-heading k-flex align-items-center">
                                                        <h5 className="card-title mb-0">Anil Singh</h5>
                                                        <p className="card-body-text text-dark-gray ml-auto">
                                                            <span className="cursor-pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.101" height="18.897" viewBox="0 0 21.101 18.897">
                                                                    <path id="Icon_ionic-md-attach" data-name="Icon ionic-md-attach" d="M18.276,6.289v9.872a3.59,3.59,0,1,1-7.18,0V5.84a2.244,2.244,0,1,1,4.487,0V16.161a.9.9,0,0,1-1.8,0V7.635H12.442v8.526a2.244,2.244,0,0,0,4.488,0V5.84a3.59,3.59,0,1,0-7.18,0V16.161a4.936,4.936,0,0,0,9.872,0V6.289Z" transform="matrix(0.574, 0.819, -0.819, 0.574, 11.69, -9.277)" fill="gray"/>
                                                                </svg>                                                      
                                                            </span> 16, 2020, 1:33 PM (1 day ago)</p>
                                                    </div>
                                                    <p className="card-body-text text-dark-gray">Also, my Google Drive is not working properly in connect.</p>
                                                    <div className="comment-section-attched-img">
                                                        <div className="attched-img-cmt">
                                                            <img src="./images/attched-img.svg" />
                                                        </div>
                                                        <div className="attched-img-cmt">
                                                            <img src="./images/attched-img.svg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}


                                        {/* <div className="coment-row">
                                        <div>
                                    <div className="user-comment">
                                        <img src="./images/user-comment.svg" />
                                    </div>
                                    <div className="comment-text mt-2">
                                        <div className="comment-heading k-flex align-items-center">
                                        <h5 className="card-title mb-0">Anil Singh</h5>
                                        <p className="card-body-text text-dark-gray ml-auto">October 16, 2020, 1:33 PM (1 day ago)</p>
                                        </div>
                                        <p className="card-body-text text-dark-gray">How to integrate chatbot into my design?</p>
                                    </div>
                                    <div className="coment-row">
                                        <div className="user-comment">
                                        <img src="./images/user-comment.svg" />
                                        </div>
                                        <div className="comment-text mt-2">
                                        <div className="comment-heading k-flex align-items-center">
                                            <h5 className="card-title mb-0">Anil Singh</h5>
                                            <p className="card-body-text text-dark-gray ml-auto">
                                            <span className="cursor-pointer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.101" height="18.897" viewBox="0 0 21.101 18.897">
                                                <path id="Icon_ionic-md-attach" data-name="Icon ionic-md-attach" d="M18.276,6.289v9.872a3.59,3.59,0,1,1-7.18,0V5.84a2.244,2.244,0,1,1,4.487,0V16.161a.9.9,0,0,1-1.8,0V7.635H12.442v8.526a2.244,2.244,0,0,0,4.488,0V5.84a3.59,3.59,0,1,0-7.18,0V16.161a4.936,4.936,0,0,0,9.872,0V6.289Z" transform="matrix(0.574, 0.819, -0.819, 0.574, 11.69, -9.277)" fill="gray" />
                                                </svg>                                                      
                                            </span> 16, 2020, 1:33 PM (1 day ago)</p>
                                        </div>
                                        <p className="card-body-text text-dark-gray">Also, my Google Drive is not working properly in connect.</p>
                                        <div className="comment-section-attched-img">
                                            <div className="attched-img-cmt">
                                            <img src="./images/attched-img.svg" />
                                            </div>
                                            <div className="attched-img-cmt">
                                            <img src="./images/attched-img.svg" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>



                                <div className="coment-row">
                                <div className="user-comment">
                                    <img src="./images/user-comment.svg" />
                                </div>
                                <div className="comment-text mt-2">
                                    <div className="comment-heading k-flex align-items-center">
                                    <h5 className="card-title mb-0">Anil Singh</h5>
                                    <p className="card-body-text text-dark-gray ml-auto">October 16, 2020, 1:33 PM (1 day ago)</p>
                                    </div>
                                    <p className="card-body-text text-dark-gray">We have a video that shows how you can add or remov…</p>
                                </div>
                                </div>
                                */}

                                    </div>
                                    <div className="textarea-comment mt-3">
                                        <div className="comment-feilds-section">
                                            {/* <p className="card-body-text text-dark-gray"><strong>Note : </strong> Esme maine image laga diya he q k developer lagayega comment section</p> */}

                                            {
                                                initalLoadDataComp &&
                                                (<div className="textarea-Design">
                                                    {/* <Editor
                                                        //initialValue={formDescription}
                                                        // apiKey='kpqm9cs87bavvn1yu7fqlj5lcmsmw4zh9h21b9pjdl3w977m'
                                                        apiKey='qhr0m0gv8rkrme6g9ar4pd20kz6mqy88n5dh3522tn00v5k9'
                                                        init={{
                                                            placeholder: 'Enter description*',
                                                            height: 200,
                                                            menubar: false,
                                                            skin: 'naked',
                                                            icons: 'small',
                                                            content_style: "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; font-weight:400; font-size:14px}",
                                                            branding: false,
                                                            toolbar_location: 'top',
                                                            menubar: false,
                                                            statusbar: false,
                                                            plugins: [
                                                                'advlist autolink lists image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                'undo redo | bold italic | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist | removeformat ' ,
                                                        }}
                                                        onEditorChange={(content, editor) => {
                                                            // this.handleEditorChange
                                                            setFormDescription(content)
                                                        }}
                                                        value={`${formDescription}`}
                                                    /> */}

<FroalaEditor
                                    // ref={(el) => {  
                                    //     quillObj = el;  
                                    //     }} 
                                        tag='textarea'
                                        config={{
                                            placeholderText: 'Enter description*',
                                            //charCounterCount: false,
                                            heightMin: 200,
                                            heightMax: 250,
                                            fontSizeUnit: 'pt',
                                            fileDropUpload:false,
                                            attribution: false,
                                            key: `${process.env.REACT_APP_EDITOR_FROALAKEY}`,
                                            imageMultipleStyles: true,
                                            autofocus: true,
                                            direction: 'ltr',
                                            wordPasteModal:true,
                                            imagePaste:false,
                                            pasteImage: false,
                                            //wordPasteKeepFormatting:true,
                                            enter: FroalaEditor.ENTER_DIV,
                                            //fontSizeSelection: true,
                                            //pasteAllowedStyleProps: ['font-family', 'font-size', 'color'],
                                            //wordAllowedStyleProps: ['font-family', 'font-size', 'background', 'color', 'width', 'text-align', 'vertical-align', 'background-color', 'padding', 'margin', 'height', 'margin-top', 'margin-left', 'margin-right', 'margin-bottom', 'text-decoration', 'font-weight', 'font-style', 'text-indent', 'border', 'border-.*'],
                                            fontSize:['8', '9', '10', '11', '12', '14','16', '18','20','22', '24', '30', '36', '48', '60', '72', '96'],
                                            //pastePlain: true,
                                            //documentReady: true,
                                            colorsBackground: ["#000000", "#444444", "#666666", "#999999", "#CCCCCC", "#EEEEEE", '#F3F3F3', '#FFFFFF',
                                            "#FF0000", "#FF9900", "#FFFF00", "#00FF00", "#00FFFF", "#0000FF", "#9900FF", "#FF00FF",
                                            "#F4CCCC", "#FCE5CD", "#FFF2CC", "#D9EAD3", "#D0E0E3", "#CFE2F3", "#D9D2E9", "#EAD1DC",
                                            "#EA9999", "#F9CB9C", "#FFE599", "#B6D7A8", "#A2C4C9", "#9FC5E8", "#B4A7D6", "#D5A6BD",
                                            "#E06666", "#F6B26B", "#FFD966", "#93C47D", "#76A5AF", "#6FA8DC", "#8E7CC3", "#C27BA0",
                                            "#CC0000", "#E69138", "#F1C232", "#6AA84F", "#45818E", "#3D85C6", "#674EA7", "#A64D79",
                                            "#990000", "#B45F06", "#BF9000", "#38761D", "#134F5C", "#0B5394", "#351C75", "#741B47",
                                            "#660000", "#783F04", "#7F6000", "#274E13", "#0C343D", "#073763", "#20124D", "#4C1130"],
                                             colorsStep: 8,
                                            colorsText: ["#000000", "#444444", "#666666", "#999999", "#CCCCCC", "#EEEEEE", '#F3F3F3', '#FFFFFF',
                                            "#FF0000", "#FF9900", "#FFFF00", "#00FF00", "#00FFFF", "#0000FF", "#9900FF", "#FF00FF",
                                            "#F4CCCC", "#FCE5CD", "#FFF2CC", "#D9EAD3", "#D0E0E3", "#CFE2F3", "#D9D2E9", "#EAD1DC",
                                            "#EA9999", "#F9CB9C", "#FFE599", "#B6D7A8", "#A2C4C9", "#9FC5E8", "#B4A7D6", "#D5A6BD",
                                            "#E06666", "#F6B26B", "#FFD966", "#93C47D", "#76A5AF", "#6FA8DC", "#8E7CC3", "#C27BA0",
                                            "#CC0000", "#E69138", "#F1C232", "#6AA84F", "#45818E", "#3D85C6", "#674EA7", "#A64D79",
                                            "#990000", "#B45F06", "#BF9000", "#38761D", "#134F5C", "#0B5394", "#351C75", "#741B47",
                                            "#660000", "#783F04", "#7F6000", "#274E13", "#0C343D", "#073763", "#20124D", "#4C1130"],
                                            colorsHEXInput: false,
                                            lineHeights: {
                                                '1.15': '1.15',
                                                '1.5': '1.5',
                                                Double: '2',
                                                '2.5':'2.5'
                                              },
                                            toolbarButtons: {
                                                moreText: {
                                                buttons: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikeThrough",
                                                    //"subscript",
                                                    //"superscript",
                                                    "fontFamily",
                                                    "fontSize",
                                                    "textColor",
                                                    "backgroundColor",
                                                    "inlineClass",
                                                    "inlineStyle",
                                                    "clearFormatting",
                                                    
                                                    
                                                    
                                                ],
                                                //align: "right",
                                               // buttonsVisible: 11
                                                },
                                                moreParagraph: {
                                                    buttons: [
                                                        'align',
                                                    // "alignLeft",
                                                    // "alignCenter",
                                                    // //"formatOLSimple",
                                                    // "alignRight",
                                                    // "alignJustify",
                                                    "formatOL",
                                                    "formatUL",
                                                    "paragraphFormat",
                                                    "paragraphStyle",
                                                    //"lineHeight",
                                                    //"outdent",
                                                    //"indent",
                                                    //"quote",
                                                    
                                                    ]
                                                },
                                                moreMisc: {
                                                    buttons: [
                                                    "undo",
                                                    "redo",
                                                   
                                                    ],
                                                    align: "left",
                                                    buttonsVisible: 2
                                                },
                                                
                                            },
                                            
                                        pluginsEnabled: [
                                            //"table",
                                            "fontFamily",
                                            "fontSize",
                                            "spell",
                                            //"quote",
                                            "save",
                                            //"quickInsert",
                                            // "paragraphFormat",
                                            // "paragraphStyle",
                                            "help",
                                            "draggable",
                                            "align",
                                            //"link",
                                            "lists",
                                            "emoticons",
                                            "url",
                                            "embedly",
                                            "colors",
                                            "entities",
                                            "lineHeight",
                                            "wordPaste",
                                            "image"
                                        ],

                                        //const self = this;
                                        events: {
                                            
                                           
        
                                            'image.beforeUpload': function (currentFileList, imgEl) {
                                                // File was uploaded to the server.
                                                console.log(currentFileList, imgEl,"e, editor, images")
                                                imgEl[0].remove();
                                                return false;
                                            },
                                           
                                            }
                                    
                                        }}
                                        //model={this.state.model}
                                        model={`${formDescription}`}
                                        //onModelChange={this.handleModelChange}
                                        //onModelChange={handleModelChange}
                                        onModelChange={(content, editor) => {
                                            // this.handleEditorChange
                                            setFormDescription(content)
                                        }}
                                        
                                        />  

                                                    <div className="row mb-3">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="attchement-section md-form-custom mt-0 mb-3">

                                                                <h5 className="card-title">Attachements <span className="text-dark-gray">(Optional)</span></h5>

                                                                <div className="attachement-file cursor-pointer">
                                                                    <div className="attchment-inner">

                                                                        <Dropzone onDrop={acceptedFiles => onSelectDrop(acceptedFiles)} accept={attachmentAllowFormat.toString()} >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <section>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />

                                                                                        <span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={59} viewBox="0 0 28 59">
                                                                                                <text id="_" data-name="+" transform="translate(14 47)" fill="gray" fontSize={51} fontFamily="Roboto-Bold, Roboto" fontWeight={700} opacity="0.2"><tspan x="-13.908" y={0}>+</tspan></text>
                                                                                            </svg>
                                                                                        </span>
                                                                                        <p className="card-body-text">Drag &amp; Drop or <span className="text-light-blue cursor-pointer"><u>Click</u></span> to upload a file</p>
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                </div>

                                                                <div className="attched-file-section">
                                                                    {
                                                                        tempUploadFile.map((file, index) => {
                                                                            return (<div className="attched-file" key={file.name}>
                                                                                <div className="attched-icon" title={file.name}>
                                                                                    <img src="/images/pdf.svg" alt={file.name} />
                                                                                </div>
                                                                                <span className="close-file" onClick={(e) => {
                                                                                    // removeImageFromArray(index)
                                                                                    // console.log('filename',file.name);
                                                                                    let refinefileList = tempUploadFile.filter(row => row.name !== file.name)
                                                                                    setTempUploadFile(refinefileList);

                                                                                }} >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                                                                                        <g id="Group_2906" data-name="Group 2906" transform="translate(-87 -267)">
                                                                                            <circle id="Ellipse_127" data-name="Ellipse 127" cx={9} cy={9} r={9} transform="translate(87 267)" fill="#9f9f9f" />
                                                                                            <path id="Path_2071" data-name="Path 2071" d="M-134.477,555.841h9" transform="translate(225.977 -279.341)" fill="none" stroke="#fff" strokeWidth={2} />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                            </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        formSubmitInProgress ?
                                                            (
                                                                <div className="k-flex align-items-center">
                                                                    <p className="card-body-text dsable-btn form-btn px-4 py-2 text-light-blue mr-auto cursor-pointer"  > Mark as Resolved </p>

                                                                    <button className="dsable-btn form-btn px-4 py-2" type="submit"> Cancel </button>
                                                                    <button className="dsable-btn form-btn px-4 py-2 ml-2" type="submit">Submit</button>
                                                                    

                                                                </div>) :

                                                            (
                                                                <div className="k-flex align-items-center">

                                                                   { !formDescription ?
                                                                    (
                                                                        <p className="card-body-text px-4 py-2 text-light-blue mr-auto cursor-pointer"  onClick={() => markedAsResolved()} > Mark as Resolved </p>
                                                                    ):(
                                                                        <p className="card-body-text px-4 py-2 text-light-blue mr-auto cursor-pointer"  onClick={() => replyOverTicket('closed')} > Mark as Resolved and Submit </p>
                                                                    )}


                                                                        <button className="dsable-btn form-btn px-4 py-2" type="submit"
                                                                            onClick={() => {

                                                                                setFormDescription('');
                                                                                setTempUploadFile([]);
                                                                            }}
                                                                        > Cancel </button>

                                                                    {
                                                                        formDescription ?
                                                                        (<button className="info-btn form-btn px-4 py-2 ml-2" type="submit" onClick={replyOverTicket} >Submit</button>)
                                                                         :
                                                                         (<button className="dsable-btn form-btn px-4 py-2 ml-2" type="submit"> Submit</button>)
                                                                    }
                                                                        

                                                                    </div>

                                                            )}

                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                // console.log('ticketdata..', ticketData)
                            }
                            
                            {ticketData ? <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div className="notification-ticket mt-xs-cus-3">
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">ID</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray">#{ticketData?.num_ticket_id}</p>
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Assigned to</h5>
                                        </div>
                                        <div className="integrate-title-output" data-tip data-for="assignTo">
                                            <p className="card-body-text text-dark-gray text-truncate">
                                                {

                                                    ticketData?.entity_to_asign === 'U' ? getName(ticketData) :
                                                    ticketData?.entity_to_asign === 'T' ? getTeamName(ticketData) :''
                                                }

                                            </p>
                                            { ( ticketData?.entity_to_asign === 'U' ? getName(ticketData) :
                                                ticketData?.entity_to_asign === 'T' ? getTeamName(ticketData) :'').length>17 ?
                                            <ReactTooltip place="right" type="dark" id="assignTo" effect="float" delayShow={100}>
                                                            <span className="toggle-custome-react">
                                                                <ul>
                                                                    <li>{ticketData?.entity_to_asign === 'U' ? getName(ticketData) :
                                                                       ticketData?.entity_to_asign === 'T' ? getTeamName(ticketData) :''}</li>
                                                                </ul>
                                                            </span>
                                            </ReactTooltip>:''
                                            }
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3" data-tip data-for="showRequester">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Requester</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray text-truncate">{ticketData?.contacts?.firstname}</p>
                                        {ticketData.contacts.firstname.length > 17 ?
                                        <ReactTooltip place="right" type="dark" id="showRequester" effect="float" delayShow={100}>
                                            
                                                            <span className="toggle-custome-react">
                                                                <ul>
                                                                    <li>{ticketData.contacts.firstname}</li>
                                                                </ul>
                                                            </span>
                                        </ReactTooltip>: ""
                                        }
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Created</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray">
                                                <Moment format={`DD MMM YYYY`} unix  >{ticketData?.created_on?._seconds}</Moment>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Last Activity</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray">

                                                <Moment format={`DD MMM YYYY`} unix  >{ticketData?.updated_on?._seconds}</Moment>

                                                {/* 19 Sep 2020 */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Priority</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray">{ticketData?.priority}</p>
                                        </div>
                                    </div>
                                    <div className="integrate-box py-3">
                                        <div className="integrate-title">
                                            <h5 className="card-title mb-0">Status</h5>
                                        </div>
                                        <div className="integrate-title-output">
                                            <p className="card-body-text text-dark-gray">{ticketData?.ticketStatus?.name[0].toUpperCase()+ticketData?.ticketStatus?.name.slice(1).toLowerCase()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>:''}
                        </div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    )
}

export default TicketDetail