/**
 * Get Request Api Call 
 * @param {object} arg request parameter
 * @param {string} name  api function name
 */
export const getApiCall = (arg, name) => {
    var URL = ''
    if (Object.keys(arg).length) {
        const params = new URLSearchParams(arg);
        URL = `${process.env.REACT_APP_TICKET_API_CONFIG}/${name}?${params.toString()}`;
    }
    else {
        URL = `${process.env.REACT_APP_TICKET_API_CONFIG}/${name}`;
    }

    const token  = localStorage.getItem('customer');
  
    return new Promise((resolve, reject) => {
        fetch(URL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization' :  `Bearer ${token}`
            }
        }).then(res => res.json()).then(response => {
            resolve(response)
        })
    })
}

/**
 * Post request api call method
 * @param {object} arg  request parameter 
 * @param {string} name function name
 */
export const postApiCall = (arg, name) => {

    const token  = localStorage.getItem('customer');
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TICKET_API_CONFIG}/${name}`, {
            method: 'POST',
            body: JSON.stringify(arg),
            headers: {
                'Content-Type': 'application/json',
                'authorization' :  `Bearer ${token}`
            }
        }).then(res => res.json()).then(response => {
            resolve(response)
        })
    })
}

/**
 * Put Api Request
 * @param {*} arg 
 * @param {*} name 
 */
export const putApiCall = (arg, name) => {
    console.log(arg,name,"put");
    const token  = localStorage.getItem('customer');
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TICKET_API_CONFIG}/${name}`, {
            method: 'PUT',
            body: JSON.stringify(arg),
            headers: {
                'Content-Type': 'application/json',
                'authorization' :  `Bearer ${token}`
            }
        }).then(res => res.json()).then(response => {
            resolve(response)
        })
    })
}

export const deleteApiCall = (arg, name) => {
    const token  = localStorage.getItem('customer');
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_TICKET_API_CONFIG}/${name}`, {
            method: 'DELETE',
            body: JSON.stringify(arg),
            headers: {
                'Content-Type': 'application/json',
                'authorization' :  `Bearer ${token}`
            }
        }).then(res => res.json()).then(response => {
            resolve(response)
        })
    })
}

export const getDomainNameByUrl = () => {
    //const domainname = window.location.hostname.split('.')[0]
    const domainname = 'betaversion'
    return domainname
}   

export const textelipsify = (text, count, insertDots) => {
    if(!text){
        return text
    }
    return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
}

/**
 *  Attachment Formate
 */
export const attachmentAllowFormat = [
        '.doc',
        '.docx',
        '.pdf', 
        '.xls', 
        '.xlsx',
        'application/pdf',
        'image/png',
        'image/gif',
        'image/jpg',
        'image/jpeg',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.ms-excel',
        'text/csv',
        '.csv',
        'video/x-flv',
        'video/mp4',
        'application/x-mpegURL',
        'video/MP2T',
        'video/3gpp',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
        'xlsx',
        'csv'
    ]