import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import { Link, useHistory } from "react-router-dom"
import React, { useEffect, useState } from "react"
import BaseLayout from "../BaseLayout"
import { useSelector, useDispatch } from "react-redux"
import { 
    loginRequest, 
    loadLastPage, 
    // resendOtpRequest,
    resetErrors, 
   // loginSuccess,
   // setAuthenticationStatus 
} from "../BaseLayout/actions"
import queryString from 'query-string'
import { 
    postApiCall,
    agentlogin, 
    checkDeactivate, 
    reactivateAccount, 
    setCookies, 
    getTokenForOTPVerification, 
    fetchCustomerInfo,
    sendOptToUnVerifiedCustomer
} from "../../utils/services"
import Loader from "../../components/Shared/Loader"
import { toast } from 'react-toastify';
import {Modal} from "react-bootstrap";

const Index = (props) => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory();

    const baseData = useSelector(state => state.baseReducer)
    const params = queryString.parse(props.location.search)
    const settings = baseData.settings.usersettings; 
    const [show, setShow] = useState(false);
    const [showAgent, setAgentShow] = useState(false);
    const handleAgentClose = () => setAgentShow(false);
 
    const handleClose = () => setShow(false);
    const [loginInfo,setLoginInfo] = useState({})
    
    let load = false
   
    useEffect(() => {  

        
        // history.push(`/signup?org_id=${params.org_id}&email_id=${btoa(email)}`)
        if(params.org_id && params.email_id){
            let email=atob(params.email_id)
             window.location.href = `kb/login?org_id=${params.org_id}&email_id=${btoa(email)}`
        }else{
            window.location.href = `kb/login`; 
        }
    },[])

    const reactivateUserAccount = async (loginInfo)=>{
        await reactivateAccount({org_id:loginInfo.org_id,id:loginInfo.id})
        dispatch(loginRequest(loginInfo))
    }

    return (
        <BaseLayout>
            
            {/* { loading ? <div className="shadow">
            <Loader /> 
            </div>: null} */}
            
            { true ? <div className="shadow">
            <Loader /> 
            </div>: null}

                <Formik
                    initialValues={{
                        email: params.email_id? atob(params.email_id) : '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().trim()
                            .email('Email is invalid')
                            .required('Email is required'),
                        password: Yup.string()
                            .required('Password is required')
                    })}
                    onSubmit={async fields => {
                        //console.log(baseData.settings.usersettings,"baseData.settings.usersettings.domainname")
                        //console.log(settings, fields.email, "settings.id,fields.email")
                        setLoading(true)
                        //return
                        if(settings.ticketdeactivated || settings.kbdeactivated){
                        //if(true){
                            
                                setAgentShow(true)
                                setLoading(false)                            
                        }
                        else{
                            //console.log(settings.id, fields.email, "settings.id,fields.email")
                            //var loginagent = await agentlogin(baseData.settings.usersettings.domainname,fields.email,fields.password,"customer");
                            var loginagent = await agentlogin(baseData.settings.usersettings.companyidentifier,fields.email.trim(),fields.password,"customer");
                            //console.log(loginagent,"loginagent")
                            //return
                            let is_kb_app=false
                            let is_ticket_app= false
                            if(baseData.settings.usersettings.is_kb_app){
                                is_kb_app=true
                            }
                            if(baseData.settings.usersettings.is_ticket_app){
                                is_ticket_app=true
                            }
                            if(loginagent.status == 200){
                                let subId=JSON.parse(loginagent.userdata).idToken.payload.sub
                                //console.log(subId,"subId")
                                setLoading(false)
                                let resp = await checkDeactivate({id:subId, org_id: baseData.settings.usersettings.id})
                                //console.log(resp,"resp")
                                var customerLoginCookies = {
                                    "a1840bd4010cc8f4c31a45a679a985cd" : 1
                                }
                                await setCookies(customerLoginCookies);
                                if(resp.status==200 && resp.isAccountDeactivate){
                                    setShow(true)
                                    setLoginInfo({ 
                                        email: fields.email.trim(), 
                                        org_id: baseData.settings.usersettings.id, 
                                        password: fields.password,id:subId, 
                                        domainname: baseData.settings.usersettings.domainname,
                                        portalname: baseData.settings.usersettings.portalname,
                                        companyidentifier: baseData.settings.usersettings.companyidentifier,
                                        login_type:"customer",
                                        is_ticket_app:is_ticket_app,
                                        is_kb_app:is_kb_app,
                                        path:"/"
                                    })
                                }
                                else{
                                let hist1=baseData.browserHistory.filter(value => value.path == 'detail-ticket')
                                if (hist1.length === 0) {
                                    dispatch(loginRequest({ 
                                            email: fields.email.trim(),
                                            org_id: baseData.settings.usersettings.id, 
                                            password: fields.password,id:subId, 
                                            domainname: baseData.settings.usersettings.domainname,
                                            portalname: baseData.settings.usersettings.portalname,
                                            companyidentifier: baseData.settings.usersettings.companyidentifier,
                                            login_type:"customer",
                                            is_ticket_app:is_ticket_app,
                                            is_kb_app:is_kb_app,
                                            path:"/"
                                        })
                                    )
                                } else {
                                        dispatch(loginRequest({ 
                                                email: fields.email, 
                                                org_id: baseData.settings.usersettings.id, 
                                                password: fields.password,
                                                id:subId, 
                                                domainname: baseData.settings.usersettings.domainname,
                                                portalname: baseData.settings.usersettings.portalname,
                                                companyidentifier: baseData.settings.usersettings.companyidentifier,
                                                login_type:"customer",
                                                is_ticket_app:is_ticket_app,
                                                is_kb_app:is_kb_app,
                                                path:hist1[hist1.length - 1].pathname
                                            }
                                        ))
                                    }    
                                }
                                // dispatch(setAuthenticationStatus(true))
                                // dispatch(loginSuccess({token:loginagent.accessToken.jwtToken}))
                                
                            } else if(loginagent.status === 400 && loginagent.error_key == 4 ){ 
                             
                                
                                let  customer_info = {                                    
                                    email: fields.email, 
                                    org_id: baseData.settings.usersettings.id, 
                                }
                                const customerInfo = await fetchCustomerInfo(customer_info)
                                // console.log('customerInfo',customerInfo)

                                // find user With email and get customer                                
                                let obj= { 
                                    uid: customerInfo.results.uid,
                                    email: fields.email, 
                                    password: fields.password, 
                                    org_id: baseData.settings.usersettings.id,
                                    portalName:baseData.settings.usersettings.portalname,
                                    companyidentifier:baseData.settings.usersettings.companyidentifier,
                                    url:`https://${window.location.hostname.split('.')[0]}.appypiedesk.com`
                                }

                                // console.log('send resendOtpSignup')
                                let resp= await sendOptToUnVerifiedCustomer(window.location.href,"customer",obj )                                  
                                // console.log(resp,"res")

                                if(resp.status == 200){
                                    setLoading(false)
                                    // if user unverified then redirect 
                                    const optVerification = await  getTokenForOTPVerification(obj)
                                    toast.success("The verification code has been sent to your email address");
                                    setLoading(false)
                                    console.log('optVerification...', optVerification)
                                    history.push(`/customer-verify/${optVerification.userToken}`)                                          
                                }
                                else{
                                    setLoading(false)
                                    toast.error(resp.message);
                                }

                         } else{
                                toast.error(loginagent.message)
                                setLoading(false)
                            }
                        }
                    }}
                >

                    {({ errors, status, touched }) => (
                        <Form>
                            <Modal className="disableModel" show={show}  aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header className="py-0 border-bottom-0"  onClick={handleClose} closeButton>
                            
                                </Modal.Header>
                                <Modal.Body className="py-0 px-0">
                                        <div className="mb-4">
                                            <h4 className="card-title mb-0 font-bold mb-3">Sign In</h4>
                                            <p className="card-body-text text-dark-gray">This account has been deactivated. Are you sure you want to reactivate it?</p>
                                        </div>                                        
                                        <>
                                            <button className="info-btn form-btn py-2 px-4" type="button" onClick={() => reactivateUserAccount(loginInfo)}>Sign In</button>
                                            <span className="ml-2 mr-3 cursor-pointer text-dark-gray" onClick={() => window.location.replace('/')}>Cancel</span>
                                        </>                                    
                                </Modal.Body>
                  
                            </Modal>
                            <Modal className="disableModel" show={showAgent}  aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Header className="py-0 border-bottom-0"  onClick={handleAgentClose} closeButton>
                            
                                </Modal.Header>
                                <Modal.Body className="py-0 px-0">
                                    <div className="mb-4">
                                        <h4 className="card-title mb-0 font-bold mb-3">Deactivate</h4>
                                        <p className="card-body-text text-dark-gray">This portal has been deactivated. Please contact your admin.</p>
                                    </div>
                                    
                                    
                                        
                                        
                                        {/* <>
                                        <button className="info-btn form-btn py-2 px-4" type="button" onClick={() => reactivateUserAccount(settings.userId)}>Sign In</button>
                                        <span className="ml-2 mr-3 cursor-pointer text-dark-gray" onClick={() => window.location.replace('/agent-login')}>Cancel</span>
                                        </> */}
                                    
                                        
                                    
                                </Modal.Body>
                  
                            </Modal>
                            <div className="main-tickting-frontend frontends tickting-login-screen">
                                <div className="container">
                                    <div className="login-centerd">
                                        <div className="login-frontend">
                                            <div className="lock-secure text-right pb-3">
                                                <p><svg xmlns="http://www.w3.org/2000/svg" width="16.797" height="22.396" viewBox="0 0 16.797 22.396">
                                                <path id="lock" d="M14.7,8.4H14V5.6a5.6,5.6,0,0,0-11.2,0V8.4H2.1A2.1,2.1,0,0,0,0,10.5v9.8a2.1,2.1,0,0,0,2.1,2.1H14.7a2.1,2.1,0,0,0,2.1-2.1V10.5A2.1,2.1,0,0,0,14.7,8.4ZM4.666,5.6a3.733,3.733,0,0,1,7.465,0V8.4H4.666Zm0,0" />
                                                </svg>
                                                <span>Secure Area</span></p>
                                            </div>
                                            {/* {(!show)? */}
                                            <div className="login-box">
                                            <div className="headings-login-signup">
                                                <h4>Log In To Your Account</h4>
                                            </div>
                                            <div className="feilds-new">
                                                <div className="user-feilds">
                                                    <div className="md-form md-form-custom">
                                                        <Field name="email" id="inputMDEx7"  readOnly={params.email_id?.trim() ? true : false} type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                        <label htmlFor="inputMDEx7" className={params.email_id ? "active" : ""}>Email Address* </label>
                                                    </div>
                                                </div>
                                                <div className="user-feilds">
                                                    <div className="md-form md-form-custom">
                                                        <Field name="password" id="inputMDEx71" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                        <label htmlFor="inputMDEx71">Password*</label>
                                                    </div>

                                                </div>
                                                {(baseData.loginError) ?
                                                    <p className="text-danger">{baseData.loginError}</p>
                                                    : null
                                                }
                                                <div className="user-feilds">
                                                    {/* {!loading? */}
                                                     <p className="text-light-blue card-body-text text-right"><span className="cursor-pointer paralogin-signup"><Link to="/forgot-password/user">Forgot Password?</Link></span></p>
                                                        {/* :
                                                        null
                                                    } */}
                                                </div>


                                                <div className="user-feilds">
                                                    <div className="login-signup-btn k-flex align-items-center">
                                                        <button disabled={loading} type="submit">Login</button>
                                                        {/* <span className="or">or</span>
                                                        <ul>
                                                            <li>
                                                                <a href="#">
                                                                    <img src="../../../public/images/facebook.svg" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <img src="../../../public/images/social/g+.svg" />
                                                                </a>
                                                            </li>
                                                        </ul> */}

                                                    </div>
                                                </div>
                                                {/* {!loading? */}

                                                <div className="user-feilds">
                                                <p className="paralogin-signup card-body-text">Don’t have an account? <Link to="/signup">Sign Up</Link></p>

                                                    {/* <p className="paralogin-signup">Forgot your password?  <Link to="/forgot-password/user">Click here</Link> </p> */}

                                                    {/* <p className="paralogin-signup">Don’t have an account? <Link to="/signup">Sign Up</Link></p> */}
                                                </div>
                                                {/* :
                                                null} */}
                                            </div>

                                            <div className="are-you-agent text-center pt-3 mt-3 pt-md-3 pt-xs-cus-4 pt-xs-cus-0">
                                                
                                                <p className="paralogin-signup" style={{'fontWeight': 'bold'}}>Are you an agent?  <Link to="/agent-login">Login here</Link> </p>
                                            </div>
                                                

                                        </div>
                                         {/* :
                                         <div className="login-box">
                                             <div className="headings-login-signup">
                                                 <h4>Sign In</h4>
                                             </div>
                                             <div className="mb-4">
                                           
                                                 <p className="card-body-text text-dark-gray">This account has been deactivated. Are you sure you want to reactivate it?</p>
                                             </div>
                                             <>
                                             <button className="info-btn form-btn py-2 px-4" type="button" onClick={() => reactivateUserAccount(loginInfo)}>Sign In</button>
                                             <span className="ml-2 mr-3 cursor-pointer text-dark-gray" onClick={() => setShow(false)}>Cancel</span>
                                             </>


                                         </div>
                                        } */}
                                            
                                        </div>
                                        
                                    </div></div>
                                    {/* <div className="loginFooterReview registrationImgFilter NewFooterFrnt">
                                            <div className="review-section">
                                                <div className="review-companies">
                                                    <div className="reviewcompanieswrapper">
                                                        <div className="reviewcompaniesimg"><a href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47" target="_blank" rel="nofollow noopener"><img src='/images/login1.png' /></a></div>
                                                    </div>
                                                    <div className="reviewcompanieswrapper">
                                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login2.png' /></a></div>
                                                    </div>
                                                    <div className="reviewcompanieswrapper">
                                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login3.png' /></a></div>
                                                    </div>
                                                    <div className="reviewcompanieswrapper">
                                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login4.png' /></a></div>
                                                    </div>
                                                    <div className="reviewcompanieswrapper">
                                                        <div className="reviewcompaniesimg"><a rel="nofollow noopener"><img src='/images/login5.png' /></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                            </div>
                        </Form>
                    )}
                </Formik>               
        </BaseLayout>
    )
}

export default Index;

