import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from "moment";

const TimeFormat = (props) => {
        const  dataformat =  'MMM DD, YYYY'
        const  timeformat = 'hh:mm A' 
        // find default timezome
        let defaulTtimezone = moment.tz.guess();
       if (typeof defaulTtimezone !== 'string') {
           defaulTtimezone = 'America/Los_Angeles'
       }
        const timezoneData =  defaulTtimezone;
        const timeinsecond = props.timedata._seconds || props.timedata.seconds
        //console.log('time formate timeformat',timeformat)
        return (
            <React.Fragment>
                <Moment format={`${dataformat} , ${timeformat}`} unix tz={timezoneData} >{timeinsecond}</Moment>
                ( <Moment fromNow={true} unix tz={timezoneData} >{timeinsecond}</Moment>  )
            </React.Fragment>
        )
    
};

export default TimeFormat;