import { Link,useHistory } from "react-router-dom"
import React,{ useState , useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
// import BaseLayout from "../BaseLayout"
// import { getApiCall } from "../../utils/ticketServices"
import TimeFormat from './../../utils/TimeFormat'

/**
 * 
 * @param {*} props 
 */
const ThreadList = (props) => {
        const history = useHistory();
        const baseData = useSelector(state => state.baseReducer)
    return (
        <div className="coment-row">
        <div className="user-comment">
            {(baseData.authUser?.photo)?
            <img src={baseData.authUser.photo} />:
            <img src="/images/user-comment.svg" />
            }
        </div>

        <div className="comment-text mt-2">
            <div className="comment-heading k-flex align-items-center">
                <h5 className="card-title mb-0">{props.fromUser.firstname} {props.fromUser.lastname}  </h5>
                <p className="card-body-text text-dark-gray ml-auto">
                    {
                       props?.attachmentDoc && props?.attachmentDoc.length > 0 ? 
                        (<svg xmlns="http://www.w3.org/2000/svg" width="21.101" height="18.897" viewBox="0 0 21.101 18.897">
                        <path id="Icon_ionic-md-attach" data-name="Icon ionic-md-attach" d="M18.276,6.289v9.872a3.59,3.59,0,1,1-7.18,0V5.84a2.244,2.244,0,1,1,4.487,0V16.161a.9.9,0,0,1-1.8,0V7.635H12.442v8.526a2.244,2.244,0,0,0,4.488,0V5.84a3.59,3.59,0,1,0-7.18,0V16.161a4.936,4.936,0,0,0,9.872,0V6.289Z" transform="matrix(0.574, 0.819, -0.819, 0.574, 11.69, -9.277)" fill="gray"/>
                        </svg>
                        ) : ''
                    }
                    <TimeFormat timedata={props.created_on} />
                    {/* October 16, 2020, 1:33 PM (1 day ago) */}
                    </p>
            </div>
                <p className="card-body-text text-dark-gray" dangerouslySetInnerHTML={{__html: props.body}}></p>

                <div className="comment-section-attched-img">
                {
                    props?.attachmentDoc && props.attachmentDoc.map(attachefile => {
                        return (
                            <div className="attched-img-txt" key={attachefile.filename}>
                                {/* <img src={attachefile.path} /> */}
                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.101" height="18.897" viewBox="0 0 21.101 18.897">
                                <path id="Icon_ionic-md-attach" data-name="Icon ionic-md-attach" d="M18.276,6.289v9.872a3.59,3.59,0,1,1-7.18,0V5.84a2.244,2.244,0,1,1,4.487,0V16.161a.9.9,0,0,1-1.8,0V7.635H12.442v8.526a2.244,2.244,0,0,0,4.488,0V5.84a3.59,3.59,0,1,0-7.18,0V16.161a4.936,4.936,0,0,0,9.872,0V6.289Z" transform="matrix(0.574, 0.819, -0.819, 0.574, 11.69, -9.277)" fill="gray"/>
                                </svg>
                                <a target="_blank" href={attachefile.path} > {attachefile.filename} </a>

                            </div>
                        )
                    })
                }
                </div>
                {/* <div className="comment-section-attched-img">
                    <div className="attched-img-cmt">
                        <img src="/images/attched-img.svg" />
                    </div>
                    <div className="attched-img-cmt">
                        <img src="/images/attched-img.svg" />
                    </div>
                </div> */}
        </div>

            {/* <div style={{padding: '10px 20px'}}>
                <div> <strong> {props.fromUser.firstname} {props.fromUser.lastname} </strong> </div>
                <div className="post__content" dangerouslySetInnerHTML={{__html: props.body}}></div> 
                <div><TimeFormat timedata={props.created_on} /></div>
            </div> */}
        </div>      
    )
}

export default ThreadList