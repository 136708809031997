import { Link,useHistory } from "react-router-dom"
import React,{ useState , useEffect} from "react"
import Moment from 'react-moment';
import 'moment-timezone';
// import { getApiCall } from "../../utils/ticketServices"

/**
 * @param {*} props 
 */
const SingleTicketList = (props) => {
        const history = useHistory();
        const [ticketId, setTicketID] = useState(props.ticket_id)

    return (
            <React.Fragment >

                <div className="view-ticket-table view-ticket-row k-flex align-items-center justify-content-center py-4 px-3">
                    <div className="view-ticket-col view-ticket-col-1" onClick={(props) => {
                            //console.log('props data ',ticketId)
                             history.push(`detail-ticket/${ticketId}`)
                        }}
                        > 
                        <h5 className="card-title mb-0 subject-col">{props.subject} </h5>
                    </div>
                    <div className="view-ticket-col view-ticket-col-2"> 
                        <p className="card-body-text text-dark-gray">#{props.num_ticket_id}</p>
                    </div>
                    <div className="view-ticket-col view-ticket-col-3"> 
                        <p className="card-body-text text-dark-gray">
                            <Moment format={`DD/MM/YYYY`} unix  >{props.created_on._seconds}</Moment>
                                                   
                        </p>
                    </div>
                    <div className="view-ticket-col view-ticket-col-4"> 
                        <p className="card-body-text text-dark-gray">
                        
                            <Moment format={`DD/MM/YYYY`} unix  >{props?.updated_on?._seconds}</Moment> 
                            {/* <Moment format={`DD MMM YYYY`} unix  >{props?.updated_on?._seconds}</Moment> */}
                            </p>
                    </div>
                    <div className="view-ticket-col view-ticket-col-5 text-center"> 
                        <p className={props.ticketStatus.name == 'closed' ? 'card-body-text  text-light-gray' : 'card-body-text'}  >{props.ticketStatus.name[0].toUpperCase() + props.ticketStatus.name.slice(1).toLowerCase()}</p>
                    </div>
                    </div>


        {/* <div>
            <div onClick={(props) => { 
              console.log('props data ',ticketId)
              history.push(`ticket-detail/${ticketId}`)
            }}>
            {props.subject} 
            </div>
            <div> #{props.ticket_num_id} </div>
            <div> {props.created_on._seconds} </div>
            <div> {props.ticketStatus.name} </div>
        </div> */}

        </React.Fragment>
    )
}

export default SingleTicketList