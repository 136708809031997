export const FETCH_FOLDERS_REQUEST = 'FETCH_FOLDERS_REQUEST';
export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS';
export const FETCH_FOLDERS_FAILURE = 'FETCH_FOLDERS_FAILURE';

export const MORE_FOLDERS_AVAILABLE = 'MORE_FOLDERS_AVAILABLE';

export const ZERO_FOLDERS = 'ZERO_FOLDERS'

export const LAST_VISIBLE_FOLDER_ID = 'LAST_VISIBLE_FOLDER_ID'

export const CLEAR_FOLDERS = 'CLEAR_FOLDERS'

export const FETCH_FOLDER_PAGE_SEO_REQUEST = 'FETCH_FOLDER_PAGE_SEO_REQUEST';
export const FETCH_FOLDER_PAGE_SEO_SUCCESS = 'FETCH_FOLDER_PAGE_SEO_SUCCESS';
export const FETCH_FOLDER_PAGE_SEO_FAILURE = 'FETCH_FOLDER_PAGE_SEO_FAILURE';


export const FETCH_FOLDER_NAMES_REQUEST = 'FETCH_FOLDER_NAMES_REQUEST';
export const FETCH_FOLDER_NAMES_SUCCESS = 'FETCH_FOLDER_NAMES_SUCCESS';
export const FETCH_FOLDER_NAMES_FAILURE = 'FETCH_FOLDER_NAMES_FAILURE';

export const ZERO_SIDEBAR_FOLDERS = 'ZERO_SIDEBAR_FOLDERS';

export const CLEAR_SIDEBAR_FOLDERS = 'CLEAR_SIDEBAR_FOLDERS';

export const LAST_VISIBLE_SIDEBAR_FOLDER_ID = 'LAST_VISIBLE_SIDEBAR_FOLDER_ID';

export const MORE_SIDEBAR_FOLDERS_AVAILABLE = 'MORE_SIDEBAR_FOLDERS_AVAILABLE';