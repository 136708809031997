import React from "react"
//import { connectSearchBox } from 'react-instantsearch-dom';
import { hits } from './Hits';
import { Link } from "react-router-dom"
import { useState } from "react"
import { validateUrl } from "../../utils/functions"
import  {getsearchdata}  from "../../utils/services"


const CustomSearchBox = ({ currentRefinement, isSearchStalled, refine, userid, data, value1 }) => {
    const [state, setstate] = useState({
        hots: [],
        show: false,
    })
    const [searchdata, setsearchdata] = useState('');
    const getsearchowndata=async (e)=>{
      //  console.log(e.target);
        let getvalue = e.currentTarget.value
        //console.log(getvalue,"getvalue")
        let getdata = await getsearchdata(userid,getvalue)
        if(getdata.success){
        var st = { ...state, hots: getdata.data.hits }
                            setstate(st)
        }
       
    }
    //console.log(userid);
    return (
        <div className="Search-details my-4">
            <span className="search-Feilds-frontend searchFeilds">
                <input type="search"
                    placeholder="Search Articles"
                    value={searchdata}
                    onChange={event => {
                        // console.log(event.currentTarget.value.length,"event.currentTarget.value.length")
                        setsearchdata(event.currentTarget.value);
                       // refine(event.currentTarget.value)
                       //console.log(event.currentTarget.value,"e.target");
                        if (event.currentTarget.value.length == 0) {

                            var st = { ...state, hots: [] }
                            //console.log(st,"st>>>>>");
                            setstate(st)
                        } else {
                            getsearchowndata(event)
                             {/* var st = { ...state, hots: hits }
                            setstate(st)  */}
                        }

                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            var st = { ...state, hots: [] }
                            setstate(st)

                        }, 200)
                    }}
                


                />

<>
{
    (searchdata.trim().length > 0)?
            <ul className="search-bare-listings">
            {/* {console.log(state,"state>>>>>>>")} */}
            {state.hots.map(hit => (

                (hit._source.type === "article") ?


                    <li>
                        {/* <Link to={`/article/${validateUrl(hit.name)}-${hit.objectID}`}> */}
                        <Link to={`/article/${validateUrl(hit._source.name)}`}>
                            <h5 className="card-title mt-0 mb-0 ml-0 mr-0">{hit._source.name}</h5>
                            <p>in Article</p>
                        </Link>
                    </li>


                    : (hit._source.type === "category") ?

                        <li>
                            <Link to={`/category/${validateUrl(hit._source.name)}-${hit._id}`} >

                                <h5 className="card-title mt-0 mb-0 ml-0 mr-0">{hit._source.name}</h5>
                                <p>in Category</p>
                            </Link>
                        </li>


                        : (hit._source.type === "folder") ?

                            

                                <li>
                                    <Link to={`/folder/${validateUrl(hit._source.name)}-${hit._id}`}>
                                    <h5 className="card-title mt-0 mb-0 ml-0 mr-0">{hit._source.name}</h5>
                                    <p>in Folder</p>
                                    </Link>
                                </li>
                            
                            :  
                            <li>
                            <Link to={`/article/${validateUrl(hit._source.name)}`}>
                            <h5 className="card-title mt-0 mb-0 ml-0 mr-0">{hit._source.name}</h5>
                            <p>in Article</p>
                        </Link>
                        </li>

            ))}
        </ul>:
        null
        }

</>

               



            </span>


        </div>
    )
};


export default CustomSearchBox;