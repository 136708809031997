export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';

export const MORE_ARTICLES_AVAILABLE   = 'MORE_ARTICLES_AVAILABLE';

export const ZERO_ARTICLES = 'ZERO_ARTICLES';

export const LAST_VISIBLE_ARTICLE_ID = 'LAST_VISIBLE_ARTICLE_ID';

export const CLEAR_ARTICLES = 'CLEAR_ARTICLES';

export const SET_CATEGORY_ID ='SET_CATEGORY_ID';

export const FETCH_ARTICLES_PAGE_SEO_REQUEST = 'FETCH_ARTICLES_PAGE_SEO_REQUEST';
export const FETCH_ARTICLES_PAGE_SEO_SUCCESS = 'FETCH_ARTICLES_PAGE_SEO_SUCCESS';
export const FETCH_ARTICLES_PAGE_SEO_FAILURE = 'FETCH_ARTICLES_PAGE_SEO_FAILURE';

